import { Box, Button, Switch } from "@mui/material"
import CustomComponentContainer from "../../../components/container/CustomComponentContainer"
import Dropdown from "../../../components/formInputs/dropDown/Dropdown"
import FormInput from "../../../components/formInputs/FormInput"
import TextInput from "../../../components/formInputs/TextInput"
import { ThemeColors } from "../../../hooks/ThemeColors"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { errorToast, successToast } from "../../../helpers/apiToast"
import { GET, POST, PUT } from "../../../helpers/http"
import * as Yup from 'yup';
import MediaUploadForm from "../MediaUploadPPM"
import PPMSwitch from "../components/switch/PPMSwitch"
import CustomTypography from "../../../components/texts/CustomTypography"


const CostingForm = ({ data, editMode, toggleEditMode, updateParentState, toggleDrawer, task }) => {
    const { commonSX, background_color } = ThemeColors();
    const [errors, setErrors] = useState({});
    const [assigneChecked, setAssigneChecked] = useState(false);
    const [assigneType, setAssigneType] = useState(data?.expenseByUserType || 'internal');
    const [users, setUsers] = useState([])
    // const [category, setCategory] = useState({})
    const { id } = useParams();
    const [formData, setFormData] = useState({
        expenseBy: data?.expenseBy?._id || '',
        invoiceNumber: data?.invoiceNumber || '',
        vendorName: data?.vendorName || '',
        expenseByUserType: data?.expenseByUserType || 'internal',
        amount: data?.amount || '',
        media: data?.media || [], // Add media to state
    });


    useEffect(() => {
        (async () => {
            if (task?.category.id) {
                const response = await GET(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/fault/category/users?categoryId=${task?.category?.id}&userType=${assigneType}`, 'Get Users')
                setUsers(response?.data)

            }

        })();
    }, [assigneChecked])

    const vaidateSchema = Yup.object().shape({
        amount: Yup.number()
            .required('Amount is required'),
        invoiceNumber: Yup.string()
            .required('Invoice number is required'),

    })


    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            await vaidateSchema.validate(formData, { abortEarly: false });
            const response = editMode ? await PUT(`${process.env.REACT_APP_URL}ppm/v1/cost/?costId=${data._id}`, { ...formData, ppmReference: id }, "Cost Update") : await POST(`${process.env.REACT_APP_URL}ppm/v1/cost`, { ...formData, ppmReference: id }, "Create Cost")
            updateParentState({ data: response?.data });

            if (response.status === 201) {
                toggleDrawer()
                successToast("Cost Created Successfully")
            } else {
                toggleDrawer()
                successToast("Cost Updated Successfully ")
            }

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const newErrors = {};
                error.inner.forEach(err => {
                    newErrors[err.path] = err.message; // Set each error message
                });
                setErrors(newErrors); // Set errors in state
            }
            console.log(error)
        }


    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };



    const handleDropDownChange = async (e) => {
        const selectedValue = e.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            expenseBy: selectedValue // Assuming 'addedBy' is the key you're updating
        }));
    }


    const labels = [
        { label: 'Amount', name: 'amount' },
        { label: 'userType', name: 'userType' },
        { label: 'Expensed By', name: 'expenseBy' },
        { label: 'Invoice Number', name: 'invoiceNumber' },
        { label: 'Vendor Name', name: 'vendorName' },
        // { label: 'Expense Type', name: 'expenseTypeReference' },
        { label: 'Add Media', name: 'media' }, // Add media to labels
        // { label: 'visibility', name: 'visibility' },
        // { label: 'Add Comments', name: 'textContent' }
    ];

    const handleChangeCheckbox = (e) => {
        const value = e.target.checked;
        setAssigneChecked(value);
        if (value) {
            setAssigneType("external");
            setFormData(prevValues => ({ ...prevValues, expenseByUserType: "external" }))
        }
        else {
            setFormData(prevValues => ({ ...prevValues, expenseByUserType: "internal" }))
            setAssigneType("internal");
        }
    }


    const handleFileUpload = async (medias) => {
        try {
            setFormData(prevValues => ({ ...prevValues, media: medias }))
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
        }
    }

    const handleDeleteMedia = async (ele) => {
        try {
            setFormData({ ...formData, media: formData?.media?.filter(mda => mda?.uploadId !== ele?.uploadId) })
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
            console.log(error)
        }
    }


    return (
        <>
            <CustomComponentContainer
                component='form'
                onSubmit={handleSubmit}
                sx={{
                    padding: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1.5rem',
                    width: '25rem',
                    backgroundColor: background_color(),
                }}
                elevation={0}
            >
                {labels.map((field, index) => (
                    field.name === 'media' ? (
                        <Box>
                            <MediaUploadForm
                                type="ppm"
                                handleFileUpload={handleFileUpload}
                                existingMedia={data?.media}
                                handleDeleteMedia={handleDeleteMedia}
                            />


                        </Box>
                    ) :
                        field.name === 'userType' ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center"  // Vertically center-aligns the switch and text
                                }}
                            >
                                <PPMSwitch
                                    checked={assigneChecked || (data?.expenseByUserType === 'external' ? true : false)}  // Switch state for internal/external user
                                    onChange={handleChangeCheckbox} // Handle switch change
                                />
                                <CustomTypography
                                    className="color-grey"
                                    sx={{ fontSize: "13px", marginLeft: "10px" }}  // Adds space between the switch and text
                                >
                                    {`You have selected ${assigneChecked ? "External" : "Internal"} User as the Cost Bearer.`}
                                </CustomTypography>
                            </Box>

                        ) :
                            field.name === 'expenseBy' ? (
                                <Dropdown
                                    label='Select Internal User'
                                    name='expenseBy'
                                    inputLabel='Select Internal User'
                                    width='100%'
                                    sx={{ mb: '1rem' }}
                                    value={formData?.expenseBy}
                                    helperText={errors[field.name] || ''}
                                    error={errors[field.name] ?? ''}
                                    data={users.map(user => ({ id: user._id, name: user.fullName }))}
                                    onChange={handleDropDownChange}
                                />
                            ) :
                                field.name === 'vendorName' ? (
                                    <FormInput
                                        label='Vendor Name'
                                        width='100%' sx={{ mb: '1rem' }}
                                        value={formData.vendorName}
                                        onChange={handleChange}
                                        error={!!errors.vendorName}
                                        helperText={errors.vendorName || ''}
                                        name={'vendorName'}
                                    />
                                ) :
                                    field.name === 'invoiceNumber' ? (
                                        <FormInput
                                            label='Invoice Number'
                                            width='100%' sx={{ mb: '1rem' }}
                                            value={formData.invoiceNumber}
                                            onChange={handleChange}
                                            error={!!errors.invoiceNumber}
                                            helperText={errors.invoiceNumber || ''}
                                            name={'invoiceNumber'}
                                        />
                                    ) : (
                                        <TextInput
                                            key={index}
                                            name={field.name}
                                            variant='outlined'
                                            label={field.label}
                                            width='100%'
                                            value={formData[field.name]}
                                            sx={commonSX}
                                            onChange={handleChange}
                                            size='small'
                                            type={field.name === 'amount' ? 'number' : 'text'}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name] || ''}
                                        />
                                    )
                ))}
                <Button
                    variant='contained'
                    sx={{
                        backgroundColor: '#dc4d28',
                        '&:hover': { backgroundColor: '#c43e2d' },
                        width: '50%',
                        height: '2.5rem'
                    }}
                    type="submit"
                >
                    {editMode ? 'Update' : 'Submit'}
                </Button>
            </CustomComponentContainer>
        </>
    )
}
export default CostingForm