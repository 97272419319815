import { Box, Grid, Paper } from '@mui/material';
import React, { useState } from 'react';
import Button from '../../../../components/formInputs/buttons/Button'
import FileInput from '../../../../components/formInputs/FileInput';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { FileUpload } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import AssetUploadFailedModal from '../modals/uploadFailed/AssetUploadFailedModal';
import './bulkUpload.css'
import { FILEPOST } from '../../../../helpers/http';
import AddAssetExcelStyles from '../../../../../facilityManager/styles/assetManagement/addExcel/AddAssetExcelStyles';

const BulkUpload = (props) => {
    const navigate = useNavigate()
    const themeMode = useSelector(state => state.theme.darkTheme)
    const bgcolor = !themeMode ? '#282a2e' : '#fff'
    const [failedAssetModal, setFailedAssetModal] = useState(false)
    const [notUploadableAssetsData, setNotUploadableAssetData] = useState([])

    const handleFailedAssetModalClose = () => {
        setFailedAssetModal(false)
    }
    const FileInputHandler = (file) => {
        swal({
            title: "This might take some time",
            text: "Please make sure you have added with correct format mentioned in the template",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((ok) => {
                if (ok) {
                    const fileData = file.target.files[0];
                    const formData = new FormData();
                    formData.append("file", fileData);
                    FILEPOST(`${process.env.REACT_APP_URL}am/v1/assets?bid=${localStorage.getItem("bid")}`, formData, 'User Management Excel Upload', true)
                        .then((data) => {
                            if (data?.status === 201) {
                                const result = data?.data
                                if (result?.data?.hasOwnProperty('notUploadableAssetsData') && result?.data?.notUploadableAssetsData?.length > 0) {
                                    setFailedAssetModal(true)
                                    setNotUploadableAssetData(result?.data?.notUploadableAssetsData)
                                } else {
                                    swal("Success!", "All Assets Imported", "success");
                                    navigate("/admin/usermanagement")
                                }
                            } else if (data?.status === 200) {
                                const result = data?.data
                                if (result?.hasOwnProperty('notUploadableAssetsData') && result?.notUploadableAssetsData?.length > 0) {
                                    setFailedAssetModal(true)
                                    setNotUploadableAssetData(result?.notUploadableAssetsData)
                                }
                            } else {
                                swal("Waring!", data?.data?.message, "danger");
                            }
                        }).catch(e => {
                            console.log("Error when uploading the excel", e.toString())
                        })
                }
            });
    }
    const url = process.env.REACT_APP_DOWNLOAD_TEMPLATE_URL
    return (
        <Paper className='theme-component-background theme-border theme-cards-boxShadow theme-color bdrRadius4px'
            sx={{
                margin: '20px',
                width: '100%',
                minHeight: '85.5vh',
            }}
        >
            <Grid sx={AddAssetExcelStyles.gridContainer}>
                <Box sx={AddAssetExcelStyles.boxContainer}>
                    <Box sx={AddAssetExcelStyles.fontWeight700}>Upload An Excel</Box>
                    <Box sx={{ m: 1 }}>Make sure to upload the excel in the same template format that's downloaded from below </Box>
                    <Box sx={{ m: 2 }} className={themeMode ? 'button-container-dark' : 'button-container-light'}>
                        <a style={{ 'text-decoration': 'none' }} href={url}>
                            <Button name="Download Template" variant={"contained"} bgcolor={bgcolor} />
                        </a>
                    </Box>
                </Box>
                <Box sx={AddAssetExcelStyles.boxContainerTwo} className='theme-component-background theme-color'>
                    <Box><FileUpload fontSize='large' /></Box>
                    <Box sx={{ m: 1 }}>Use the below button to select the file</Box>
                    <Box sx={AddAssetExcelStyles.boxItem}>
                        <FileInput accept={".xlsx"} buttonText="SELECT FILE" pickHandler={((file) => FileInputHandler(file))} sx={AddAssetExcelStyles.fileInput} name="Select File" />
                    </Box>
                </Box>
            </Grid>
            {
                failedAssetModal && <AssetUploadFailedModal
                    openModal={failedAssetModal}
                    handleCloseModal={handleFailedAssetModalClose}
                    notUploadableAssetsData={notUploadableAssetsData}
                />
            }
        </Paper >

    )
}

export default BulkUpload