import { Avatar, Box, Grid, styled } from "@mui/material"
import CustomComponentContainer from "../../../components/container/CustomComponentContainer"
import { AddCircleOutline, Delete } from "@mui/icons-material"
import { ThemeColors } from "../../../hooks/ThemeColors";
import Loading from "../../../components/loading/Loading";
import { useEffect, useState } from "react";
import { DELETE, FILEPOST, GET, PUT } from "../../../helpers/http";
import { errorToast, successToast } from "../../../helpers/apiToast";
import Loader from "../../../components/loading/Loader";
import Modal from "../../../components/modals/NewModal";
import { useSelector } from "react-redux";
import Plyr from "plyr-react"
import "plyr-react/plyr.css"
import ReturnMultiTypeMedia from "../../faultReports/newFaultReports/components/returnMultiTypeMedia";
import AddMediaCard from "../AddMediaCard";
import AddMediasForm from "../components/taskView/AddMediaForm";
import { FilePond } from "react-filepond";
import { useParams } from "react-router-dom";
import swal from 'sweetalert'

const Medias = () => {
    const { themeMode } = ThemeColors();
    const [loading, setLoading] = useState(false)
    const [media, setMedia] = useState([])
    const [mediaChange, setMediaChange] = useState(false)
    const [addMediaModalOpen, setAddMediaModalOpen] = useState(false)
    const fileReadUrl = useSelector(state => state?.configurations?.frfileReadUrl)
    const [modalOpen, setModalOpen] = useState(false)
    const [isMedia, setIsMedia] = useState(false)
    const { id } = useParams()
    const [selectedMedia, setSelectedMedia] = useState({})

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const mediaResult = await GET(`${process.env.REACT_APP_URL}ppm/v1/task/${id}`, "GET single PPM")
                setMedia(mediaResult?.data?.media)
                setLoading(false)
            } catch (error) {
                console.log(error)
                errorToast(error?.response?.data?.message || error?.message)
                setLoading(false)
            }
        })();
    }, [])

    const handleClose = () => {
        setModalOpen(false);
    }

    const plyrOptions = {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'airplay', 'fullscreen'],
        hideControls: false,
        ratio: "16:9",
        settings: ['captions', 'quality', 'speed', 'loop'],
    }

    const mediaTriggerhandle = () => {
        setMediaChange(!mediaChange)
    }

    const mediaShow = (media) => {
        setSelectedMedia(media)
        setModalOpen(true)
    }

    const handleImagesFormSubmit = async (ele) => {
        try {
            const response = await PUT(`${process.env.REACT_APP_URL}ppm/v1/task/media/${id}`,
                { newMediaArray: [ele] },
                "Update Fault for Media")
            // const response = await PUT(`http://localhost:8030/v1/task/media/${id}`, { newMediaArray: [ele] }, "Update Fault for Media")
            // setMedia(response?.data?.media)
            setMedia(response?.data?.data)
            if (response?.status === 200) {
                successToast('updated media in ppm')
            }

        } catch (error) {
            console.log(error)
        }

    }

    const handleImage = async ({ event }) => {
        try {
            const maxLimit = 5
            //check maxMedia count
            if (media?.length < maxLimit) {
                const fileData = event.target.files[0]
                const formData = new FormData()
                formData.append('file', fileData)
                // setUploadingMedia(true)
                const response = await FILEPOST(`${process.env.REACT_APP_URL}ppm/v1/media/upload`, formData, 'File Upload : Add Image', true)
                handleImagesFormSubmit(response.data)
                if (response?.status === 200) {
                    setMedia([...media, response?.data])
                    setIsMedia(!isMedia)
                    // setUploadingMedia(false)
                    // successToast("Image Uploaded Successfully")
                }
            } else {
                errorToast(`You can upload only ${maxLimit} medias`)
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
            // setUploadingMedia(false)
            console.log(error)
        }
    }

    const handleDeleteMedia = async (ele) => {
        try {
            swal({
                title: 'Alert',
                text: 'Are you sure you want to permanently delete this media?',
                icon: 'warning',
                buttons: true,
                dangerMode: true,
            }).then(async (confirm) => {
                if (confirm) {
                    const response = await DELETE(`${process.env.REACT_APP_URL}ppm/v1/media?uploadId=${ele?.uploadId}`, "Delete Media")
                    if (response.status === 200) {
                        const updatePPM = await DELETE(`${process.env.REACT_APP_URL}ppm/v1/task/media/${id}/?uploadId=${ele?.uploadId}`)
                        // const updatePPM = await DELETE(`http://localhost:8030/v1/task/media/${id}/?uploadId=${ele?.uploadId}`)
                        setMedia(updatePPM?.data?.data?.media)
                        successToast("Media Deleted Successfully")
                    }
                }
            })


        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
            console.log(error)
        }

    }

    return (
        <>
            {!loading ?
                <CustomComponentContainer
                    sx={{
                        margin: '1rem',
                        padding: "1rem",
                    }}
                >
                    <Grid container spacing={2}>
                        {/* <AddMediasForm sx={{ height: "10rem", width: "100vw" }} setMedia={setMedia} /> */}
                        <Grid item sm={6} md={4} lg={3} xl={2}>
                            <AddMediaCard
                                sx={{ height: "10rem", width: "15rem" }}
                                type="add"
                                handleAddImage={handleImage}
                            // id={id}
                            />
                        </Grid>

                        {media?.map((ele, i) => (
                            <Grid
                                key={i}
                                item sm={6} md={4} lg={3} xl={2}
                            >
                                <Box sx={{
                                    position: "relative",
                                    backgroundColor: themeMode ? '#5a5a5a' : '#eceff4',
                                    borderRadius: '1rem',
                                    width: "15rem", // Ensure the width is dynamic based on the grid size
                                    height: "10rem",
                                    overflow: "hidden",
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    {/* Handle image, video, or pdf rendering */}
                                    {ele?.type?.includes("image") ? (
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            width: '100%',
                                            height: '100%',
                                            overflow: 'hidden',

                                        }}
                                            onClick={() => { mediaShow(ele) }}
                                        >
                                            <img
                                                src={`${fileReadUrl}${ele?.key}`}
                                                style={{
                                                    cursor: 'pointer',
                                                    objectFit: 'contain',
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                                alt={ele?.key}
                                            />
                                        </Box>
                                    ) : ele?.type?.includes("pdf") ? (
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            height: "10rem",
                                            overflow: 'hidden',
                                            cursor: 'pointer',
                                            borderRadius: '1rem',
                                            objectFit: "fill"
                                        }}>
                                            <iframe
                                                title="pdf"
                                                src={`${fileReadUrl}${ele?.key}`}
                                                type="application/pdf"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    pointerEvents: 'none',
                                                }}
                                            />
                                        </Box>
                                    ) : ele?.type?.includes("video") ? (
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            borderRadius: '1rem',
                                            height: "10rem",
                                            overflow: 'hidden',
                                        }}>
                                            <Plyr
                                                source={{ type: "video", sources: [{ src: `${fileReadUrl}${ele?.key}` }] }}
                                                options={plyrOptions}
                                            />
                                        </Box>
                                    ) : (
                                        <Box>
                                            <p>No Media</p>
                                        </Box>
                                    )}

                                    {/* Delete icon for user-uploaded media */}

                                    <Avatar sx={{
                                        position: 'absolute',
                                        height: "1.5rem",
                                        width: "1.5rem",
                                        backgroundColor: "black",
                                        right: "1rem",
                                        top: "0.5rem"
                                    }}>
                                        <Delete
                                            fontSize="small"
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => handleDeleteMedia(ele)}
                                        />
                                    </Avatar>
                                </Box>
                            </Grid>
                        ))}
                    </Grid >
                </CustomComponentContainer >
                : <Loader height="70vh" />
            }

            <Modal
                modalOpen={modalOpen}
                closeModal={handleClose}
                height="62vh"
                modalWidth="50vw"
                title="Media"
                children={
                    <ReturnMultiTypeMedia fileReadUrl={fileReadUrl} media={selectedMedia} height="50vh" />
                }
            />
            <Modal
                modalOpen={addMediaModalOpen}
                closeModal={() => setAddMediaModalOpen(false)}
                height="40vh"
                modalWidth="40vw"
                title="Add Media"
                children={
                    <AddMediasForm
                        // id={fault?._id}
                        handleCloseModal={() => setAddMediaModalOpen(false)}
                        mediaTrigger={mediaTriggerhandle}
                    />
                }
            />
        </>
    )
}

export default Medias