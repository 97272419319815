import PPMModal from "./components/modal/PPMModal"
import PPMProvider from "./context/PPMContextProvider"
import PPMStatsProvider from "./context/PPMStatsProvider"
import CreatePPMForm from "./CreatePPMForm"

const EditPPMModal = (props) => {
    const { openUpdatePPMModal, handleCloseUpdatePPMModal, editingPPMId, handleCloseViewPPMModal, setCalendarEvents, selectedDateRange, selectedUpdatePPMOption } = props

    return (
        <PPMModal
            modalOpen={openUpdatePPMModal}
            closeModal={handleCloseUpdatePPMModal}
            modalWidth="60vw"
            title={'Update PPM'}
            children={<PPMProvider>
                <PPMStatsProvider>
                    <CreatePPMForm
                        assetId={editingPPMId}
                        handleCloseModal={handleCloseUpdatePPMModal}
                        handleCloseViewPPMModal={handleCloseViewPPMModal}
                        setCalendarEvents={setCalendarEvents}
                        selectedDateRange={selectedDateRange}
                        selectedUpdatePPMOption={selectedUpdatePPMOption}
                    />
                </PPMStatsProvider>
            </PPMProvider>}
        />
    )
}

export default EditPPMModal