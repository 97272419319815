

const SensorGroupsStyle = {
    boxContainer: { p: 1, width: '100%', minHeight: '850px', },
    headingTypo: {
        fontSize: '30px',
        fontWeight: '900',
        ml: 1,
        mb: '16px'
    },
    addButton: {
        color: 'white',
        borderColor: '#DC4D28',
        bgcolor: '#DC4D28',
        // mr: 3,
        width: '100%',
        height: '100%',
        maxHeight: 35,
        alignSelf: 'center',
        ':hover': { bgcolor: '#DC4D28' },
    },
    paperBoxContainer: {
        p: 1,
        mb: 2,
    },
    switchButton: {
        '& .MuiSwitch-switchBase': {
            color: '#ef5350',
            '&.Mui-checked': {
                color: '#ef5350',
            },
            '&.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#ef5350',
            }
        },
        "& .MuiSwitch-thumb": {
            color: '#ef5350'
        },
        '& .MuiSwitch-track': {
            backgroundColor: 'gray',
        },
    },
    loaderBox: { height: '80%', display: 'flex', justifyContent: "center", alignItems: "center" }
}

module.exports = SensorGroupsStyle