import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";
import { errorHandler } from "../../../components/facilityManager/helpers/http";
import { dropdownFloorsSort } from '../../../components/facilityManager/helpers/dropdownFloorsSort';
import { validData } from "../../../components/facilityManager/helpers/validData";

const selectedFloorId = (floorId) => (floorId !== "All Floors" && floorId?.length === 24) ? floorId : "all"

const fetchEnergySlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEnergyConsumption: builder.query({
            query: (bid) => ({
                url: `twin/v1/energy/s1/stats?bid=${bid}&duration=today&type=consumption`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Consumption')
            },
            providesTags: ['energy']
        }),
        getEnergySources: builder.query({
            query: (bid) => ({
                url: `twin/v1/energy/s1/stats?bid=${bid}&duration=today&type=sources`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Sources')
            },
            providesTags: ['energy']
        }),
        getEnergyWeekConsumption: builder.query({
            query: (bid) => ({
                url: `twin/v1/energy/s1/data?bid=${bid}&duration=week`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Week Consumption')
            },
            providesTags: ['energy']
        }),
        getEnergyCarbonEmission: builder.query({
            query: (bid) => ({
                url: `twin/v1/energy/s1/data/emission?bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Carbon Emission')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryOptions: builder.query({
            query: (bid) => ({
                url: `twin/v1/energy/s2/options?bid=${bid}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Options')
            },
            providesTags: ['energy']
        }),
        getEnergySecondarySourceFloorStats: builder.query({
            query: ({ bid, duration, floorId, startDate, endDate }) => ({
                url: `twin/v1/energy/s2/stats?bid=${bid}&duration=${duration}&floor=${selectedFloorId(floorId)}&type=source-floor&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Source Floor Stats')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryComparisonStats: builder.query({
            query: ({ bid, duration, floorId, sensorId, groupId, startDate, endDate }) => ({
                url: `twin/v1/energy/s2/stats?bid=${bid}&duration=${duration}&floor=${floorId ? selectedFloorId(floorId) : ""}&sensor=${sensorId ?? ""}&group=${groupId ?? ""}&type=comparison&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Comparison Stats')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryEstimation: builder.query({
            query: ({ bid, floorId, sensorId, groupId }) => ({
                url: `twin/v1/energy/s2/data/upcoming?bid=${bid}&floor=${floorId ? selectedFloorId(floorId) : ""}&sensor=${sensorId ?? ""}&group=${groupId ?? ""}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Estimation')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryTotalConsumptionChartData: builder.query({
            query: ({ bid, duration, floorId, sensorId, groupId, startDate, endDate }) => ({
                url: `twin/v1/energy/s2/data?bid=${bid}&duration=${duration}&floor=${floorId ? selectedFloorId(floorId) : ""}&sensor=${sensorId ?? ""}&group=${groupId ?? ""}&type=consumption&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Total Consumption Chart Data')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryWeekTotalConsumptionChartData: builder.query({
            query: ({ bid, duration, floorId, sensorId, groupId, startDate, endDate }) => ({
                url: `twin/v1/energy/s2/data?bid=${bid}&duration=${duration}&floor=${floorId ? selectedFloorId(floorId) : ""}&sensor=${sensorId ?? ""}&group=${groupId ?? ""}&type=consumption&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Week Total Consumption Chart Data')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryMonthwiseTotalConsumptionChartData: builder.query({
            query: ({ bid, floorId, sensorId, groupId }) => ({
                url: `twin/v1/energy/s2/consumption?bid=${bid}&floor=${floorId ? selectedFloorId(floorId) : ""}&sensor=${sensorId ?? ""}&group=${groupId ?? ""}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Monthwise Total Consumption Chart Data')
            },
            providesTags: ['energy']
        }),
        getEnergySecondarygetTCLast30Days: builder.query({
            query: ({ bid, duration, floorId, sensorId, groupId, startDate, endDate }) => ({
                url: `twin/v1/energy/s2/data?bid=${bid}&duration=${duration}&floor=${floorId ? selectedFloorId(floorId) : ""}&sensor=${sensorId ?? ""}&group=${groupId ?? ""}&type=consumption&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Total Consumption For Last 30 Days Chart Data')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryFrequencyChartData: builder.query({
            query: ({ bid, duration, floorId, startDate, endDate }) => ({
                url: `twin/v1/energy/s2/data?bid=${bid}&duration=${duration}&floor=${selectedFloorId(floorId)}&type=frequency&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Frequency Chart Data')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryPowerFactorDonutChartData: builder.query({
            query: ({ bid }) => ({
                url: `twin/v1/energy/s2/data?bid=${bid}&duration=Today&floor=all&type=powerfactor`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Power Factor Donut Chart Data')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryPowerFactorChartData: builder.query({
            query: ({ bid, duration, floorId, startDate, endDate }) => ({
                url: `twin/v1/energy/s2/data?bid=${bid}&duration=${duration}&floor=${selectedFloorId(floorId)}&type=powerfactor&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Power Factor Chart Data')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryVoltageChartData: builder.query({
            query: ({ bid, duration, floorId, startDate, endDate }) => ({
                url: `twin/v1/energy/s2/data?bid=${bid}&duration=${duration}&floor=${selectedFloorId(floorId)}&type=voltage&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Voltage Chart Data')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryCurrentChartData: builder.query({
            query: ({ bid, duration, floorId, sensorId, groupId, startDate, endDate }) => ({
                url: `twin/v1/energy/s2/data?bid=${bid}&duration=${duration}&floor=${floorId ? selectedFloorId(floorId) : ""}&sensor=${sensorId ?? ""}&group=${groupId ?? ""}&type=current&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Current Chart Data')
            },
            providesTags: ['energy']
        }),
        getEnergySecondaryRyybbrVoltageData: builder.query({
            query: ({ bid, duration, floorId, sensorId, groupId, startDate, endDate }) => ({
                url: `twin/v1/energy/s2/data?bid=${bid}&duration=${duration}&floor=${floorId ? selectedFloorId(floorId) : ""}&sensor=${sensorId ?? ""}&group=${groupId ?? ""}&type=ryybbr&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary RY-YB-BR Voltage Data')
            },
            providesTags: ['energy']
        }),
        getEnergyEquipmentConsumptionData: builder.query({
            query: ({ bid, duration, floorId, startDate, endDate }) => ({
                url: `twin/v1/energy/s2/stats?bid=${bid}&duration=${duration}&type=equipment-connected&floor=${selectedFloorId(floorId)}&startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Secondary Eneygy Consumption Data')
            },
            providesTags: ['energy']
        }),
        getEnergyGroupsStatisticsData: builder.query({
            query: ({ bid, duration, startDate, endDate }) => ({
                url: `twin/v1/energy/s2/groups?bid=${bid}&duration=${duration}&startDate=${startDate}&endDate=${endDate}`,
                method: "GET",
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Energy Groups Statistics Data')
            },
            providesTags: ['energy']
        })
    })
})

const initialState = {
    dashboard: {
        energyConsumption: {
            isLoaded: false,
            data: {}
        },
        energySources: {
            isLoaded: false,
            data: {}
        },
        energyWeek: {
            isLoaded: false,
            data: []
        },
        energyCarbonEmissions: {
            isLoaded: false,
            data: {}
        },
        sseData: {},
    },
    secondary: {
        menuOptions: {
            data: [],
            selectedOption: ""
        },
        floors: {
            isLoaded: false,
            error: "",
            data: [],
            selectedFloor: "",
        },
        sensors: {
            isLoaded: false,
            error: "",
            data: [],
            selectedSensor: "",
        },
        groups: {
            isLoaded: false,
            error: "",
            data: [],
            selectedGroup: ""
        },
        durations: {
            list: [],
            selected: 'Today'
        },
        sourceFloorStats: {
            isLoaded: false,
            error: "",
            consumptionBySource: {},
            consumptionByFloor: []
        },
        comparisonStats: {
            isLoaded: false,
            error: "",
            data: []
        },
        estimationData: {
            isLoaded: false,
            error: "",
            currentConsumption: 0,
            monthEstimation: 0
        },
        totalConsumptionChartData: {
            isLoaded: false,
            error: "",
            data: [],
            previousData: []
        },
        weekTotalConsumptionChartData: {
            isLoaded: false,
            error: "",
            data: [],
        },
        monthwiseTotalConsumptionChartData: {
            isLoaded: false,
            error: "",
            data: {},
        },
        totalConsumptionChart30DaysData: {
            isLoaded: false,
            error: "",
            data: [],
        },
        frequencyChartData: {
            isLoaded: false,
            error: "",
            data: [],
        },
        powerFactorDonutChartData: {
            isLoaded: false,
            error: "",
            data: []
        },
        avgPowerFactorChartData: {
            isLoaded: false,
            error: "",
            data: []
        },
        voltageChartData: {
            isLoaded: false,
            error: "",
            data: []
        },
        currentChartData: {
            isLoaded: false,
            error: "",
            data: []
        },
        ryVoltageChartData: {
            isLoaded: false,
            error: "",
            data: []
        },
        ybVoltageChartData: {
            isLoaded: false,
            error: "",
            data: []
        },
        brVoltageChartData: {
            isLoaded: false,
            error: "",
            data: []
        },
        equipmentConsumption: {
            isLoaded: false,
            data: []
        },
        groupStatistics: {
            isLoaded: false,
            error: "",
            data: []
        },
        margins: [10, 10, 25, 30],
        lineWidth: 2
    }
}

const chartDataFormatter = (payload) => {
    const data = payload?.map(ele => ({
        name: ele?.floor,
        color: ele?.floor === 'Ground Floor' ? '#8085E9' :
            ele?.floor === 'First Floor' ? '#4BBAA5' :
                ele?.floor === 'Second Floor' ? '#B3EB35' : '',
        data: ele?.data
    }))
    return data
}

const energySlice = createSlice({
    name: 'energy',
    initialState,
    reducers: {
        resetDashboardEnergy: (state, action) => {
            state.dashboard = initialState.dashboard
        },
        setEnergySecondaryDuration: (state, action) => {
            if (state.secondary.durations.selected !== action.payload.duration) {
                state.secondary.durations.selected = action.payload.duration
                state.secondary.groupStatistics.isLoaded = false
                if (action.payload.type === "floor") {
                    state.secondary.sourceFloorStats.isLoaded = false
                    state.secondary.comparisonStats.isLoaded = false
                    state.secondary.totalConsumptionChartData.isLoaded = false
                    state.secondary.frequencyChartData.isLoaded = false
                    state.secondary.avgPowerFactorChartData.isLoaded = false
                    state.secondary.currentChartData.isLoaded = false
                    state.secondary.voltageChartData.isLoaded = false
                    state.secondary.ryVoltageChartData.isLoaded = false
                    state.secondary.ybVoltageChartData.isLoaded = false
                    state.secondary.brVoltageChartData.isLoaded = false
                    state.secondary.equipmentConsumption.isLoaded = false

                } else if (action.payload.type === "meter") {
                    state.secondary.ryVoltageChartData.isLoaded = false
                    state.secondary.ybVoltageChartData.isLoaded = false
                    state.secondary.brVoltageChartData.isLoaded = false
                    state.secondary.currentChartData.isLoaded = false
                    state.secondary.comparisonStats.isLoaded = false
                    state.secondary.totalConsumptionChartData.isLoaded = false
                } else if (action.payload.type === "group") {
                    state.secondary.comparisonStats.isLoaded = false
                    state.secondary.totalConsumptionChartData.isLoaded = false
                }
                //state.secondary.totalConsumptionChart30DaysData.isLoaded = false
            }
        },
        setEnergySecondaryFloor: (state, action) => {
            state.secondary.floors.selectedFloor = action.payload
            state.secondary.durations.selected = "Today"

            state.secondary.comparisonStats.isLoaded = false
            state.secondary.sourceFloorStats.isLoaded = false
            state.secondary.totalConsumptionChartData.isLoaded = false
            state.secondary.frequencyChartData.isLoaded = false
            state.secondary.avgPowerFactorChartData.isLoaded = false
            state.secondary.currentChartData.isLoaded = false
            state.secondary.voltageChartData.isLoaded = false
            state.secondary.ryVoltageChartData.isLoaded = false
            state.secondary.ybVoltageChartData.isLoaded = false
            state.secondary.brVoltageChartData.isLoaded = false
            state.secondary.estimationData.isLoaded = false
            state.secondary.weekTotalConsumptionChartData.isLoaded = false
            state.secondary.monthwiseTotalConsumptionChartData.isLoaded = false
            state.secondary.equipmentConsumption.isLoaded = false
            state.secondary.groupStatistics.isLoaded = false
            state.secondary.totalConsumptionChart30DaysData.isLoaded = false
        },
        setEnergySecondarySensor: (state, action) => {
            state.secondary.sensors.selectedSensor = action.payload
            state.secondary.durations.selected = "Today"

            state.secondary.comparisonStats.isLoaded = false
            state.secondary.totalConsumptionChartData.isLoaded = false
            state.secondary.estimationData.isLoaded = false
            state.secondary.weekTotalConsumptionChartData.isLoaded = false
            state.secondary.monthwiseTotalConsumptionChartData.isLoaded = false
            state.secondary.totalConsumptionChart30DaysData.isLoaded = false

            state.secondary.currentChartData.isLoaded = false
            state.secondary.ryVoltageChartData.isLoaded = false
            state.secondary.ybVoltageChartData.isLoaded = false
            state.secondary.brVoltageChartData.isLoaded = false
            state.secondary.totalConsumptionChart30DaysData.isLoaded = false

        },
        setEnergySecondaryGroup: (state, action) => {
            state.secondary.groups.selectedGroup = action.payload
            state.secondary.durations.selected = "Today"

            state.secondary.comparisonStats.isLoaded = false
            state.secondary.totalConsumptionChartData.isLoaded = false
            state.secondary.estimationData.isLoaded = false
            state.secondary.weekTotalConsumptionChartData.isLoaded = false
            state.secondary.monthwiseTotalConsumptionChartData.isLoaded = false
            state.secondary.totalConsumptionChart30DaysData.isLoaded = false
            state.secondary.totalConsumptionChart30DaysData.isLoaded = false
        },
        setEnergySecondaryoption: (state, action) => {
            state.secondary.menuOptions.selectedOption = action.payload
            state.secondary.durations.selected = "Today"

            if (action.payload === "Floor") {
                state.secondary.comparisonStats.isLoaded = false
                state.secondary.sourceFloorStats.isLoaded = false
                state.secondary.estimationData.isLoaded = false
                state.secondary.equipmentConsumption.isLoaded = false
                state.secondary.totalConsumptionChartData.isLoaded = false
                state.secondary.weekTotalConsumptionChartData.isLoaded = false
                state.secondary.monthwiseTotalConsumptionChartData.isLoaded = false
                state.secondary.totalConsumptionChart30DaysData.isLoaded = false
                state.secondary.frequencyChartData.isLoaded = false
                //state.secondary.powerFactorDonutChartData.isLoaded = false
                state.secondary.avgPowerFactorChartData.isLoaded = false
                state.secondary.currentChartData.isLoaded = false
                state.secondary.voltageChartData.isLoaded = false
                state.secondary.ryVoltageChartData.isLoaded = false
                state.secondary.ybVoltageChartData.isLoaded = false
                state.secondary.brVoltageChartData.isLoaded = false

            } else if (action.payload === "Meter") {
                state.secondary.comparisonStats.isLoaded = false
                state.secondary.sourceFloorStats.consumptionByFloor = []
                state.secondary.sourceFloorStats.consumptionBySource = {
                    gridConsumption: -1,
                    dieselConsumption: -1,
                    solarConsumption: -1
                }
                state.secondary.estimationData.isLoaded = false
                state.secondary.equipmentConsumption.data = []
                state.secondary.totalConsumptionChartData.isLoaded = false
                state.secondary.weekTotalConsumptionChartData.isLoaded = false
                state.secondary.monthwiseTotalConsumptionChartData.isLoaded = false
                state.secondary.totalConsumptionChart30DaysData.isLoaded = false
                state.secondary.frequencyChartData.data = []
                //state.secondary.powerFactorDonutChartData.data = []
                state.secondary.avgPowerFactorChartData.data = []
                state.secondary.voltageChartData.data = []
                state.secondary.currentChartData.isLoaded = false
                state.secondary.ryVoltageChartData.isLoaded = false
                state.secondary.ybVoltageChartData.isLoaded = false
                state.secondary.brVoltageChartData.isLoaded = false

            } else if (action.payload === "Group") {
                state.secondary.comparisonStats.isLoaded = false
                state.secondary.sourceFloorStats.consumptionByFloor = []
                state.secondary.sourceFloorStats.consumptionBySource = {
                    gridConsumption: -1,
                    dieselConsumption: -1,
                    solarConsumption: -1
                }
                state.secondary.estimationData.isLoaded = false
                state.secondary.equipmentConsumption.data = []
                state.secondary.totalConsumptionChartData.isLoaded = false
                state.secondary.weekTotalConsumptionChartData.isLoaded = false
                state.secondary.monthwiseTotalConsumptionChartData.isLoaded = false
                state.secondary.totalConsumptionChart30DaysData.isLoaded = false
                state.secondary.frequencyChartData.data = []
                //state.secondary.powerFactorDonutChartData.data = []
                state.secondary.avgPowerFactorChartData.data = []
                state.secondary.currentChartData.data = []
                state.secondary.voltageChartData.data = []
                state.secondary.ryVoltageChartData.data = []
                state.secondary.ybVoltageChartData.data = []
                state.secondary.brVoltageChartData.data = []
            }
        },
        resetEnergySecondaryData: (state, action) => {
            state.secondary = initialState.secondary
        },
        updateSSEEnergyConsumption: (state, action) => {
            state.dashboard.energyConsumption.data.todayTillNow = Number((state.dashboard.energyConsumption.data.todayTillNow + action.payload?.energyDifference).toFixed(2))
            state.dashboard.energySources.data.gridConsumption = Number((state.dashboard.energySources.data.gridConsumption + action.payload?.energyDifference).toFixed(2))
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergyConsumption' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.dashboard.energyConsumption.isLoaded = true
                    state.dashboard.energyConsumption.data = action.payload?.data
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySources' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.dashboard.energySources.isLoaded = true
                    state.dashboard.energySources.data = action.payload?.data
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergyWeekConsumption' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.dashboard.energyWeek.isLoaded = true
                    state.dashboard.energyWeek.data = action.payload?.data
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergyCarbonEmission' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.dashboard.energyCarbonEmissions.isLoaded = true
                    state.dashboard.energyCarbonEmissions.data = action.payload?.data
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryOptions' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    const floors = structuredClone(action.payload?.data?.floors)
                    floors?.length > 0 && dropdownFloorsSort(floors)

                    state.secondary.floors.isLoaded = true
                    state.secondary.floors.data = floors
                    state.secondary.floors.selectedFloor = "All Floors"
                    // state.secondary.floors.selectedFloor = floors?.[0]?.id || "All Floors"
                    state.secondary.durations.list = action.payload?.data?.durations
                    state.secondary.sensors.isLoaded = true
                    state.secondary.sensors.data = action.payload?.data?.sensors
                    state.secondary.sensors.selectedSensor = action.payload?.data?.sensors[0]?.id

                    state.secondary.groups.isLoaded = true
                    state.secondary.groups.data = action.payload?.data?.groups
                    state.secondary.groups.selectedGroup = action.payload?.data?.groups[0]?.id

                    state.secondary.menuOptions.data = action.payload?.data?.menuOptions
                    state.secondary.menuOptions.selectedOption = action.payload?.data?.menuOptions[0]
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondarySourceFloorStats' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.sourceFloorStats.isLoaded = true
                    state.secondary.sourceFloorStats.consumptionBySource = action.payload?.data?.sourceConsumption
                    state.secondary.sourceFloorStats.consumptionByFloor = action.payload?.data?.floorsConsumption
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryComparisonStats' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.comparisonStats.isLoaded = true
                    state.secondary.comparisonStats.data = action.payload?.data
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryEstimation' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    let currentTotal = 0, estimationTotal = 0, comparisonPercentWithLastMonth = 0

                    action.payload?.data?.forEach(ele => {
                        validData(ele?.thisMonthConsumption) ? currentTotal += ele?.thisMonthConsumption : currentTotal += 0
                        validData(ele?.thisMonthEstimation) ? estimationTotal += ele?.thisMonthEstimation : estimationTotal += 0
                        validData(ele?.comparisonPercentWithLastMonth) ? comparisonPercentWithLastMonth += ele?.comparisonPercentWithLastMonth : comparisonPercentWithLastMonth += 0
                    })

                    state.secondary.estimationData.isLoaded = true
                    state.secondary.estimationData.currentConsumption = Number((currentTotal).toFixed(2))
                    state.secondary.estimationData.monthEstimation = Number((estimationTotal).toFixed(2))
                    state.secondary.estimationData.comparisonPercentWithLastMonth = Number((comparisonPercentWithLastMonth / action.payload?.data?.length).toFixed(2))
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryTotalConsumptionChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    const previousData = {
                        name: state.secondary.floors.selectedFloor === "All Floors" ? 'Previous Cumulative Data' : `${state.secondary.durations.selected?.toLowerCase() === 'today' ? `Yesterday` :
                            state.secondary.durations.selected?.toLowerCase() === 'week' ? 'Previous Week' :
                                state.secondary.durations.selected?.toLowerCase() === 'month' ? 'Previous Month' :
                                    state.secondary.durations.selected?.toLowerCase() === 'year' ? 'Previous Year' : ''}`,
                        type: 'spline',
                        data: action.payload?.data?.cumulativeData?.map(ele => {
                            return (!ele?.x) ? { x: null, ...ele } : ele
                        }),
                        tooltip: {
                            valueSuffix: ' kWh'
                        }
                    }

                    const weatherChartData = {
                        name: 'External Weather',
                        type: 'spline',
                        data: action.payload?.data?.weatherData,
                        yAxis: 1,
                        tooltip: {
                            valueSuffix: ' °C'
                        }
                    }

                    const durationIfCustom = state.secondary.durations?.selected?.toLowerCase() === 'custom'
                    state.secondary.totalConsumptionChartData.isLoaded = true
                    state.secondary.totalConsumptionChartData.data = durationIfCustom ? [...chartDataFormatter(action.payload?.data?.data)] : [...chartDataFormatter(action.payload?.data?.data), previousData, weatherChartData]
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryWeekTotalConsumptionChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    const previousData = {
                        name: state.secondary.floors.selectedFloor === "All Floors" ? 'Previous Cumulative Data' : 'Previous Week',
                        type: 'spline',
                        data: action.payload?.data?.cumulativeData?.map(ele => {
                            return (!ele?.x) ? { x: null, ...ele } : ele
                        })
                    }
                    state.secondary.weekTotalConsumptionChartData.isLoaded = true
                    state.secondary.weekTotalConsumptionChartData.data = [...chartDataFormatter(action.payload?.data?.data), previousData]
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryMonthwiseTotalConsumptionChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.monthwiseTotalConsumptionChartData.isLoaded = true
                    state.secondary.monthwiseTotalConsumptionChartData.data = action.payload?.data
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondarygetTCLast30Days' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    const previousData = {
                        name: state.secondary.floors.selectedFloor === "All Floors" ? 'Previous Cumulative Data' : `${state.secondary.durations.selected?.toLowerCase() === 'today' ? `Yesterday` :
                            state.secondary.durations.selected?.toLowerCase() === 'week' ? 'Previous Week' :
                                state.secondary.durations.selected?.toLowerCase() === 'month' ? 'Previous Month' :
                                    state.secondary.durations.selected?.toLowerCase() === 'year' ? 'Previous Year' : ''}`,
                        type: 'spline',
                        data: action.payload?.data?.cumulativeData?.map(ele => {
                            return (!ele?.x) ? { x: null, ...ele } : ele
                        }),
                        tooltip: {
                            valueSuffix: ' kWh'
                        }
                    }

                    // const weatherChartData = {
                    //     name: 'External Weather',
                    //     type: 'spline',
                    //     data: action.payload?.data?.weatherData,
                    //     yAxis: 1,
                    //     tooltip: {
                    //         valueSuffix: ' °C'
                    //     }
                    // }

                    const durationIfCustom = state.secondary.durations?.selected?.toLowerCase() === 'custom'
                    state.secondary.totalConsumptionChart30DaysData.isLoaded = true
                    state.secondary.totalConsumptionChart30DaysData.data = durationIfCustom ? [...chartDataFormatter(action.payload?.data?.data)] : [...chartDataFormatter(action.payload?.data?.data), previousData]
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryFrequencyChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.frequencyChartData.isLoaded = true
                    state.secondary.frequencyChartData.data = chartDataFormatter(action.payload?.data)
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryPowerFactorDonutChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.powerFactorDonutChartData.isLoaded = true
                    state.secondary.powerFactorDonutChartData.data = chartDataFormatter(action.payload?.data)
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryPowerFactorChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.avgPowerFactorChartData.isLoaded = true
                    state.secondary.avgPowerFactorChartData.data = chartDataFormatter(action.payload?.data)
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryVoltageChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.voltageChartData.isLoaded = true
                    state.secondary.voltageChartData.data = chartDataFormatter(action.payload?.data)
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryCurrentChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.currentChartData.isLoaded = true
                    state.secondary.currentChartData.data = chartDataFormatter(action.payload?.data)
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergySecondaryRyybbrVoltageData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    const ryData = action.payload?.data?.map(ele => ({
                        name: ele.floor,
                        color: ele.floor === 'Ground Floor' ? '#8085E9' :
                            ele.floor === 'First Floor' ? '#4BBAA5' :
                                ele.floor === 'Second Floor' ? '#B3EB35' : '',
                        data: ele.ryData
                    }))

                    const ybData = action.payload?.data?.map(ele => ({
                        name: ele.floor,
                        color: ele.floor === 'Ground Floor' ? '#8085E9' :
                            ele.floor === 'First Floor' ? '#4BBAA5' :
                                ele.floor === 'Second Floor' ? '#B3EB35' : '',
                        data: ele.ybData
                    }))

                    const brData = action.payload?.data?.map(ele => ({
                        name: ele.floor,
                        color: ele.floor === 'Ground Floor' ? '#8085E9' :
                            ele.floor === 'First Floor' ? '#4BBAA5' :
                                ele.floor === 'Second Floor' ? '#B3EB35' : '',
                        data: ele.brData
                    }))

                    state.secondary.ryVoltageChartData.isLoaded = true
                    state.secondary.ryVoltageChartData.data = ryData

                    state.secondary.ybVoltageChartData.isLoaded = true
                    state.secondary.ybVoltageChartData.data = ybData

                    state.secondary.brVoltageChartData.isLoaded = true
                    state.secondary.brVoltageChartData.data = brData
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergyEquipmentConsumptionData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.equipmentConsumption.isLoaded = true
                    state.secondary.equipmentConsumption.data = action.payload.data
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getEnergyGroupsStatisticsData' && action?.meta?.requestStatus === "fulfilled",
                (state, action) => {
                    state.secondary.groupStatistics.isLoaded = true
                    state.secondary.groupStatistics.data = action.payload
                }
            )
    }
})

export const {
    useGetEnergyConsumptionQuery,
    useGetEnergySourcesQuery,
    useGetEnergyWeekConsumptionQuery,
    useGetEnergyCarbonEmissionQuery,
    useGetEnergySecondaryOptionsQuery,
    useGetEnergySecondarySourceFloorStatsQuery,
    useGetEnergySecondaryComparisonStatsQuery,
    useGetEnergySecondaryEstimationQuery,
    useGetEnergySecondaryTotalConsumptionChartDataQuery,
    useGetEnergySecondaryWeekTotalConsumptionChartDataQuery,
    useGetEnergySecondaryMonthwiseTotalConsumptionChartDataQuery,
    useGetEnergySecondarygetTCLast30DaysQuery,
    useGetEnergySecondaryFrequencyChartDataQuery,
    useGetEnergySecondaryPowerFactorDonutChartDataQuery,
    useGetEnergySecondaryPowerFactorChartDataQuery,
    useGetEnergySecondaryVoltageChartDataQuery,
    useGetEnergySecondaryCurrentChartDataQuery,
    useGetEnergySecondaryRyybbrVoltageDataQuery,
    useGetEnergyEquipmentConsumptionDataQuery,
    useGetEnergyGroupsStatisticsDataQuery
} = fetchEnergySlice

export const { resetDashboardEnergy, setEnergySecondaryDuration, setEnergySecondaryFloor, setEnergySecondarySensor, setEnergySecondaryGroup, setEnergySecondaryoption, resetEnergySecondaryData, updateSSEEnergyConsumption } = energySlice.actions

export default energySlice.reducer