import React, { useEffect, useState } from 'react';
import {
    Box,
    Divider,
    Grid,
    MenuItem,
    Checkbox,
    ListItemText,
    Button,
    Autocomplete,
    TextField,
    Avatar,
    Input,
} from '@mui/material';
import { fetchBuildings, fetchDepartments, fetchUserList, handleCreateUser } from '../helpers';
import Loader from '../../../components/loading/Loader';
import CustomComponentContainer from '../../../components/container/CustomComponentContainer';
import CustomTypography from '../../../components/texts/CustomTypography';
import FormInput from '../../../components/formInputs/FormInput';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { ThemeColors } from '../../../hooks/ThemeColors';
import { useSelector } from 'react-redux';
import { DeleteOutlined, PhotoCamera } from '@mui/icons-material';
import ImageCropper from '../../settings/areas/components/ImageCropper';
import { optimizImg } from '../../../helpers/optimizeImg';
import { FILEPOST } from '../../../helpers/http';
import { errorToast, successToast } from '../../../helpers/apiToast';

const AddUser = () => {
    const { borderSX } = ThemeColors()
    const themeMode = useSelector(state => state.theme.darkTheme);
    const { fileReadUrl } = useSelector(state => state?.configurations);

    const [cropModal, setCropModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [buildingsList, setBuildingsList] = useState([]);
    const [departmentsList, setDepartmentsList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [selectedReportsTo, setSelectedReportsTo] = useState(null);
    const rolesList = ['super admin', 'admin', 'facility manager', 'employee'];
    const [formFields, setFormFields] = useState({
        fullName: '',
        email: '',
        mobile: '',
        address: '',
        building: '',
        buildings: [],
        department: '',
        about: '',
        customName: '',
        empCode: '',
        role: '',
        designation: '',
        employeeId: '',
        deskNumber: '',
        bloodGroup: '',
        parkingSlotNumber: '',
        dateOfBirth: '',
        dateOfJoining: '',
        dateOfLeaving: '',
        reportsTo: '',
        directReportsFrom: [],
        emergencyContactName: "",
        emergencyContactMobile: "",
        emergencyContactRelation: "",
    });

    const navigate = useNavigate();

    const userSchema = Yup.object().shape({
        fullName: Yup.string()
            .min(3, 'Full Name must be at least 3 characters')
            .required('Full Name is required'),
        email: Yup.string()
            .email('Email must be a valid email address')
            .required('Email is required'),
        mobile: Yup.string()
            .min(10, 'Mobile number must be at least 10 characters')
            .max(15, 'Mobile number must be less than 16 characters')
            .required('Mobile number is required'),
        building: Yup.string().required('Building is required'),
        buildings: Yup.array().min(1, 'At least one building must be selected').required('Buildings are required'),
        department: Yup.string().required('Department is required'),
        empCode: Yup.string().required('Employee Code is required'),
        role: Yup.string().required('Role is required'),
        designation: Yup.string().required('Designation is required'),
        employeeId: Yup.string().required('Employee ID is required'),
        deskNumber: Yup.string().optional(),
        bloodGroup: Yup.string().required('Blood Group is required'),
        dateOfBirth: Yup.date().required('Date of Birth is required'),
        dateOfJoining: Yup.date().required('Date of Joining is required'),
        reportsTo: Yup.string().required('Reports To is required'),
        directReportsFrom: Yup.array().min(1, 'At least one direct report must be selected').required('Direct Reports From is required'),
        emergencyContact: Yup.object().shape({
            name: Yup.string().required('Emergency Contact Name is required'),
            mobile: Yup.string()
                .min(10, 'Emergency Contact Mobile must be at least 10 characters')
                .max(15, 'Emergency Contact Mobile must be less than 16 characters')
                .required('Emergency Contact Mobile is required'),
            relation: Yup.string().required('Emergency Contact Relation is required'),
        }).required('Emergency Contact is required'),
        address: Yup.string().optional(),
        customName: Yup.string().optional(),
        profilePictureUrl: Yup.string().optional(),
    }).required();

    const userFields = [
        { name: 'fullName', label: 'Full Name' },
        { name: 'email', label: 'Email' },
        { name: 'mobile', label: 'Mobile' },
        { name: 'address', label: 'Address' },
        { name: 'building', label: 'Building', isSelect: true },
        { name: 'buildings', label: 'Buildings', isSelect: true, multiple: true },
        { name: 'department', label: 'Department', isSelect: true },
        { name: 'about', label: 'About' },
        { name: 'customName', label: 'Custom Name' },
        { name: 'empCode', label: 'Employee Code' },
        { name: 'role', label: 'Role', isSelect: true },
        { name: 'designation', label: 'Designation' },
        { name: 'employeeId', label: 'Employee ID' },
        { name: 'deskNumber', label: 'Desk Number' },
        { name: 'bloodGroup', label: 'Blood Group' },
        { name: 'parkingSlotNumber', label: 'Parking Slot Number' },
        { name: 'dateOfBirth', label: 'Date of Birth', type: 'date' },
        { name: 'dateOfJoining', label: 'Date of Joining', type: 'date' },
        { name: 'dateOfLeaving', label: 'Date of Leaving', type: 'date' },
    ];

    const emergencyFields = [
        { error: 'emergencyContact.name', name: 'emergencyContactName', label: 'Emergency Contact Name' },
        { error: 'emergencyContact.mobile', name: 'emergencyContactMobile', label: 'Emergency Contact Mobile' },
        { error: 'emergencyContact.relation', name: 'emergencyContactRelation', label: 'Emergency Contact Relation' },
    ];

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            setError(null);
            try {
                await Promise.all([
                    fetchBuildings({ setBuildings: setBuildingsList }),
                    fetchDepartments({ setDepartments: setDepartmentsList }),
                    fetchUserList({ setUserList }),
                ]);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError("Failed to load data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };
        loadData();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const payload = {
            fullName: formFields.fullName,
            email: formFields.email,
            role: formFields.role,
            empCode: formFields.empCode,
            mobile: formFields.mobile,
            customName: formFields.customName,
            address: formFields.address,
            buildingId: formFields.building,
            buildings: formFields.buildings,
            department: formFields.department,
            designation: formFields.designation,
            dateOfJoining: formFields.dateOfJoining,
            dateOfBirth: formFields.dateOfBirth,
            deskNumber: formFields.deskNumber,
            employeeId: formFields.employeeId,
            reportsTo: formFields.reportsTo,
            bloodGroup: formFields.bloodGroup,
            profilePictureUrl: selectedImage,

            // Emergency Contact
            emergencyContact: {
                name: formFields.emergencyContactName,
                mobile: formFields.emergencyContactMobile,
                relation: formFields.emergencyContactRelation,
            }
        };

        try {
            // Validate payload using Yup schema
            await userSchema.validate(payload, { abortEarly: false });

            setError(null);
            setValidationErrors({});
            const result = await handleCreateUser({ payload, setUserList: () => { } });
            if (result?.status === 201) {
                navigate('/admin/usermanagement');
            }
        } catch (error) {
            const errorMessages = error.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            setValidationErrors(errorMessages);
        }
    };

    if (loading) {
        return <Loader height={'100vh'} width={'100vw'} />;
    }

    const uploadCroppedImg = async (img) => {
        //optimize bfr Image
        const optImg = await optimizImg(img)
        // console.log("🚀 ~ afetr opt ~ img:", optImg.size / 1000, 'kb')

        const myFile = new File([optImg], 'image.jpeg', {
            type: optImg.type,
        });

        //upload Img
        let formData = new FormData();
        formData.append('file', myFile)
        FILEPOST(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/user/image`, formData, "IMAGE UPLOAD")
            .then((data) => {
                if (data?.status === 201) {
                    successToast('Image uploaded successfully!!');
                    setSelectedImage(data?.data)
                }
                else errorToast('Something went wrong!!');
            })
            .catch((error) => {
                console.log(error.message)
                errorToast(error?.response?.data?.message || error.message)
            })
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', minHeight: '89vh' }}>
            <CustomComponentContainer className='mrgn20' sx={{ borderRadius: '.5rem !important' }}>
                <Box component="form" onSubmit={handleSubmit} className='pad16'>
                    {error && <CustomTypography color="error">{error}</CustomTypography>}
                    <CustomTypography variant="h4" gutterBottom>
                        Add New User
                    </CustomTypography>

                    <Divider sx={{ backgroundColor: 'grey', marginBottom: 2 }} />

                    <CustomTypography variant="h6" gutterBottom>
                        User Profile
                    </CustomTypography>

                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    width: 'fit-content',
                                    '&:hover .backdrop': {
                                        display: 'flex',

                                    },
                                }}
                            >
                                <Avatar
                                    src={`${fileReadUrl}${selectedImage}`}
                                    sx={{ width: 200, height: 200, marginRight: 2 }}
                                />

                                {selectedImage ? (
                                    <Box
                                        className="backdrop"
                                        sx={{
                                            display: 'none',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: 200,
                                            borderRadius: '50%',
                                            cursor: 'pointer',
                                            height: 200,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: 'white',
                                            zIndex: 1,
                                        }}
                                    >
                                        <DeleteOutlined sx={{ fontSize: 48 }} onClick={() => setSelectedImage(null)} />
                                    </Box>
                                ) : <label htmlFor="icon-button-file">
                                    <Input
                                        accept="image/*"
                                        id="icon-button-file"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => {
                                            setSelectedImage(URL.createObjectURL(e.target.files[0]))
                                            setCropModal(true)
                                        }}
                                    />
                                    <Box
                                        className="backdrop"
                                        sx={{
                                            display: 'none',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: 200,
                                            borderRadius: '50%',
                                            cursor: 'pointer',
                                            height: 200,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: 'white',
                                            zIndex: 1,
                                        }}
                                    >
                                        <PhotoCamera sx={{ fontSize: 48 }} />
                                    </Box>
                                </label>
                                }
                            </Box>

                        </Grid>
                    </Grid>

                    {
                        cropModal && <ImageCropper
                            img={selectedImage}
                            setImg={setSelectedImage}
                            open={cropModal}
                            close={() => setCropModal(false)}
                            uploadCroppedImg={uploadCroppedImg}
                        />
                    }

                    <CustomTypography variant="subtitle1" gutterBottom>
                        Please fill out the user information below.
                    </CustomTypography>
                    <Grid container spacing={2}>
                        {userFields.map((field) => (
                            <Grid item xs={12} sm={6} md={4} key={field.name}>
                                <FormInput
                                    select={field.isSelect}
                                    size='small'
                                    type={field.type}
                                    value={formFields[field.name]}
                                    onChange={handleChange}
                                    fullWidth
                                    label={field.label}
                                    name={field.name}
                                    error={!!validationErrors[field.name]}
                                    helperText={validationErrors[field.name]}
                                    SelectProps={field.multiple ? {
                                        multiple: true,
                                        renderValue: (selected) => {
                                            return buildingsList.filter(b => selected.includes(b._id)).map(b => b.name).join(', ');
                                        },
                                    } : {}}
                                >
                                    {field.isSelect && field.name === 'building' && buildingsList.map((building) => (
                                        <MenuItem key={building._id} value={building._id}>
                                            {building.name}
                                        </MenuItem>
                                    ))}
                                    {field.isSelect && field.name === 'department' && departmentsList.map((department) => (
                                        <MenuItem key={department._id} value={department._id}>
                                            {department.name}
                                        </MenuItem>
                                    ))}
                                    {field.isSelect && field.name === 'role' && rolesList.map((role) => (
                                        <MenuItem key={role} value={role}>
                                            {role}
                                        </MenuItem>
                                    ))}
                                    {field.isSelect && field.name === 'buildings' && buildingsList.map((building) => (
                                        <MenuItem key={building._id} value={building._id}>
                                            <Checkbox checked={formFields.buildings.indexOf(building._id) > -1} />
                                            <ListItemText primary={building.name} />
                                        </MenuItem>
                                    ))}
                                </FormInput>
                            </Grid>

                        ))}
                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                sx={borderSX}
                                SelectProps={{
                                    MenuProps: {
                                        className: themeMode ? 'menu-container-dark' : 'menu-container-light',
                                        PaperProps: { sx: { maxHeight: 250 } },
                                    },
                                }}
                                size='small'
                                fullWidth
                                value={formFields.reportsTo}
                                onChange={(event, newValue) => {
                                    setFormFields({ ...formFields, reportsTo: newValue });
                                }}
                                inputValue={selectedReportsTo}
                                onInputChange={(event, newInputValue) => {
                                    setSelectedReportsTo(newInputValue);
                                }}
                                id="reportsTo"
                                options={userList?.map(el => el.fullName)}
                                renderInput={(params) => <TextField {...params} label="Reports To" />}
                            />
                        </Grid>
                    </Grid>

                    {/* New Emergency Contact Section */}
                    <Divider sx={{ backgroundColor: 'grey', marginY: 2 }} />
                    <CustomTypography variant="h6" gutterBottom>
                        Emergency Contact Information
                    </CustomTypography>
                    <Grid container spacing={2}>
                        {emergencyFields.map((field) => (
                            <Grid item xs={12} sm={6} md={4} key={field.name}>
                                <FormInput
                                    size='small'
                                    value={formFields[field.name]}
                                    onChange={handleChange}
                                    fullWidth
                                    label={field.label}
                                    name={field.name}
                                    error={!!validationErrors[field.error]}
                                    helperText={validationErrors[field.error]}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Button type="submit" variant="contained" color="primary" sx={{
                        marginTop: 4,
                        backgroundColor: '#dc4d28',
                        '&:hover': { backgroundColor: '#c43e2d' },
                        width: '15%'
                    }}>
                        Submit
                    </Button>

                    <Button variant="contained" color="primary" sx={{
                        marginTop: 4,
                        backgroundColor: '#dc4d28',
                        '&:hover': { backgroundColor: '#c43e2d' },
                        width: '15%',
                        ml: 3
                    }}
                        onClick={() => navigate('/admin/usermanagement')}
                    >
                        cancel
                    </Button>

                </Box>
            </CustomComponentContainer >
        </Box >
    );
};

export default AddUser;

