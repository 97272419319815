import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";
import { GET_CSRF, errorHandler } from "../../../components/facilityManager/helpers/http";
import moment from "moment";
import dateFormatter from "../../../components/facilityManager/helpers/dateFormatter";

const fetchMeetingRoomsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        fetchMeetingRoomData: builder.mutation({
            query: ({ buildingId, areaIds }) => ({
                url: `twin/v1/meetingrooms/s1/occupancy/latest?buildingId=${buildingId}`,
                method: "POST",
                body: { areaIds },
                headers: { 'x-csrf-token': localStorage.getItem('csrfToken') },
                credentials: "include"
            }),
            transformErrorResponse: error => {
                if (error?.status === 400 && (error?.data?.error === 'Invalid csrf token' || error?.data?.message === 'Invalid csrf token')) GET_CSRF()
                else
                    errorHandler(error, 'Meetingroom data')
            },
        }),
        fetchDashboardMeetingBookedStatus: builder.query({
            query: (buildingId) => ({
                url: `mr/v1/meetingrooms/s1/booked/status?buildingId=${buildingId}&selected-time=${Date.now()}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: errorResponse => {
                errorHandler(errorResponse, 'Meetingroom dashboard data')
            }
        }),
        fetchMeetingRoomSecondaryOptions: builder.query({
            query: ({ buildingId, floorId, zoneId }) => ({
                url: `mr/v1/meetingrooms/s2/options?buildingId=${buildingId}&floor=${floorId || ''}&zone=${zoneId || ''}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: errorResponse => {
                errorHandler(errorResponse, 'Meeting Room Secondary Options')
            },
        }),
        fetchMeetingRoomSecondaryTwinOptions: builder.query({
            query: ({ buildingId, floorId, zoneId }) => ({
                url: `twin/v1/meetingrooms/s2/options?buildingId=${buildingId}&floor=${floorId || ''}&zone=${zoneId || ''}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: errorResponse => {
                errorHandler(errorResponse, 'Meeting Room Secondary Options')
            },
        }),
        fetchMeetingRoomBookingsData: builder.query({
            query: (buildingId) => ({
                url: `mr/v1/meetingrooms/s2/statistics?selected-date=${moment().format('YYYY-MM-DD')}&buildingId=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: errorResponse => {
                errorHandler(errorResponse, 'Meeting Room Secondary Bookings Data')
            },
        }),
        fetchAreawiseBookingsChartData: builder.query({
            query: ({ buildingId, area }) => ({
                url: `mr/v1/meetingrooms/s2/booked/data?selected-date=${moment().format('YYYY-MM-DD')}&buildingId=${buildingId}&areaId=${area.area}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: errorResponse => {
                errorHandler(errorResponse, 'Meeting Room Secondary Bookings ChartData')
            },
        }),

        // New Screens API for fidelity and other tenants
        fetchAllRoomsStatisticsData: builder.query({
            query: ({ selectedBuilding, selectedFloor, selectedZone, selectedDuration, excludeWeekend, startDate, endDate, selectedSeatingCapacity, startTime, endTime }) => ({
                url: `twin/v1/meetingrooms/s2/overall/statistics?buildingId=${selectedBuilding}&floor=${selectedFloor}&from=${startTime}&to=${endTime}&zone=${selectedZone || ''}&capacity=${selectedSeatingCapacity || ''}&weekend=${!excludeWeekend}${startDate ? `&startDate=${dateFormatter(startDate)}` : ''}${endDate ? `&endDate=${dateFormatter(endDate)}` : ''}&duration=${selectedDuration || ''}`,
                method: 'GET',
                credentials: 'include'
            }),
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'Meeting Room Secondary All Rooms Statistics Data')
            },
        }),
        fetchStatisticsData: builder.query({
            query: ({ selectedBuilding, selectedFloor, selectedZone, startDate, endDate, selectedSeatingCapacity, startTime, endTime }) => ({
                url: `twin/v1/meetingrooms/s2/statistics?buildingId=${selectedBuilding}&floor=${selectedFloor}&from=${startTime}&to=${endTime}&zone=${selectedZone || ''}&capacity=${selectedSeatingCapacity || ''}${startDate ? `&startDate=${dateFormatter(startDate)}` : ''}${endDate ? `&endDate=${dateFormatter(endDate)}` : ''}`,
                method: 'GET',
                credentials: 'include'
            }),
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'Meeting Room Secondary Statistics Data')
            },
        }),
        fetchSeatingCapacityStatisticsData: builder.query({
            query: ({ selectedBuilding, selectedFloor, selectedZone, startDate, endDate, selectedSeatingCapacity, startTime, endTime }) => ({
                url: `twin/v1/meetingrooms/s2/capacity/statistics?buildingId=${selectedBuilding}&floor=${selectedFloor}&from=${startTime}&to=${endTime}&zone=${selectedZone || ''}&capacity=${selectedSeatingCapacity || ''}${startDate ? `&startDate=${dateFormatter(startDate)}` : ''}${endDate ? `&endDate=${dateFormatter(endDate)}` : ''}`,
                method: 'GET',
                credentials: 'include'
            }),
            transformResponse: (responseData) => {
                return (responseData || [])?.map(ele => {
                    return {
                        ...ele,
                        name: `${ele.capacity} Seater Rooms`,
                        occupiedRooms: ele?.occupied || 0,
                        occupiedPercentage: ele?.percent || 0
                    }
                })
            },
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'Meeting Room Secondary Seating Capacity Statistics Data')
            },
        }),
        fetchOccupancyFloorwiseStatsChartData: builder.query({
            query: ({ selectedBuilding, selectedFloor, selectedZone, startDate, endDate, selectedSeatingCapacity, startTime, endTime }) => ({
                url: `twin/v1/meetingrooms/s2/floor-wise/statistics?buildingId=${selectedBuilding}&floor=${selectedFloor}&from=${startTime}&to=${endTime}&zone=${selectedZone || ''}&capacity=${selectedSeatingCapacity || ''}${startDate ? `&startDate=${dateFormatter(startDate)}` : ''}${endDate ? `&endDate=${dateFormatter(endDate)}` : ''}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Meeting Room Secondary Occupancy Floorwise Stats Chart Data')
            }
        }),
        fetchOccupancyTodayChartData: builder.query({
            query: ({ selectedBuilding, selectedFloor, selectedZone, startDate, endDate, selectedSeatingCapacity, startTime, endTime }) => ({
                url: `twin/v1/meetingrooms/s2/occupancy/chart?buildingId=${selectedBuilding}&floor=${selectedFloor}&from=${startTime}&to=${endTime}&zone=${selectedZone || ''}&capacity=${selectedSeatingCapacity || ''}${startDate ? `&startDate=${dateFormatter(startDate)}` : ''}${endDate ? `&endDate=${dateFormatter(endDate)}` : ''}`,
                method: 'GET',
                credentials: "include",
            }),
            transformResponse: (responseData) => {
                return {
                    ...responseData,
                    data: (responseData?.data || [])?.map(ele => ({
                        ...ele,
                        name: `${ele.name} (Total Rooms: ${ele.totalRooms})`,
                    }))
                }
            },
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Meeting Room Secondary Occupancy Today Chart Data')
            }
        }),
        fetchOccupancyDayWiseData: builder.query({
            query: ({ selectedBuilding, selectedFloor, startTime, endTime, excludeWeekend, selectedZone, startDate, endDate, selectedSeatingCapacity, selectedDuration, }) => ({
                url: `twin/v1/meetingrooms/s2/occupancy?buildingId=${selectedBuilding}&floor=${selectedFloor}&from=${startTime}&to=${endTime}&weekend=${!excludeWeekend}&zone=${selectedZone || ''}&capacity=${selectedSeatingCapacity || ''}${startDate ? `&startDate=${dateFormatter(startDate)}` : ''}${endDate ? `&endDate=${dateFormatter(endDate)}` : ''}&duration=${selectedDuration || 'today'}`,
                method: 'GET',
                credentials: 'include'
            }),
            transformResponse: (responseData) => {
                return {
                    ...responseData,
                    data: (responseData?.data || [])?.map(ele => ({
                        ...ele,
                        name: `${ele.name} (Max Hours: ${ele.maxHours})`,
                    }))
                }
            },
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'Meeting Room Secondary Occupancy Day Wise')
            },
        }),
        fetchMostUsedRoomsData: builder.query({
            query: ({ selectedBuilding, selectedFloor, startTime, endTime, excludeWeekend, selectedZone, startDate, endDate, selectedSeatingCapacity, selectedDuration, }) => ({
                url: `twin/v1/meetingrooms/s2/usage?buildingId=${selectedBuilding}&floor=${selectedFloor}&from=${startTime}&to=${endTime}&weekend=${!excludeWeekend}&zone=${selectedZone || ''}&capacity=${selectedSeatingCapacity || ''}${startDate ? `&startDate=${dateFormatter(startDate)}` : ''}${endDate ? `&endDate=${dateFormatter(endDate)}` : ''}&duration=${selectedDuration || 'today'}`,
                method: 'GET',
                credentials: 'include'
            }),
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'Meeting Room Secondary Most Used Rooms Data')
            },
        }),
        fetchUsageHeatmapData: builder.query({
            query: ({ selectedBuilding, selectedFloor, startTime, endTime, excludeWeekend, selectedZone, startDate, endDate, selectedSeatingCapacity, selectedDuration, heatMapSelectedDateOnly }) => ({
                url: `twin/v1/meetingrooms/s2/occupancy/heatmap/hourly?buildingId=${selectedBuilding}&floor=${selectedFloor}&from=${startTime}&to=${endTime}&weekend=${!excludeWeekend}&zone=${selectedZone || ''}&capacity=${selectedSeatingCapacity || ''}${startDate ? `&startDate=${dateFormatter(startDate)}` : ''}${endDate ? `&endDate=${dateFormatter(endDate)}` : ''}&selectedDateOnly=${!!heatMapSelectedDateOnly}&duration=${selectedDuration || 'today'}`,
                method: 'GET',
                credentials: 'include'
            }),
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'Meeting Room Secondary Occupancy Usage Heatmap')
            },
        }),
        fetchStatCardModalData: builder.query({
            query: ({ selectedBuilding, selectedFloorId, selectedZone, startDate, endDate, selectedSeatingCapacity, startTime, endTime, }) => ({
                url: `twin/v1/meetingrooms/s2/rooms?buildingId=${selectedBuilding}&from=${startTime}&to=${endTime}&floor=${selectedFloorId}&zone=${selectedZone || ''}&capacity=${selectedSeatingCapacity || ''}${startDate ? `&startDate=${dateFormatter(startDate)}` : ''}${endDate ? `&endDate=${dateFormatter(endDate)}` : ''}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'Meeting Room Stats Card Modal Data')
            },
        }),
        fetchSeatingCapacityStatCardModalData: builder.query({
            query: ({ selectedBuilding, selectedFloor, selectedZone, startDate, endDate, selectedSeatingCapacity, selectedDuration, startTime, endTime, }) => ({
                url: `twin/v1/meetingrooms/s2/rooms?buildingId=${selectedBuilding}&from=${startTime}&to=${endTime}&floor=${selectedFloor}&zone=${selectedZone || ''}&capacity=${selectedSeatingCapacity || ''}${startDate ? `&startDate=${dateFormatter(startDate)}` : ''}${endDate ? `&endDate=${dateFormatter(endDate)}` : ''}&duration=${selectedDuration || 'today'}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'Meeting Room Seating CapacityStats Card Modal Data')
            },
        }),
        fetchOccupancyStatusData: builder.query({
            query: ({ selectedBuilding, selectedFloor, selectedZone, startDate, endDate, excludeWeekend, selectedSeatingCapacity, selectedDuration, startTime, endTime, }) => ({
                url: `twin/v1/meetingrooms/s2/rooms?buildingId=${selectedBuilding}&from=${startTime}&to=${endTime}&weekend=${!excludeWeekend}&floor=${selectedFloor}&zone=${selectedZone || ''}&capacity=${selectedSeatingCapacity || ''}${startDate ? `&startDate=${dateFormatter(startDate)}` : ''}${endDate ? `&endDate=${dateFormatter(endDate)}` : ''}&duration=${selectedDuration || 'today'}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'Meeting Room Occupancy Status Data')
            },
        }),
        fetchOccupancyChartView: builder.query({
            query: ({ areaId, startTime, endTime, selectedBuilding, selectedDuration, startDate, endDate, excludeWeekends }) => ({
                url: `twin/v1/meetingrooms/s2/chart/${areaId}?buildingId=${selectedBuilding}&duration=${selectedDuration || 'today'}&weekend=${!excludeWeekends}&from=${startTime}&to=${endTime}${startDate ? `&startDate=${dateFormatter(startDate)}` : ''}${endDate ? `&endDate=${dateFormatter(endDate)}` : ''}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'Meeting Room Chart View')
            },
        }),
        fetchAreaAQIData: builder.query({
            query: ({ areaId, selectedBuilding, selectedDuration, startTime, endTime, startDate, endDate, excludeWeekends }) => ({
                url: `twin/v1/aqi/s3/meetingRooms/${areaId}?bid=${selectedBuilding}&from=${startTime}&to=${endTime}&duration=${selectedDuration || 'today'}&weekend=${!excludeWeekends}${startDate ? `&startDate=${dateFormatter(startDate)}` : ''}${endDate ? `&endDate=${dateFormatter(endDate)}` : ''}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'Meeting Room Area AQI Data')
            },
        }),
        fetchAreaImages: builder.query({
            query: ({ areaId, selectedBuilding, }) => ({
                url: `twin/v1/meetingrooms/images/${areaId}?buildingId=${selectedBuilding}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'Meeting Room Area Images')
            },
        }),
        fetchAreaUtilization: builder.query({
            query: ({ areaId, selectedBuilding, startTime, endTime, excludeWeekends }) => ({
                url: `twin/v1/meetingrooms/s2/utilization/${areaId}?buildingId=${selectedBuilding}&from=${startTime}&to=${endTime}&weekend=${!excludeWeekends}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'Meeting Room Area Utilization Data')
            },
        }),
        fetchAreaHeatmapData: builder.query({
            query: ({ areaId, selectedBuilding, selectedDuration, startTime, endTime, startDate, endDate, excludeWeekends, heatMapSelectedDateOnly }) => ({
                url: `twin/v1/meetingrooms/s2/occupancy/heatmap/${areaId}?buildingId=${selectedBuilding}&from=${startTime}&to=${endTime}&duration=${selectedDuration || 'today'}${startDate ? `&startDate=${dateFormatter(startDate)}` : ''}${endDate ? `&endDate=${dateFormatter(endDate)}` : ''}&weekend=${!excludeWeekends}&selectedDateOnly=${!!heatMapSelectedDateOnly}`,
                method: 'GET',
                credentials: 'include'
            }),
            transformErrorResponse: (errorResponse) => {
                errorHandler(errorResponse, 'Meeting Room Area Heatmap')
            },
        }),
    })
})

export const {
    useFetchMeetingRoomDataMutation,
    useFetchDashboardMeetingBookedStatusQuery,
    useFetchMeetingRoomSecondaryOptionsQuery,
    useFetchMeetingRoomSecondaryTwinOptionsQuery,
    useFetchMeetingRoomBookingsDataQuery,
    useFetchAreawiseBookingsChartDataQuery,

    // New Screens API for fidelity and other tenants
    useFetchAllRoomsStatisticsDataQuery,
    useFetchStatisticsDataQuery,
    useFetchSeatingCapacityStatisticsDataQuery,
    useFetchOccupancyFloorwiseStatsChartDataQuery,
    useFetchOccupancyTodayChartDataQuery,
    useFetchMostUsedRoomsDataQuery,
    useFetchOccupancyDayWiseDataQuery,
    useFetchUsageHeatmapDataQuery,
    useFetchStatCardModalDataQuery,
    useFetchSeatingCapacityStatCardModalDataQuery,
    useFetchOccupancyStatusDataQuery,
    useFetchOccupancyChartViewQuery,
    useFetchAreaAQIDataQuery,
    useFetchAreaImagesQuery,
    useFetchAreaUtilizationQuery,
    useFetchAreaHeatmapDataQuery,
} = fetchMeetingRoomsSlice

const initialState = {
    dashboard: {
        isLoaded: false,
        error: "",
        data: {},
        bookedStatus: {
            isLoaded: false,
            bookedStatus: {},
            roomsData: []
        },
    },
    secondary: {
        bookings: {
            isLoaded: false,
            bookingStatsIsloaded: false,
            chartData: [],
            stats: {}
        },
    }
}

const meetingRoomSlice = createSlice({
    name: "meetingRoomSlice",
    initialState,
    reducers: {
        resetDashboardMeetingRooms: (state, action) => {
            state.dashboard = initialState.dashboard
        },
        updateSseMeetingRoomData: (state, action) => {
            for (const ele in state?.dashboard?.data) {
                if (state?.dashboard?.data[ele]?.subarea === action.payload.subarea) {
                    state.dashboard.data[ele].meetingRoomOccupancy = action.payload.count
                } else if (action?.payload?.floor === "Second Floor" && state?.dashboard?.data[ele]?.floor === action?.payload?.floor) {
                    state.dashboard.data[ele].meetingRoomOccupancy = action.payload.count
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchMeetingRoomData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.dashboard.isLoaded = true;
                    state.dashboard.data = action.payload
                    if (state.dashboard.bookedStatus?.roomsData?.length === 0) {
                        if (Object.values(action.payload)?.length > 0) {
                            state.dashboard.bookedStatus.isLoaded = true
                        }
                        state.dashboard.bookedStatus.roomsData = Object.values(action.payload)
                    }
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchDashboardMeetingBookedStatus' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.dashboard.isLoaded = true;
                    state.dashboard.bookedStatus.isLoaded = true
                    state.dashboard.bookedStatus = { ...state.dashboard.bookedStatus, ...action.payload }
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchMeetingRoomBookingsData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.bookings.isLoaded = true
                    state.secondary.bookings.stats = action.payload
                    state.secondary.bookings.bookingStatsIsloaded = true
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'fetchAreawiseBookingsChartData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    const chartData = { ...state.secondary?.bookings?.chartData }
                    chartData[action.payload?.area] = action.payload?.data?.map(ele => {
                        return { ...ele, color: 'red', borderColor: 'none' }
                    })
                    state.secondary.bookings.isLoaded = true
                    state.secondary.bookings.chartData = chartData
                }
            )
    }
})

export const {
    resetDashboardMeetingRooms, updateSseMeetingRoomData
} = meetingRoomSlice.actions

export default meetingRoomSlice.reducer