

import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { ThemeColors } from "../../../../hooks/ThemeColors";


const PPMStatusDropdown = ({ selectedValue, dropdownOptions, handleChangeFun, sx }) => {
    const { commonSX, themeMode } = ThemeColors();
    const color = themeMode ? '#fff' : '#000'
    const bgColor = themeMode ? "black" : '#dcdcdc'

    return (
        <Box sx={{ ...sx, }}>
            <FormControl size="small" fullWidth>
                <Select
                    sx={{
                        ...commonSX,
                        '.MuiOutlinedInput-notchedOutline': {
                            border: `0px`,
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: `0px`,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            border: `0px`,
                        },
                        '.MuiSvgIcon-root ': {
                            fill:"black",
                        },
                        height: "2rem",
                        borderRadius: "5rem",
                        fontSize: "15px",
                        color:"black",
                        backgroundColor: "#dcdcdc",
                    }}
                    onChange={(e) => handleChangeFun(e)}
                    value={selectedValue}
                >
                    {dropdownOptions?.map((menu, i) => {
                        return <MenuItem
                            key={i}
                            sx={menu === selectedValue ? {
                                height: "0px",
                                padding: 0,
                                margin: 0,
                                opacity: 0,
                            } : {}}
                            value={menu}>
                            {menu}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>
        </Box>
    )
}

export default PPMStatusDropdown