import { Avatar, Box, Typography } from "@mui/material"
import moment from "moment"
import NoteTwoToneIcon from '@mui/icons-material/NoteTwoTone';
import TextFieldsTwoToneIcon from '@mui/icons-material/TextFieldsTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import GppMaybeTwoToneIcon from '@mui/icons-material/GppMaybeTwoTone';
import EditNoteIcon from '@mui/icons-material/EditNote';
import TrackChangesTwoToneIcon from '@mui/icons-material/TrackChangesTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import BackHandTwoToneIcon from '@mui/icons-material/BackHandTwoTone';
import PermMediaTwoToneIcon from '@mui/icons-material/PermMediaTwoTone';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import IconButton from '@mui/material/IconButton';
import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';
import HighlightedText from "./HighlightedText";

const HistoryCard = ({ data, type, handleHistoryDetail, isCardSelected, sx }) => {
    const avatarStyle = {
        position: "absolute",
        left: "-1.3rem",
        top: "3.5rem",
        zIndex: 1
    }

    const ReturnIcons = ({ type, value }) => {
        if (type === "notes") return (
            <Avatar
                sx={{ ...avatarStyle, backgroundColor: "khaki" }}>
                <NoteTwoToneIcon />
            </Avatar>)
        else if (type === "title") return (
            <Avatar
                sx={{ ...avatarStyle, backgroundColor: "darkslategray" }}>
                <TextFieldsTwoToneIcon />
            </Avatar>)
        else if (type === "description") return (
            <Avatar
                sx={{ ...avatarStyle, backgroundColor: "lightblue" }}>
                <ArticleTwoToneIcon />
            </Avatar>)
        else if (type === "priority") {
            if (value === "low") {
                return <Avatar
                    sx={{ ...avatarStyle, backgroundColor: "lightgreen" }}>
                    <GppMaybeTwoToneIcon />
                </Avatar>
            } else if (value === "medium") {
                return <Avatar
                    sx={{ ...avatarStyle, backgroundColor: "#FFA07A" }}>
                    <GppMaybeTwoToneIcon />
                </Avatar>
            } else {
                return < Avatar
                    sx={{
                        ...avatarStyle,
                        backgroundColor: "#ef5350"
                    }}>
                    <GppMaybeTwoToneIcon />
                </Avatar >
            }
        } else if (type === "status") {
            if (value === "closed") {
                return <Avatar
                    sx={{ ...avatarStyle, backgroundColor: "lightgreen" }}>
                    <CheckCircleTwoToneIcon />
                </Avatar >
            } else {
                return <Avatar
                    sx={{ ...avatarStyle, backgroundColor: "#FA8072" }}>
                    <TrackChangesTwoToneIcon />
                </Avatar >
            }

        } else if (type === "media") {
            return <Avatar
                sx={{ ...avatarStyle, backgroundColor: "lightblue" }}>
                <PermMediaTwoToneIcon />
            </Avatar >
        } else if (type === "ppm") {
            return <Avatar
                sx={{ ...avatarStyle, backgroundColor: "#ffd700" }}>
                <BackHandTwoToneIcon />
            </Avatar>
        } else if (type === 'cost') {
            return <Avatar
                sx={{ ...avatarStyle, backgroundColor: "#4682b4" }}>
                <PaymentsTwoToneIcon />
            </Avatar>
        } else return (
            <Avatar
                sx={{ ...avatarStyle, backgroundColor: "lightgreen" }}>
                <EditNoteIcon />
            </Avatar>)
    }

    return (
        <Box
            className="flex flexDirectionRow"
            sx={{ position: "relative", height: "6rem", paddingLeft: "5rem", ...sx }}
        >
            <Typography
                sx={{ width: "10rem", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                {moment(data?.updatedTime).format('Do MMMM YYYY hh:mm A')}
            </Typography>
            <Box
                sx={{ border: `1px dashed gray`, height: type === "last" ? "80%" : "100%", marginLeft: "2rem" }}>
            </Box>
            <Box sx={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "end" }}>
                <ReturnIcons type={data?.fieldType} value={data?.currentValue} />
                <Box sx={{ paddingLeft: "3rem" }}>
                    <Typography><HighlightedText text={data?.textInfo && data?.textInfo} /></Typography>
                    <Typography>by {data?.user?.fullName}</Typography>
                </Box>
            </Box>
            {(data?.fieldType === "cost" || data?.fieldType === "media" || data?.comment) && <IconButton
                sx={{ marginLeft: "2rem", height: "2.5rem", marginTop: "3rem", color: isCardSelected ? "black" : 'lightgray' }}
                onClick={() => handleHistoryDetail({ historyObj: data })}>
                <ArrowCircleRightIcon size="large" />
            </IconButton>}
        </Box>
    )
}

export default HistoryCard