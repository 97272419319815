import { Avatar, Box, Button, Chip, IconButton, Stack, Tooltip } from "@mui/material"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from "moment";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../../../helpers/apiToast";
import { GET, PUT } from "../../../../helpers/http";
import PPMStatusDropdown from "./PPMStatusDropdown";
import FormInput from "../../../../components/formInputs/FormInput";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ThemeColors } from "../../../../hooks/ThemeColors";

const DetailsInfo = ({ task, capitalizeFirst, fetchSingleTask }) => {
    const [selectedStatus, setSelectedStatus] = useState(task?.taskStatus ?? '')
    const [taskOptions, setTaskOptions] = useState([]);
    const [taskoptionsLoaded, setTaskOptionsLoaded] = useState(false)
    const [overdueDateTime, setOverdueDateTime] = useState(task?.overdueFields?.overdueFrom ?? "")
    const { textColor, bgColor, themeMode } = ThemeColors()
    const bgColorForChip = themeMode ? "black" : '#dcdcdc'
    const muiChipStyle = {
        width: "10vw",
        display: "flex",
        justifyContent: "start",
        backgroundColor: "#dcdcdc",
        "& .MuiChip-label": {
            color: "black"
        }
    }
    console.log("🚀 ~ DetailsInfo ~ overdueDateTime:", task)

    const getAllTaskStatus = async ({ id, setTaskOptions }) => {
        try {
            const response = await GET(`${process.env.REACT_APP_URL}ppm/v1/task/status-options?id=${id}`, 'Get Task Status');
            if (response.status === 200) {
                setTaskOptions(response?.data);
                setTaskOptionsLoaded(true)
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message);
        }
    };


    useEffect(() => {
        (async () => {
            if (task?.id) {
                await getAllTaskStatus({ id: task?.id, setTaskOptions });
            }
        })()
    }, [task?.id])

    const handleDropDownChange = async (e) => {
        const value = e.target.value;
        try {
            const response = await PUT(`${process.env.REACT_APP_URL}ppm/v1/task/status`, { ppmId: task?.id, taskStatus: value }, 'Change Task Status');
            if (response.status === 200) {
                setSelectedStatus(value)
                fetchSingleTask(task?.id)
                successToast("Status Updated")
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message);
        }
    };

    const handleOverdueChange = async () => {
        try {
            const response = await PUT(`${process.env.REACT_APP_URL}ppm/v1/task/overdue`, {
                ppmId: task?.id,
                overdueFields: {
                    isOverdue: true,
                    overdueFrom: new Date(overdueDateTime).toISOString()
                }
            }, 'Change Overdue Date');
            if (response.status === 200) {
                fetchSingleTask(task.id)
                successToast("Overdue Updated")
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message);
        }
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "start",
            // height: "35vh",
            height: "100%",
            paddingTop: "4rem"
        }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: 'start',
                    width: "100%",
                    gap: 5,
                    alignItems: 'center',
                    marginLeft: "2rem",
                }}>
                <Stack direction="column">
                    <div className="color-darkgray font16 fontweight600 height50px">Status</div>
                    <div className="color-darkgray font16 fontweight600 height50px">
                        Priority
                    </div>
                    <div className="color-darkgray font16 fontweight600 height50px">
                        Assignment Status
                    </div>
                    <div className="color-darkgray font16 fontweight600 height50px">
                        Assigned To
                    </div>
                    <div className="color-darkgray font16 fontweight600 height50px">
                        Assigned Vendor
                    </div>
                    <div className="color-darkgray font16 fontweight600 height50px">
                        Start Time
                    </div>
                    <div className="color-darkgray font16 fontweight600 height50px">
                        End Time
                    </div>
                </Stack>
                <Stack direction="column" >
                    <div className="font16 fontweight600 height50px" >
                        {taskoptionsLoaded && <PPMStatusDropdown
                            selectedValue={selectedStatus}
                            dropdownOptions={[task?.taskStatus, ...taskOptions]}
                            handleChangeFun={handleDropDownChange}
                            capitalizeFirst={capitalizeFirst}
                        />}
                    </div>
                    <div className="font16 fontweight600 height50px" style={{ color: textColor() }}>
                        <Chip
                            sx={{
                                backgroundColor: "#ECFFE6", border: "1px solid #88D66C", width: "10vw", display: "flex", justifyContent: "start", "& .MuiChip-label": {
                                    color: "black"
                                }
                            }}
                            avatar={<FiberManualRecordIcon fontSize="small" style={{ color: "#88D66C" }} />}
                            label={capitalizeFirst(task?.priority)} />
                    </div>
                    <div className="font16 fontweight600 height50px" style={{ color: textColor() }}>{capitalizeFirst(task?.assignmentStatus)}</div>
                    <div className="font16 fontweight600 height50px" style={{ color: textColor() }}>
                        <Chip
                            sx={{
                                width: "10vw", display: "flex", justifyContent: "start", backgroundColor: "#dcdcdc",
                                "& .MuiChip-label": {
                                    color: "black"
                                }
                            }}
                            avatar={<Avatar alt={task?.currentService?.assignedUser?.name}
                                src={task?.currentService?.assignedUser?.profilePictureUrl} />}
                            label={<Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "7vw"
                            }}>
                                <>
                                    {capitalizeFirst(task?.currentService?.assignedUser?.name)}
                                </>
                                {task?.currentService?.assignedUserType === "external" && <Tooltip title="External User">
                                    <ArrowOutwardIcon fontSize="small" /> </Tooltip>}
                            </Box>
                            }
                        />

                    </div>
                    <div className="font16 fontweight600 height50px" style={{ color: textColor() }}>
                        {task?.currentService?.assignedVendor?.name ? <Chip
                            sx={{
                                width: "10vw",
                                display: "flex",
                                justifyContent: "start",
                                backgroundColor: "#dcdcdc",
                                "& .MuiChip-label": {
                                    color: "black"
                                }
                            }}
                            avatar={<Avatar alt={task?.currentService?.assignedVendor?.name} src={task?.currentService?.assignedVendor?.profilePictureUrl} />}
                            label={`${capitalizeFirst(task?.currentService?.assignedVendor?.name)}`}
                        /> : "-"}
                    </div>
                    <div className="font16 fontweight600 height50px" style={{ color: textColor() }}>{moment(`${task?.date}T${task?.startTime}`).format('lll')}</div>
                    <div className="font16 fontweight600 height50px" style={{ color: textColor() }}>{moment(`${task?.date}T${task?.endTime}`).format('lll')}</div>
                </Stack>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: 'start',
                    gap: 5,
                    alignItems: 'center',
                    marginLeft: "2rem",
                }}>
                <Stack direction="column" >
                    <div className="color-darkgray font16 fontweight600 height50px">
                        Overdue On
                    </div>
                    {/* <div className="color-darkgray font16 fontweight600 height50px">Building</div> */}
                    <div className="color-darkgray font16 fontweight600 height50px">Floor</div>
                    <div className="color-darkgray font16 fontweight600 height50px">Area</div>
                    <div className="color-darkgray font16 fontweight600 height50px">Category</div>
                    <div className="color-darkgray font16 fontweight600 height50px">Asset</div>
                    <div className="color-darkgray font16 fontweight600 height50px">Created By</div>
                </Stack>
                <Stack direction="column">
                    <div className="color-darkslategrey font16 fontweight600 height50px gap16">
                        {/* {capitalizeFirst(moment(task?.overdueFields?.overdueFrom).format('lll'))} */}
                        <FormInput
                            size='small'
                            type='datetime-local'
                            focused
                            // fullWidth
                            // name='date'
                            sx={{
                                '.MuiInputBase-root': {
                                    height: "2rem",
                                    borderRadius: "5rem",
                                    width: "12rem",
                                    fontSize: "14px"
                                }
                            }}
                            inputProps={{
                                min: moment(`${task?.date}T${task?.endTime}`).format('YYYY-MM-DD HH:mm'),
                            }}
                            value={moment(overdueDateTime).format('YYYY-MM-DD HH:mm')}
                            onChange={(e) => setOverdueDateTime(e.target.value)}
                        />
                        {moment(overdueDateTime).format('YYYY-MM-DD HH:mm') !== moment(task?.overdueFields?.overdueFrom).format('YYYY-MM-DD HH:mm') && <IconButton onClick={handleOverdueChange}>
                            <AddCircleIcon fontSize="small" sx={{ color: "black" }} />
                        </IconButton>}
                    </div>
                    {/* <div className="color-darkslategrey font16 fontweight600 height50px">{task?.building?.name}</div> */}
                    <div className="color-darkslategrey font16 fontweight600 height50px" style={{ color: textColor() }}>{task?.floor?.name ?? "-"}</div>
                    <div className="color-darkslategrey font16 fontweight600 height50px" style={{ color: textColor() }}>{task?.area?.name ?? "-"}</div>
                    <div className="color-darkslategrey font16 fontweight600 height50px" style={{ color: textColor() }}>{task?.category?.name ?? "-"}</div>
                    <div className="color-darkslategrey font16 fontweight600 height50px" style={{ color: textColor() }}>{task?.assetRef?.name ?? "-"}</div>
                    <div className="color-darkslategrey font16 fontweight600 height50px" style={{ color: textColor() }}>
                        <Chip
                            sx={{
                                width: "10vw", display: "flex",
                                justifyContent: "start",
                                backgroundColor: "#dcdcdc",
                                "& .MuiChip-label": {
                                    color: "black"
                                }
                            }}
                            avatar={<Avatar alt={task?.createdBy?.name} src={task?.createdBy?.profilePictureUrl} />}
                            label={<Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "7vw",
                            }}>
                                <>
                                    {capitalizeFirst(task?.createdBy?.name)}
                                </>
                                {task?.createdBy?.userType === "external" && <Tooltip title="External User">
                                    <ArrowOutwardIcon fontSize="small" /> </Tooltip>}
                            </Box>
                            }
                        />
                    </div>
                </Stack>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: 'start',
                    gap: 5,
                    alignItems: 'center',
                    marginLeft: "2rem"
                }}>
                {task?.isRecursive && <>
                    <Stack direction="column"  >
                        <div className="color-darkgray font16 fontweight600 height50px ">Frequency</div>
                        <div className="color-darkgray font16 fontweight600 height50px">Interval</div>
                        <div className="color-darkgray font16 fontweight600 height50px">Until</div>
                        {task?.isRecursive && <div className="color-darkgray font16 fontweight600 height50px">
                            <Chip
                                sx={muiChipStyle}
                                avatar={task?.recursiveConfiguration?.avoidSaturday ? <CheckCircleTwoToneIcon fontSize="small" /> : < CancelTwoToneIcon fontSize="small" />}
                                label={"Avoid Saturday"} />
                        </div>}
                    </Stack>
                    <Stack direction="column">
                        <div className="font16 fontweight600 height50px" style={{ color: textColor() }}>{task?.recursiveConfiguration?.frequency ?? "-"}</div>
                        <div className="font16 fontweight600 height50px" style={{ color: textColor() }}>{task?.recursiveConfiguration?.interval ?? "-"}</div>
                        <div className="font16 fontweight600 height50px" style={{ color: textColor() }}>{task?.recursiveConfiguration?.until ?? "-"}</div>
                        {task?.isRecursive && <div className="font16 fontweight600 height50px" >
                            <Chip
                                sx={muiChipStyle}
                                avatar={task?.recursiveConfiguration?.avoidSunday ? <CheckCircleTwoToneIcon fontSize="small" /> : < CancelTwoToneIcon fontSize="small" />}
                                label={"Avoid Sunday"} />
                        </div>}
                        {/* <div>{task?.textInfo}</div> */}
                    </Stack>
                </>}
            </Box>
        </Box >
    )
}

export default DetailsInfo