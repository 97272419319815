import { Box, styled } from "@mui/material"
import MuiDrawer from '@mui/material/Drawer'
import {
    addAssetExcel, addAssetExcelWhite, addAssetForm, addAssetFormWhite, assetExpiring, assetExpiringWhite, assetList,
    assetListWhite, assetUnderMaintenance, assetInsuranceWhite, assetUnderMaintenanceWhite, assetInsurance, analytics, analyticsWhite,
    assetMgmt, assetMgmtWhite, cctv, cctvWhite, deviceMgmt, deviceMgmtWhite, document, documentWhite, faultReport, faultReportWhite,
    feedback, feedbackWhite, home, homeWhite, ppm, ppmWhite, settings, settingsWhite, support, supportWhite, twin, twinWhite,
    visitorMgmt, visitorMgmtWhite, desk, deskWhite, energy, energyWhite, alerts, alertsWhite, meetingRoom, meetingRoomWhite,
    occupancy, occupancyWhite, restroom, restroomWhite, aqi, aqiWhite, water, waterWhite, autodesk, autodeskWhite,
    mattReport, mattReportWhite, visitorsList, visitorsListWhite,
    FRFaultListBlack,
    FRFaultListWhite,
    PpmListWhite,
    PpmList,
} from "../../../../../data/imageUrls"

import ImageRenderer from "../../../components/image/ImageRenderer"

export const returnIcon = (LightIcon, DarkIcon, path, themeMode, location) => {
    return themeMode ? (
        <Box ml={0.5} height={24} width={20} >
            <ImageRenderer src={LightIcon} className='d20' />
        </Box>
    ) : (
        <Box ml={0.5} height={24} width={20}>
            {location.pathname === path ? <ImageRenderer src={LightIcon} className='d20' /> : <ImageRenderer src={DarkIcon} className='d20' />}
        </Box>
    )
}

export const servicesStatus = (services, user) => {
    return {
        "Home": true,
        'Digital Twin': services?.forge || services?.matterport,
        'Asset Management': services?.assetManagement,
        'Security (CCTV)': user?.tenantCode === "cultfit" ? true : services?.liveCamera,
        'Device Management': services?.deviceManagement || services?.deviceHealth,
        'Fault Reporting': services?.faultReports2,
        'Document Hub': services?.digitalDocumentation,
        'Visitor Management': services?.visitorManagement,
        'Meeting Rooms': services?.meetingRoomsTab,
        'Feedback': services?.feedback,
        'PPM': services?.ppm,
        'Maverick Data': true,
    }
}

const dashboardChildren = ({ services, underMaintenance }) => [
    {
        id: "energyconsumption",
        title: "Energy Consumption",
        icons: [energyWhite.src, energy.src],
        serviceStatus: services?.energy,
        maintenanceStatus: underMaintenance?.energy,
        path: '/dashboard/energyconsumption'
    },
    {
        id: "alerts",
        title: "Alerts",
        icons: [alertsWhite.src, alerts.src],
        serviceStatus: services?.alerts,
        maintenanceStatus: underMaintenance?.alerts,
        path: '/dashboard/alerts'
    },
    {
        id: "occupancy",
        title: "Occupancy",
        icons: [occupancyWhite.src, occupancy.src],
        serviceStatus: services?.occupancy,
        maintenanceStatus: underMaintenance?.occupancy,
        path: '/dashboard/occupancy'
    },
    {
        id: "deskBooking",
        title: "Desks",
        icons: [deskWhite.src, desk.src],
        serviceStatus: services?.deskBooking || services?.deskOccupancy,
        maintenanceStatus: underMaintenance?.deskBooking || underMaintenance?.deskOccupancy,
        path: '/dashboard/desks'
    },
    {
        id: "meetingRooms",
        title: "Meeting rooms",
        icons: [meetingRoomWhite.src, meetingRoom.src],
        serviceStatus: services?.meetingRoomsTwin || services?.meetingRoomsCalendar,
        maintenanceStatus: underMaintenance?.meetingRoomsTwin || underMaintenance?.meetingRoomsCalendar,
        path: '/dashboard/meetingrooms'
    },
    {
        id: "aqi",
        title: "AQI",
        icons: [aqiWhite.src, aqi.src],
        serviceStatus: services?.aqi,
        maintenanceStatus: underMaintenance?.aqi,
        path: '/dashboard/aqi'
    },
    {
        id: "restRooms",
        title: "Rest rooms",
        icons: [restroomWhite.src, restroom.src],
        serviceStatus: services?.restRooms,
        maintenanceStatus: underMaintenance?.restRooms,
        path: '/dashboard/washroom'
    },
    {
        id: "water",
        title: "Water Consumption",
        icons: [waterWhite.src, water.src],
        serviceStatus: services?.water,
        maintenanceStatus: underMaintenance?.water,
        path: '/dashboard/waterconsumption'
    }
].filter((item) => item.serviceStatus)

const digitalTwinChildren = ({ services, underMaintenance }) => [
    {
        id: "autodeskForge",
        title: "Autodesk Forge",
        icons: [autodeskWhite.src, autodesk.src],
        serviceStatus: services.forge,
        maintenanceStatus: underMaintenance.forge,
        path: '/digitaltwin'
    },
    {
        id: "matterport",
        title: "Matterport",
        icons: [mattReportWhite.src, mattReport.src],
        serviceStatus: services.matterport,
        maintenanceStatus: underMaintenance.matterport,
        path: '/digitaltwin/matterport'
    }
].filter((item) => item.serviceStatus)

export const listItem = ({ navigate, themeMode, location, services, underMaintenance }) => {
    return [
        {
            text: 'Home',
            icon: returnIcon(homeWhite.src, home.src, '/', themeMode, location),
            onClick: () => navigate('/'),
            path: '/',
            children: dashboardChildren({ services, underMaintenance }),
        },
        {
            text: 'Digital Twin',
            icon: returnIcon(
                twinWhite.src,
                twin.src,
                '/digitaltwin', themeMode, location
            ),
            onClick: () => navigate((!services?.forge && services?.matterport) ? '/digitaltwin/matterport' : (!services?.forge && !services?.matterport) ? '/' : '/digitaltwin'),
            path: '/digitaltwin',
            children: digitalTwinChildren({ services, underMaintenance })?.length === 1 ? [] : (services.forge && services.matterport) ? digitalTwinChildren({ services, underMaintenance }).filter((item) => item.path !== '/digitaltwin') : digitalTwinChildren({ services, underMaintenance }),
        },
        {
            text: 'Document Hub',
            icon: returnIcon(
                documentWhite.src,
                document.src,
                '/digitaldocumentation', themeMode, location
            ),
            onClick: () => navigate('/digitaldocumentation'),
            path: '/digitaldocumentation',
            children: [],
        },
        {
            text: 'Asset Management',
            icon: returnIcon(assetMgmtWhite.src, assetMgmt.src, '/assetmanagement', themeMode, location),
            onClick: () => navigate('/assetmanagement'),
            path: '/assetmanagement',
            children: [
                {
                    id: "assetsList",
                    title: "Assets List",
                    icons: [assetListWhite.src, assetList.src],
                    serviceStatus: services.assetManagement,
                    maintenanceStatus: underMaintenance.assetManagement,
                    path: '/assetmanagement/assetlist'
                },
                {
                    id: "addAnAssetForm",
                    title: "Add an Asset (Form)",
                    icons: [addAssetFormWhite.src, addAssetForm.src],
                    serviceStatus: services.assetManagement,
                    maintenanceStatus: underMaintenance.assetManagement,
                    path: '/assetmanagement/addassetform'
                },
                {
                    id: "addAnAssetExcel",
                    title: "Add an Asset (Excel)",
                    icons: [addAssetExcelWhite.src, addAssetExcel.src],
                    serviceStatus: services.assetManagement,
                    maintenanceStatus: underMaintenance.assetManagement,
                    path: '/assetmanagement/addassetxl'
                },
                {
                    id: "expiringWarrantyAssets",
                    title: "Expiring Warranty Assets",
                    icons: [assetExpiringWhite.src, assetExpiring.src],
                    serviceStatus: services.assetManagement,
                    maintenanceStatus: underMaintenance.assetManagement,
                    path: '/assetmanagement/secondary/expiry'
                },
                {
                    id: "assetsUnderMaintenance",
                    title: "Assets Under Maintenance",
                    icons: [assetUnderMaintenanceWhite.src, assetUnderMaintenance.src],
                    serviceStatus: services.assetManagement,
                    maintenanceStatus: underMaintenance.assetManagement,
                    path: '/assetmanagement/secondary/maintenance'
                },
                {
                    id: "assetInsurance",
                    title: "Assets Insurance",
                    icons: [assetInsuranceWhite.src, assetInsurance.src],
                    serviceStatus: services.assetManagement,
                    maintenanceStatus: underMaintenance.assetManagement,
                    path: '/assetmanagement/insurance'
                }
            ],
        },
        {
            text: 'Fault Reporting',
            icon: returnIcon(faultReportWhite.src, faultReport.src, '/faultreports', themeMode, location),
            onClick: () => navigate('/faultreports'),
            path: '/faultreports',
            children: [
                {
                    id: "tickets",
                    title: "Tickets",
                    icons: [FRFaultListWhite.src, FRFaultListBlack.src],
                    serviceStatus: services.faultReports2,
                    maintenanceStatus: underMaintenance.faultReports2,
                    path: '/faultreports/tickets'
                },
            ],
        },
        {
            text: 'Security (CCTV)',
            icon: returnIcon(cctvWhite.src, cctv.src, '/cctv', themeMode, location),
            onClick: () => navigate('/cctv'),
            path: '/cctv',
            children: [],
        },
        {
            text: 'Device Management',
            icon: returnIcon(deviceMgmtWhite.src, deviceMgmt.src, '/devicemanagement', themeMode, location),
            onClick: () => navigate('/devicemanagement'),
            path: '/devicemanagement',
            children: [],
        },
        {
            text: 'Visitor Management',
            icon: returnIcon(
                visitorMgmtWhite.src,
                visitorMgmt.src,
                '/visitormanagement/appointments', themeMode, location
            ),
            onClick: () => navigate('/visitormanagement/appointments'),
            path: '/visitormanagement/appointments',
            children: [{
                id: "visitors",
                title: "Visitors",
                icons: [visitorsListWhite.src, visitorsList.src],
                serviceStatus: services.visitorManagement,
                maintenanceStatus: underMaintenance.visitorManagement,
                path: '/visitormanagement/visitors'
            },],
        },
        {
            text: 'Feedback',
            icon: returnIcon(
                feedbackWhite.src,
                feedback.src,
                '/feedback', themeMode, location
            ),
            onClick: () => navigate('/feedback'),
            path: '/feedback',
            children: [],
        },
        {
            text: 'PPM',
            icon: returnIcon(
                ppmWhite.src,
                ppm.src,
                '/ppm',
                themeMode,
                location
            ),
            onClick: () => navigate('/ppm'),
            path: '/ppm',
            children: [
                {
                    id: "list",
                    title: "PPM List",
                    icons: [PpmListWhite.src, PpmList.src],
                    serviceStatus: services.ppm,
                    maintenanceStatus: underMaintenance.ppm,
                    path: '/ppm/list'
                }
            ],
        },
        {
            text: 'Analytics & Reporting',
            icon: returnIcon(analyticsWhite.src, analytics.src, '', themeMode, location),
            onClick: () => { },
            children: [],
        },
        {
            text: 'Settings',
            icon: returnIcon(settingsWhite.src, settings.src, '', themeMode, location),
            onClick: () => { },
            children: [],
        },
        {
            text: 'Support',
            icon: returnIcon(supportWhite.src, support.src, '', themeMode, location),
            onClick: () => { },
            children: [],
        },
    ]
}

const drawerWidth = '14%'

export const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen + 500,
    }),
    overflowX: 'hidden',
})

export const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen + 500,
    }),
    overflowX: 'hidden',
    width: `72px`,
    [theme.breakpoints.up('sm')]: {
        width: `72px`,
    },
})

export const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))