import React from 'react';
import CustomComponentContainer from '../../../components/container/CustomComponentContainer';
import { Avatar, Box, Grid } from '@mui/material';
import CustomTypography from '../../../components/texts/CustomTypography';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { EditOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';

const View = ({ userData }) => {
    const navigate = useNavigate();
    const { fileReadUrl } = useSelector(state => state?.configurations);

    const commonStyle = {
        border: '1px solid #e0e0e0',
        borderRadius: '1rem',
        padding: '16px',
        marginBottom: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: '.75rem'
    };

    return (
        <Box sx={{ m: 4, minHeight: '81vh', width: '100%' }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
                <CustomTypography variant='h5' sx={{ mb: 2 }}>{`${userData?.userRef?.fullName?.toUpperCase()} - DETAILS`}</CustomTypography>
                <EditOutlined sx={{ mt: '4px', color: '#dc4d28', cursor: 'pointer' }} onClick={() => navigate(`/admin/usermanagement/edit/${userData?.userRef?._id}`, { state: { type: 'edit' } })} />
            </Box>
            <CustomComponentContainer sx={{ p: 2, borderRadius: '1rem !important' }}>
                <Grid container sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>

                    <Grid item xs={4} sx={commonStyle}>
                        {/* Avatar Section */}
                        <CustomTypography variant="h6" sx={{ mb: 1 }}>Profile Picture</CustomTypography>
                        <Avatar sx={{ width: 120, height: 120 }} src={`${fileReadUrl}${userData?.profilePictureUrl}`} />

                        {/* Personal Information */}
                        <CustomTypography variant="h6" sx={{ mt: 4, mb: 2 }}>Personal Information</CustomTypography>

                        <Box>
                            <CustomTypography variant="body1">Full Name:</CustomTypography>
                            <CustomTypography variant="body2" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                                {userData?.userRef?.fullName || '-'}
                            </CustomTypography>
                        </Box>

                        <Box>
                            <CustomTypography variant="body1">Employee Code:</CustomTypography>
                            <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                {userData?.userRef?.empCode || '-'}
                            </CustomTypography>
                        </Box>

                        <Box>
                            <CustomTypography variant="body1">Email:</CustomTypography>
                            <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                {userData?.userRef?.email || '-'}
                            </CustomTypography>
                        </Box>

                        <Box>
                            <CustomTypography variant="body1">Mobile:</CustomTypography>
                            <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                {userData?.mobile || '-'}
                            </CustomTypography>
                        </Box>

                        <Box>
                            <CustomTypography variant="body1">About:</CustomTypography>
                            <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                {userData?.about || '-'}
                            </CustomTypography>
                        </Box>
                    </Grid>

                    {/* Right Section: Additional Information */}
                    <Grid item xs={8} sx={{ pl: '1rem', display: 'flex', gap: '1rem' }}>
                        <Grid container>
                            <Grid item xs={12} sx={commonStyle}>
                                <CustomTypography variant="h6" sx={{ mb: 1 }}>Department Information</CustomTypography>

                                <Box>
                                    <CustomTypography variant="body1">Department:</CustomTypography>
                                    <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {userData?.department?.name || '-'}
                                    </CustomTypography>
                                </Box>

                                <Box>
                                    <CustomTypography variant="body1">Reports To:</CustomTypography>
                                    <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {userData?.reportingChain?.reportsTo?.fullName || '-'}
                                    </CustomTypography>
                                </Box>

                                <Box>
                                    <CustomTypography variant="body1">Date of Joining:</CustomTypography>
                                    <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {userData?.dateOfJoining ? moment(userData.dateOfJoining).format('DD/MM/YY') : '-'}
                                    </CustomTypography>
                                </Box>

                                <Box>
                                    <CustomTypography variant="body1">Current Building:</CustomTypography>
                                    <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {userData?.buildings?.find(b => b._id === userData.buildingId)?.name || '-'}
                                    </CustomTypography>
                                </Box>

                                <Box>
                                    <CustomTypography variant="body1">Direct Reports:</CustomTypography>
                                    <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {userData?.reportingChain?.directReportsFrom?.map(b => b.fullName).join(', ') || '-'}
                                    </CustomTypography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sx={commonStyle}>
                                <CustomTypography variant="h6" sx={{ mb: 1 }}>Role & Building Information</CustomTypography>

                                <Box>
                                    <CustomTypography variant="body1">Current Building:</CustomTypography>
                                    <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {userData?.buildings?.find(b => b._id === userData.buildingId)?.name || '-'}
                                    </CustomTypography>
                                </Box>

                                <Box>
                                    <CustomTypography variant="body1">Role:</CustomTypography>
                                    <CustomTypography variant="body2" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                                        {userData?.userRef?.role || '-'}
                                    </CustomTypography>
                                </Box>

                                <Box>
                                    <CustomTypography variant="body1">All Buildings:</CustomTypography>
                                    <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {userData?.buildings?.map(el => el.name).join(', ') || '-'}
                                    </CustomTypography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} sx={commonStyle}>
                                <CustomTypography variant="h6" sx={{ mb: 1 }}>Personal Details</CustomTypography>

                                <Box>
                                    <CustomTypography variant="body1">Blood Group:</CustomTypography>
                                    <CustomTypography variant="body2" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                                        {userData?.bloodGroup || '-'}
                                    </CustomTypography>
                                </Box>

                                <Box>
                                    <CustomTypography variant="body1">Date of Birth:</CustomTypography>
                                    <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {userData?.dateOfBirth ? moment(userData.dateOfBirth).format('DD/MM/YY') : '-'}
                                    </CustomTypography>
                                </Box>

                                <Box>
                                    <CustomTypography variant="body1">Emergency Contact:</CustomTypography>
                                    <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {userData?.emergencyContact?.name || '-'}
                                    </CustomTypography>
                                </Box>

                                <Box>
                                    <CustomTypography variant="body1">Emergency Contact Mobile:</CustomTypography>
                                    <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {userData?.emergencyContact?.mobile || '-'}
                                    </CustomTypography>
                                </Box>

                                <Box>
                                    <CustomTypography variant="body1">Emergency Contact Relation:</CustomTypography>
                                    <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {userData?.emergencyContact?.relation || '-'}
                                    </CustomTypography>
                                </Box>

                                <Box>
                                    <CustomTypography variant="body1">Address:</CustomTypography>
                                    <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {userData?.address || '-'}
                                    </CustomTypography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sx={commonStyle}>
                                <CustomTypography variant="h6" sx={{ mb: 1 }}>Workspace Information</CustomTypography>

                                <Box>
                                    <CustomTypography variant="body1">Desk Number:</CustomTypography>
                                    <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {userData?.deskNumber || '-'}
                                    </CustomTypography>
                                </Box>

                                <Box>
                                    <CustomTypography variant="body1">Parking Slot:</CustomTypography>
                                    <CustomTypography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {userData?.parkingSlotNumber || '-'}
                                    </CustomTypography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CustomComponentContainer>
        </Box>
    );
};

export default View;
