import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { DELETE, GET } from '../../helpers/http'
import { successToast } from '../../helpers/apiToast'
import { fetchPPMList, PPMListDispatchContext } from './context/PPMListContextProvider'
import Loader from '../../components/loading/Loader'
import { fetchCalendarData } from './helpers'

const PPMDelete = (props) => {
    const { deletingPPMId, handleCloseDeletePPMModal, handleCloseViewPPMModal, setCalendarEvents, selectedDateRange } = props
    const [deletingPPM, setDeletingPPM] = useState({})
    const [deletePPMOptions, setDeletePPMOptions] = useState({})
    const [selectedDeletePPMOption, setSelectedDeletePPMOption] = useState('')
    const dispatchPPMList = useContext(PPMListDispatchContext)

    useEffect(() => {
        const fetchDeletingPPM = async () => {
            try {
                // const { data = {} } = await GET(`${'http://localhost:8030/'}v1/task/${deletingPPMId}`, 'Fetch Deleting PPM')
                const { data = {} } = await GET(`${process.env.REACT_APP_URL}ppm/v1/task/${deletingPPMId}`, 'Fetch Deleting PPM')
                setDeletingPPM({ ...data })
            } catch (error) {
                console.log({ error })
            }
        }

        fetchDeletingPPM()

    }, [deletingPPMId, selectedDateRange?.end, selectedDateRange?.start, setCalendarEvents])

    useEffect(() => {
        const fetchDeletePPMOptions = async () => {
            try {
                // const { data } = await GET(`${'http://localhost:8030/'}v1/update/options?type=delete`, 'Fetch delete PPM options')
                const { data } = await GET(`${process.env.REACT_APP_URL}ppm/v1/update/options?type=delete`, 'Fetch delete PPM options')
                setDeletePPMOptions({ ...data })
            } catch (error) {
                console.log({ error })
            }
        }

        if (deletingPPM?.isRecursive) {
            fetchDeletePPMOptions()
        }

        return () => {
            setDeletePPMOptions({})
        }
    }, [deletingPPM?.isRecursive])

    const handleDeletePPM = async () => {
        try {
            // const { status } = await DELETE(`${'http://localhost:8030/'}v1/task/${deletingPPMId}${deletingPPM?.isRecursive ? `?type=${selectedDeletePPMOption}` : ''}`, 'Delete PPM')
            const { status } = await DELETE(`${process.env.REACT_APP_URL}ppm/v1/task/${deletingPPMId}${deletingPPM?.isRecursive ? `?type=${selectedDeletePPMOption}` : ''}`, 'Delete PPM')
            if (status === 200) {
                // await fetchPPMStats()
                successToast('PPM Deleted Successfully')
                handleCloseDeletePPMModal()
                fetchPPMList(dispatchPPMList)
                if (handleCloseViewPPMModal) {
                    handleCloseViewPPMModal()
                }
                if (selectedDateRange.start && selectedDateRange.end) {
                    fetchCalendarData({ startDate: selectedDateRange?.start, endDate: selectedDateRange?.end, setEvents: setCalendarEvents })
                }
            }
        } catch (error) {
            console.log({ error })
        }
    }

    return (
        <Stack spacing={2}>
            {deletingPPM?.title ? <>
                <Typography>Are you sure you want to delete this PPM
                    {/* <b>{deletingPPM?.title}</b> */}
                    ?
                </Typography>
                {deletingPPM?.isRecursive && <FormControl>
                    <FormLabel>Select an Option</FormLabel>
                    <RadioGroup
                        value={selectedDeletePPMOption}
                        onChange={(e) => setSelectedDeletePPMOption(e.target.value)}
                    >
                        {Object.keys(deletePPMOptions)?.map((option) => (
                            <FormControlLabel key={option} value={option} control={<Radio />} label={deletePPMOptions[option]} />
                        ))}
                    </RadioGroup>
                </FormControl>}
                <Stack direction="row" spacing={2} className='flexJustifyBetween'>
                    <Button className='actionButton' onClick={handleCloseDeletePPMModal}>Cancel</Button>
                    <Button className='actionButton' onClick={handleDeletePPM}>Delete</Button>
                </Stack>
            </> : <Loader />}
        </Stack>
    )
}

export default PPMDelete