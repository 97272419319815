import { Avatar, Box, Button, Divider, IconButton, Tooltip, Typography } from "@mui/material"
import CustomComponentContainer from "../../../components/container/CustomComponentContainer"
import { AddOutlined, DeleteOutline, EditOutlined, RemoveRedEyeOutlined } from "@mui/icons-material"
import Datatable from "../../../components/dataTable/Datatable"
import MUIRightDrawer from "../../faultReports/newFaultReports/components/MuiDrawer"
import useApiCalls from "../../faultReports/newFaultReports/components/hooks/useApiCalls"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import { DELETE, GET } from "../../../helpers/http"
import CostingForm from "./CostingForm"
import swal from 'sweetalert'
import { error } from "jquery"
import { errorToast, successToast } from "../../../helpers/apiToast"
import Modal from "../../../components/modals/NewModal"
import { useSelector } from "react-redux"
import Plyr from "plyr-react"
import "plyr-react/plyr.css"



const Costing = ({ task }) => {
    const { getAllCost, deleteSingleCost } = useApiCalls();
    const { id } = useParams()
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState(null);
    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState({})
    const [updateParent, setUpdateParent] = useState(false)
    const [tableData, setTableData] = useState([]);
    const [cost, setCost] = useState({})
    const [modalOpen, setModalOpen] = useState(false)

    const fileReadUrl = useSelector(state => state?.configurations?.frfileReadUrl)


    useEffect(() => {
        (async () => {
            try {
                const response = await GET(`${process.env.REACT_APP_URL}ppm/v1/cost/list/?ppmId=${id}`)
                setTableData(response?.data || [])
            } catch (error) {
                console.log(error)
            }

        })();
    }, [id, updateParent])

    const handleUpdateParent = () => {
        setUpdateParent(!updateParent)
    }

    const plyrOptions = {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'airplay', 'fullscreen'],
        hideControls: false,
        ratio: "16:9",
        settings: ['captions', 'quality', 'speed', 'loop'],
    }

    const handleCostView = (cost) => {
        console.log(cost)
        setModalOpen(true)
        setCost(cost)
    }
    const columns = [

        {
            field: 'recordedBy',
            headerName: 'Recorded By',
            flex: 1,
            renderCell: (params) => {
                return params?.row?.recordedBy?.fullName
            },
        },

        { field: 'amount', headerName: 'Amount', flex: 1 },
        {
            field: 'expenseBy',
            headerName: 'Expense By',
            renderCell: (params) => {
                return params?.row?.expenseBy?.fullName
            },
            flex: 1
        },
        { field: 'vendorName', headerName: 'Vendor Name', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <>
                    <Tooltip>
                        <IconButton disabled={params.row.isDeleted} aria-label="view"
                            onClick={() => { handleCostView(params.row) }}
                        >
                            <RemoveRedEyeOutlined sx={{ color: '#dc4d28 !important' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit" placement="top">
                        <IconButton disabled={params.row.isDeleted} aria-label="edit"
                            onClick={() => handleEditClick(params.row._id)}
                        >
                            <EditOutlined sx={{ color: '#dc4d28 !important' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                        <IconButton disabled={params.row.isDeleted} aria-label="delete"
                            onClick={() => handleDelete(params.row._id)}
                        >
                            <DeleteOutline sx={{ color: '#dc4d28 !important' }} />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
    ];

    const dataToEdit = (id) => {
        const result = tableData.find(el => el._id === id);
        setEditData(result);
    };


    const toggleDrawer = async (state) => {
        setOpen(state);
        if (!state) {
            // Reset states when closing the drawer
            setEditMode(false);
            setEditData(null);
        }
    };

    const toggleEditMode = (state) => {
        setEditMode(state);
    };

    const handleEditClick = (id) => {
        setEditMode(true);
        dataToEdit(id);
        toggleDrawer(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    }
    const handleDelete = async (costId) => {
        swal({
            title: 'Alert',
            text: 'Are you sure you want to delete',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then(async (confirm) => {
            if (confirm) {
                const response = await DELETE(`${process.env.REACT_APP_URL}ppm/v1/cost/?costId=${costId}&ppmId=${id}`)
                console.log(response.data)
                successToast('Successfully Deleted Cost')
                const finalTable = tableData.filter(ele => {
                    return ele._id !== response?.data?._id
                })
                setTableData(finalTable)

            }
        })
            .catch(error => {
                errorToast('Error While Deleting The Cost')
                console.log(error)
            })
    };

    const updateParentState = ({ data }) => {
        setTableData(prevData => {
            // If in edit mode, replace the edited item
            if (editMode && editData) {
                return prevData.map(item => item._id === data._id ? data : item);
            }
            // If in add mode, add the new item
            return [...prevData, data];
        });
        setUpdateParent(!updateParent)
    };

    return (
        <>
            <CustomComponentContainer className='commonComponentSpacing' sx={{ position: 'relative' }}>
                <div style={{ height: '75vh', width: '100%' }}>
                    <Tooltip title="Add Costing" placement="left">
                        <Button
                            sx={{
                                backgroundColor: '#dc4d28',
                                color: 'white',
                                position: 'absolute',
                                zIndex: 2,
                                right: '1rem',
                                top: '1rem',
                                '&:hover': { backgroundColor: '#c43e2d' },
                            }}
                            onClick={() => toggleDrawer(true)}
                        >
                            <AddOutlined />
                        </Button>
                    </Tooltip>
                    <Datatable disableGridToolBar columns={columns} rows={tableData} />
                </div>
            </CustomComponentContainer>
            <MUIRightDrawer open={open}
                toggleDrawer={toggleDrawer}
                title={`${editMode ? 'Update' : 'Add'} Costing`}>
                <CostingForm
                    data={editData}
                    editMode={editMode}
                    handleUpdateParent={handleUpdateParent}
                    toggleEditMode={toggleEditMode}
                    updateParentState={updateParentState}
                    task={task}
                    category={category}
                    toggleDrawer={toggleDrawer} />
            </MUIRightDrawer>
            <Modal
                modalOpen={modalOpen}
                closeModal={handleClose}
                height="62vh"
                modalWidth="40vw"
                children={
                    <Box
                        padding={2}
                    >
                        <Typography variant="h6" gutterBottom>
                            Cost Details
                        </Typography>
                        {/* Amount */}
                        <Box marginTop={2}>
                            <Typography variant="body1"><strong>Total Amount:</strong>{cost?.amount}</Typography>
                        </Box>

                        {/* Invoice Number */}
                        <Box marginTop={2}>
                            <Typography variant="body1"><strong>Invoice Number:</strong> {cost?.invoiceNumber}</Typography>
                        </Box>

                        {/* Vendor Name */}
                        {cost?.vendorName &&
                            <Box marginTop={2}>
                                <Typography variant="body1"><strong>Vendor Name:</strong> {cost?.vendorName}</Typography>
                            </Box>
                        }


                        {/* Expense By */}
                        {
                            cost?.expenseBy?.fullName && <Box display="flex" alignItems="center" marginTop={2}>
                                <Typography variant="body1"><strong>Expense By:</strong>{cost?.expenseBy?.fullName}</Typography>
                            </Box>
                        }


                        {/* Expense By User Type */}
                        {
                            cost?.recordedBy?.fullName && <Box marginTop={2}>
                                <Typography variant="body1"><strong>Recorded By:</strong> {cost?.recordedBy?.fullName}</Typography>
                            </Box>
                        }
                        {/* Media */}
                        <Box display="flex" alignItems="center" marginTop={2} marginBottom={2}>
                            <Typography variant="body1"><strong>Documents:</strong></Typography>
                        </Box>

                        {cost.media && cost.media.length > 0 && (
                            cost.media.map(ele => {
                                return <>
                                    {ele?.type?.includes("image") ? (
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            width: '100%',
                                            height: '50%',
                                            overflow: 'hidden',
                                            marginBottom: 2

                                        }}
                                        >
                                            <img
                                                src={`${fileReadUrl}${ele?.key}`}
                                                style={{
                                                    cursor: 'pointer',
                                                    objectFit: 'contain',
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                                alt={ele?.key}
                                            />
                                        </Box>
                                    ) : ele?.type?.includes("pdf") ? (
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            height: "10rem",
                                            overflow: 'hidden',
                                            cursor: 'pointer',
                                            borderRadius: '1rem',
                                            objectFit: "fill"
                                        }}>
                                            <iframe
                                                title="pdf"
                                                src={`${fileReadUrl}${ele?.key}`}
                                                type="application/pdf"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    pointerEvents: 'none',
                                                }}
                                            />
                                        </Box>
                                    ) : ele?.type?.includes("video") ? (
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            borderRadius: '1rem',
                                            height: "10rem",
                                            overflow: 'hidden',
                                        }}>
                                            <Plyr
                                                source={{ type: "video", sources: [{ src: `${fileReadUrl}${ele?.key}` }] }}
                                                options={plyrOptions}
                                            />
                                        </Box>
                                    ) : (
                                        <Box>
                                            <p>No Media</p>
                                        </Box>
                                    )}

                                </>
                            })

                        )}
                    </Box>
                }
            />
        </>
    )
}

export default Costing