import { lazy } from 'react'
import DigitalTwin from '../../../pages/secondary/twin/digital/DigitalTwin'
import MatterportTwin from '../../../pages/secondary/twin/matterport/matterportTwin'
import PDFReportGeneratorFR from '../../../features/components/PDFReportGeneration/AM/PDFReportGeneratorAM'
import NewFaultReports from '../../../pages/secondary/faultReports/NewFaultReports'
import AdminDataGrid from '../../../features/chat/components/AdminStatsView'
import PPMStatisticsPage from '../../../pages/secondary/plannedPreventativeMaintenance/PPMStatistics'
import PPMTaskList from '../../../features/plannedPreventativeMaintenance/PpmTaskList'
import PPMTaskView from '../../../features/plannedPreventativeMaintenance/TaskView/TaskViewContainer'

const MainContainerRenderer = lazy(() => import('../components/MainContainerRenderer'))

const EnergyConsumption = lazy(() => import('../../../pages/secondary/energy/EnergyConsumption'))
const Alerts = lazy(() => import('../../../pages/secondary/alerts/Alerts'))
const TotalOccupancy = lazy(() => import('../../../pages/secondary/occupancy/TotalOccupancy'))
const BookedDesks = lazy(() => import('../../../pages/secondary/desks/BookedDesks'))
const MeetingRooms = lazy(() => import('../../../pages/secondary/meetingRooms/MeetingRooms'))
const MeetingRoomTertiary = lazy(() => import('../../../pages/tertiary/meetingRooms/MeetingRoomTertiary'))
const MeetingRoomStatsCharts = lazy(() => import('../../../pages/tertiary/meetingRooms/MeetingRoomStatsAndCharts'))
const AQI = lazy(() => import('../../../pages/secondary/AQI/AQI'))
const AQItertiary = lazy(() => import('../../../pages/tertiary/AQI/AQItertiary'))
const Washroom = lazy(() => import('../../../pages/secondary/restRooms/Washroom'))
const WashroomTertiary = lazy(() => import('../../../pages/tertiary/restrooms/WashroomTertiary'))

const FaultReports = lazy(() => import('../../../pages/secondary/faultReports/FaultReports'));
const FRTicketListing = lazy(() => import('../../../features/faultReports/newFaultReports/secondary/FaultReportings'));
const FRInfo = lazy(() => import('../../../features/faultReports/newFaultReports/components/FRInfoContainer'));
const JobsCalender = lazy(() => import('../../../features/faultReports/newFaultReports/secondary/JobsCalender'));
const MyJobs = lazy(() => import('../../../features/faultReports/newFaultReports/secondary/MyJobs'));

const Feedback = lazy(() => import('../../../pages/secondary/feedback/Feedback'))
const WaterConsumption = lazy(() => import('../../../pages/secondary/waterConsumption/WaterConsumption'))

const Documentation = lazy(() => import('../../../pages/secondary/documentsHub/DigitalDocumentation'))
const DigitalDocInfo = lazy(() => import('../../../pages/tertiary/documentsHub/DigitalDocInfo'))

const AssetsDashboard = lazy(() => import('../../../pages/secondary/assetManagement/dashboard/assetManagement'))
const AssetsList = lazy(() => import('../../../pages/secondary/assetManagement/list/List'))
const AddForm = lazy(() => import('../../../pages/secondary/assetManagement/addForm/AddForm'))
const AddExcel = lazy(() => import('../../../pages/secondary/assetManagement/addExcel/AddExcel'))
const AssetView = lazy(() => import('../../../pages/secondary/assetManagement/assetView/AssetView'))
const AssetEdit = lazy(() => import('../../../pages/secondary/assetManagement/assetEdit/AssetEdit'))
const AssetInsurance = lazy(() => import('../../../pages/secondary/assetManagement/assetInsurance/AssetInsurance'))

const MaintenanceOrExpiry = lazy(() => import('../../../pages/secondary/assetManagement/maintainanceOrExpiry/MaintenanceOrExpiry'))

const TVDashboard = lazy(() => import('../../../pages/secondary/CCTV/TVDashboard'))
const DeviceManagement = lazy(() => import('../../../pages/secondary/deviceManagement/DeviceStatus'))

const AppointmentsPage = lazy(() => import('../../../pages/secondary/visitorManagement/appointments/AppointmentsPage'))
// const KioskConfigPage = lazy(() => import('../../../pages/secondary/visitorManagement/kioskConfig/KioskConfigPage'))
// const KioskSessionsPage = lazy(() => import('../../../pages/tertiary/visitorManagement/kioskSessions/KioskSessionsPage'))
const VisitorProfilePage = lazy(() => import('../../../pages/tertiary/visitorManagement/visitorProfile/VisitorProfilePage'))
const VisitorsPage = lazy(() => import('../../../pages/secondary/visitorManagement/visitors/VisitorsPage'))

const TabConfigPage = lazy(() => import('../../../pages/secondary/tabConfiguration/TabConfigPage'))

const PPMPage = lazy(() => import('../../../pages/secondary/plannedPreventativeMaintenance/PPM'))

const Weather = lazy(() => import('../../../pages/secondary/weather/WeatherPage'))

// const NotFound = lazy(() => import('../../../components/404Error/404Error'))

const ChatBot = lazy(() => import('../../../features/chat/context/ChatContext'));
// const ChatAdminView = lazy(() => import('../../../features/chat/components/AdminStatsView'));

export const componentRoutes = [
    { path: "/", component: MainContainerRenderer },
    { path: "/dashboard/energyconsumption", component: EnergyConsumption },
    { path: "/dashboard/alerts", component: Alerts },
    { path: "/dashboard/occupancy", component: TotalOccupancy },
    { path: "/dashboard/desks", component: BookedDesks },
    { path: "/dashboard/waterconsumption", component: WaterConsumption },
    { path: "/dashboard/meetingrooms", component: MeetingRooms },
    { path: "/dashboard/meetingrooms/:id", component: MeetingRoomStatsCharts },
    { path: "/dashboard/meetingrooms/aqi/:id", component: MeetingRoomTertiary },
    { path: "/dashboard/aqi", component: AQI },
    { path: "/dashboard/aqi/:id", component: AQItertiary },
    { path: "/dashboard/washroom", component: Washroom },
    { path: "/dashboard/washroom/:id", component: WashroomTertiary },
    { path: "/faultreports", component: NewFaultReports },
    // { path: "/faultreports", component: FaultReports },
    // new FR changes starts
    { path: "/faultreports/tickets", component: FRTicketListing },
    { path: "/faultreports/myjobs", component: MyJobs },
    { path: "/faultreports/calender", component: JobsCalender },
    { path: "/faultreports/tickets/:id", component: FRInfo },
    // new FR changes ends
    { path: '/feedback', component: Feedback },
    { path: "/digitaltwin", component: DigitalTwin },
    { path: "/digitaltwin/matterport", component: MatterportTwin },
    { path: "/digitaldocumentation", component: Documentation },
    { path: "/digitaldocumentation/documentinfo/:docId", component: DigitalDocInfo },
    { path: "/assetmanagement", component: AssetsDashboard },
    { path: "/assetmanagement/assetlist", component: AssetsList },
    { path: "/assetmanagement/addassetform", component: AddForm },
    { path: "/assetmanagement/insurance", component: AssetInsurance },
    { path: "/assetmanagement/addassetxl", component: AddExcel },
    { path: "/assetmanagement/assetview/:id", component: AssetView },
    { path: "/assetmanagement/assetedit/:id", component: AssetEdit },
    { path: "/assetmanagement/secondary/:listType", component: MaintenanceOrExpiry },
    { path: "/cctv", component: TVDashboard },
    { path: "/devicemanagement", component: DeviceManagement },
    // { path: "/visitormanagement/kiosks", component: KioskConfigPage },
    // { path: "/visitormanagement/kiosk/:id", component: KioskSessionsPage },
    { path: "/visitormanagement/visitor/:id", component: VisitorProfilePage },
    { path: "/visitormanagement/appointments", component: AppointmentsPage },
    { path: "/visitormanagement/visitors", component: VisitorsPage },
    { path: "/meetingroom/configurations", component: TabConfigPage },
    { path: "/weather/:date", component: Weather },
    { path: "/pdfgenerator", component: PDFReportGeneratorFR },
    { path: '/maverick', component: ChatBot },
    { path: '/chat/data', component: AdminDataGrid },
    { path: '/ppm/list', component: PPMPage },
    { path: "/ppm", component: PPMStatisticsPage },
    { path: "/ppm/view/:id", component: PPMTaskList },
    // { path: "/ppm/:id/task/:id", component: PPMTaskView },
    // { path: '/ppm', component: PPMPage },
    { path: "/ppm/task/:id", component: PPMTaskView },
    // { path: '/chat', component: ChatBot },
    // { path: '*', component: NotFound },
]