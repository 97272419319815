import { Grid } from '@mui/material'
import React from 'react'
import FormInput from '../../components/formInputs/FormInput'
import moment from 'moment'
import CheckBox from '../../components/formInputs/CheckBox'
import CreateRecursivePPMInputs from './CreateRecursivePPMInputs'

const DateTimeAndRecursiveInputs = (props) => {
    const { PPMData, handleFormInputsChange, validationErrors, isPPMrecursive, handleIsRecursiveChange, PPMOptionsData, assetId, selectedUpdatePPMOption } = props

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormInput
                    label='Starts On'
                    size='small'
                    type='date'
                    focused
                    fullWidth
                    name='date'
                    value={PPMData?.date}
                    onChange={handleFormInputsChange}
                    error={!!validationErrors?.date}
                    helperText={validationErrors?.date}
                    inputProps={{
                        min: moment().format('YYYY-MM-DD'),
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInput
                    label='Start Time'
                    size='small'
                    type='time'
                    focused
                    fullWidth
                    name='startTime'
                    value={PPMData?.startTime}
                    onChange={handleFormInputsChange}
                    error={!!validationErrors?.startTime}
                    helperText={validationErrors?.startTime}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInput
                    label='End Time'
                    size='small'
                    type='time'
                    focused
                    fullWidth
                    name='endTime'
                    value={PPMData?.endTime}
                    onChange={handleFormInputsChange}
                    error={!!validationErrors?.endTime}
                    helperText={validationErrors?.endTime}
                />
            </Grid>
            <Grid item xs={12}>
                <CheckBox
                    checkboxLabel='Repeat'
                    checked={isPPMrecursive}
                    onChange={handleIsRecursiveChange}
                    width='5rem'
                    disabled={assetId}
                />
            </Grid>
            {isPPMrecursive && <CreateRecursivePPMInputs
                PPMData={PPMData}
                handleFormInputsChange={handleFormInputsChange}
                PPMOptionsData={PPMOptionsData}
                validationErrors={validationErrors}
                assetId={assetId}
                selectedUpdatePPMOption={selectedUpdatePPMOption}
            />}
        </Grid>
    )
}

export default DateTimeAndRecursiveInputs