import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from '../../api/apiSlice'
import { errorHandler } from '../../../components/facilityManager/helpers/http';

const initialState = {
    dashboard: {
        faults: [],
        isLoaded: false,
        total: 0,
        open: 0,
        inProgress: 0,
        closed: 0
    }
}

const fetchFaultReportSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getFaultReports: builder.query({
            query: ({ buildingId }) => ({
                url: `fr/v1/faultreports?buildingId=${buildingId}`,
                providesTags: ['faultReports'],
                method: 'GET',
                credentials: "include",
            }),
            transformResponse: (responseData, meta, arg) => {
                let open = 0, closed = 0, inProgress = 0;
                responseData?.faults?.forEach(ele => {
                    if (ele?.status === 'open') open++;
                    if (ele?.status === 'assigned') inProgress++;
                    if (ele?.status === 'closed') closed++;
                });
                return { faults: responseData.faults, isLoaded: true, total: responseData.total, open, closed, inProgress }
            },
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Fetch Fault Reports Dashboard')
            },
        })
    })
})

export const { useGetFaultReportsQuery } = fetchFaultReportSlice

const frSlice = createSlice({
    name: 'frSlice',
    initialState,
    reducers: {
        resetDashboardFaults: (state, action) => {
            state.dashboard = { ...initialState.dashboard }
        },
        addNewFaultReport: ((state, action) => {
            state.dashboard.faults.unshift(action.payload);
            state.dashboard.total += 1
            state.dashboard.open += 1
        }),
        updateExistingFaultReportStatusToOpen: ((state, action) => {
            state.dashboard.faults.map((fault) => {
                return fault.id === action.payload?.id ? { ...fault, ...action.payload } : fault
            })
            state.dashboard.open -= 1
            state.dashboard.inProgress += 1
        }),

        updateExistingFaultReportStatusToClosed: ((state, action) => {
            state.dashboard.faults.map((fault) => {
                return fault.id === action.payload?.id ? { ...fault, ...action.payload } : fault
            })
            state.dashboard.inProgress -= 1
            state.dashboard.closed += 1
        }),
        // updateFaultReport:(state,action)=>{
        //     const updatedFaults=state.dashboard?.faults?.map((fault)=>{
        //         if(fault.id===action.payload?.id){
        //             return action.payload
        //         }
        //         else {
        //             return {...fault}
        //         }
        //     })
        // },
        updateExistingFaultReportStatusToReOpened: ((state, action) => {
            state.dashboard.faults.map((fault) => {
                return fault.id === action.payload?.id ? { ...fault, ...action.payload } : fault
            })
            state.dashboard.inProgress -= 1
            state.dashboard.open += 1
        })

    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'getFaultReports' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                state.dashboard = action.payload
            }
        )
    }
})

export const {
    resetDashboardFaults,
    addNewFaultReport,
    updateExistingFaultReportStatusToOpen,
    updateExistingFaultReportStatusToClosed,
    updateExistingFaultReportStatusToReOpened
} = frSlice.actions

export default frSlice.reducer


