import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GET } from '../../components/facilityManager/helpers/http'

const initialState = {
    services: {
        video: true,
        sidePanel: true,
    },
    underMaintenance: {},
    isLoaded: false,
    error: null
}

export const fetchTwinConfigurations = createAsyncThunk(
    'configurations/fetchTwinConfigurations',
    async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_URL}twin/v1/config`, 'Twin Configurations')
            return response.data
        }
        catch (error) {
            console.error(error)
            throw error
        }
    }
)

export const fetchAmConfigurations = createAsyncThunk(
    'configurations/fetchAmConfigurations',
    async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_URL}am/v1/config`, 'Asset Management Configurations')
            return response.data
        }
        catch (error) {
            console.error(error)
            throw error
        }
    }
)

export const fetchDbConfigurations = createAsyncThunk(
    'configurations/fetchDbConfigurations',
    async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_URL}db/v1/config`, 'Desk Booking Configurations')
            return response.data
        }
        catch (error) {
            console.error(error)
            throw error
        }
    }
)

export const fetchFrConfigurations = createAsyncThunk(
    'configurations/fetchFrConfigurations',
    async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_URL}fr/v1/config`, 'Fault Reports Configurations')
            return response.data
        }
        catch (error) {
            console.error(error)
            throw error
        }
    }
)

export const fetchFrV2Configurations = createAsyncThunk(
    'configurations/fetchFrV2Configurations',
    async () => {
        try {
            const response = await GET('https://dev-apps.letsnhance.com/fr/v2/config', 'Fault Reports Configurations')
            // const response = await GET(`${process.env.REACT_APP_BASE_URL}v2/config`, 'Fault Reports Configurations')
            return response.data;
        }
        catch (error) {
            console.error(error)
            throw error
        }
    }
)

export const fetchMrConfigurations = createAsyncThunk(
    'configurations/fetchMrConfigurations',
    async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_URL}mr/v1/config`, 'Calendar Meeting Room Configurations')
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }
)

export const fetchVmConfigurations = createAsyncThunk(
    'configurations/fetchVmConfigurations',
    async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_URL}vm/v1/config`, 'Calendar Meeting Room Configurations')
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }
)

export const fetchFbConfigurations = createAsyncThunk(
    'configurations/fetchFbConfigurations',
    async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_URL}fb/v1/config`, 'Feedback Configurations')
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }
)

export const fetchDocConfigurations = createAsyncThunk(
    'configurations/fetchDocConfigurations',
    async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_URL}doc/v1/config`, 'Digital Documentation Configurations')
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }
)

export const fetchWallConfigurations = createAsyncThunk(
    "configurations/fetchWallConfigurations",
    async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_URL}wall/v1/config`, 'Wall Configurations')
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }
)

export const fetchCommunitiesConfigurations = createAsyncThunk(
    "configurations/fetchCommunitiesConfigurations",
    async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_URL}comms/v1/config`, 'Communities Configurations')
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }
)

export const fetchPPMConfigurations = createAsyncThunk(
    "configurations/fetchPPMConfigurations",
    async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_URL}ppm/v1/config`, 'PPM Configurations')
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }
)

export const fetchAttendanceConfigurations = createAsyncThunk(
    "configurations/fetchAttendanceConfigurations",
    async () => {
        try {
            const response = await GET(`${process.env.REACT_APP_URL}at/v1/config`, 'Attendance Configurations')
            return response.data
        } catch (error) {
            console.error(error)
            throw error
        }
    }
)

const configurationsSlice = createSlice({
    name: 'configurations',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchTwinConfigurations.fulfilled, (state, action) => {
                state.isLoaded = true
                state.companyName = action.payload?.dashboard?.companyName
                state.logo = action.payload?.dashboard?.logo
                state.video = action.payload?.dashboard?.twinVideoUrl
                state.videoAlt = action.payload?.dashboard?.videoAlt
                state.fileReadUrl = action.payload?.fileReadUrl
                state.socket = action.payload?.dashboard?.socket
                state.services.alerts = action.payload?.alerts?.isServiceEnabled
                state.services.aqi = action.payload?.aqi?.isServiceEnabled
                state.services.aqiFloorView = action.payload?.aqi?.isFloorViewEnabled
                state.services.deskOccupancy = action.payload?.deskOccupancy?.isServiceEnabled
                state.services.deskOccupancyFloorView = action.payload?.deskOccupancy?.isFloorViewEnabled
                state.services.deskOccupancyHeatmap = action.payload?.deskOccupancy?.isHeatMapEnabled
                state.services.deviceHealth = action.payload?.deviceHealth?.isServiceEnabled
                state.services.deviceManagement = action.payload?.deviceManagement?.isServiceEnabled
                state.services.energy = action.payload?.energy?.isServiceEnabled
                state.services.forge = action.payload?.forge?.isServiceEnabled
                state.services.matterport = action.payload?.matterport?.isServiceEnabled
                state.services.liveCamera = action.payload?.liveCamera?.isServiceEnabled
                state.services.occupancy = action.payload?.occupancy?.isServiceEnabled
                state.services.restRooms = action.payload?.restRooms?.isServiceEnabled
                state.services.hvac = action.payload?.hvac?.isServiceEnabled
                state.services.water = action.payload?.water?.isServiceEnabled
                state.services.meetingRoomsTwin = action.payload?.meetingRooms?.isServiceEnabled
                state.services.meetingRoomFloorView = action.payload?.meetingRooms?.isFloorViewEnabled
                state.services.meetingRoomHeatmap = action.payload?.meetingRooms?.isHeatMapEnabled
                state.services.meetingRoomsV1 = action.payload?.meetingRooms?.isServiceEnabled  //Only for fidelity
                state.underMaintenance.alerts = action.payload?.alerts?.isUnderMaintenance
                state.underMaintenance.aqi = action.payload?.aqi?.isUnderMaintenance
                state.underMaintenance.deskOccupancy = action.payload?.deskOccupancy?.isUnderMaintenance
                state.underMaintenance.deviceManagement = action.payload?.deviceManagement?.isUnderMaintenance
                state.underMaintenance.deviceHealth = action.payload?.deviceHealth?.isUnderMaintenance
                state.underMaintenance.energy = action.payload?.energy?.isUnderMaintenance
                state.underMaintenance.forge = action.payload?.forge?.isUnderMaintenance
                state.underMaintenance.matterport = action.payload?.matterport?.isUnderMaintenance
                state.underMaintenance.liveCamera = action.payload?.liveCamera?.isUnderMaintenance
                state.underMaintenance.occupancy = action.payload?.occupancy?.isUnderMaintenance
                state.underMaintenance.restRooms = action.payload?.restRooms?.isUnderMaintenance
                state.underMaintenance.hvac = action.payload?.hvac?.isUnderMaintenance
                state.underMaintenance.water = action.payload?.water?.isUnderMaintenance
                state.underMaintenance.meetingRoomsTwin = action.payload?.meetingRooms?.isUnderMaintenance
                state.underMaintenance.meetingRoomsV1 = action.payload?.meetingRooms?.isUnderMaintenance  //Only for fidelity
            })
            .addCase(fetchAmConfigurations.fulfilled, (state, action) => {
                state.isLoaded = true
                state.services.assetManagement = action.payload?.isServiceEnabled
                state.underMaintenance.assetManagement = action.payload?.isUnderMaintenance
            })
            .addCase(fetchDbConfigurations.fulfilled, (state, action) => {
                state.isLoaded = true
                state.services.deskBooking = action.payload?.isServiceEnabled
                state.underMaintenance.deskBooking = action.payload?.isUnderMaintenance
            })
            .addCase(fetchFrConfigurations.fulfilled, (state, action) => {
                state.isLoaded = true
                state.services.faultReports = action.payload?.isServiceEnabled
                state.underMaintenance.faultReports = action.payload?.isUnderMaintenance
            })
            .addCase(fetchFrV2Configurations.fulfilled, (state, action) => {
                state.isLoaded = true
                state.services.faultReports2 = action.payload?.isServiceEnabled
                state.underMaintenance.faultReports2 = action.payload?.isUnderMaintenance
                state.frfileReadUrl = action?.payload?.fileReadUrl
            })
            .addCase(fetchMrConfigurations.fulfilled, (state, action) => {
                state.meetingRoomsFileReadUrl = action.payload?.fileReadUrl
                state.services.meetingRoomsCalendar = action.payload?.isServiceEnabled
                state.services.meetingRoomsTab = action.payload?.isTabServiceEnabled
                state.underMaintenance.meetingRoomsCalendar = action.payload?.isUnderMaintenance
                state.underMaintenance.meetingRoomsTab = action.payload?.isUnderMaintenance
            })
            .addCase(fetchFbConfigurations.fulfilled, (state, action) => {
                state.isLoaded = true
                state.services.feedback = action.payload?.isServiceEnabled
                state.underMaintenance.feedback = action.payload?.isUnderMaintenance
            })
            .addCase(fetchDocConfigurations.fulfilled, (state, action) => {
                state.isLoaded = true
                state.services.digitalDocumentation = action.payload?.isServiceEnabled
                state.underMaintenance.digitalDocumentation = action.payload?.isUnderMaintenance
            })
            .addCase(fetchVmConfigurations.fulfilled, (state, action) => {
                state.isLoaded = true
                state.services.visitorManagement = action.payload?.isServiceEnabled
                state.underMaintenance.visitorManagement = action.payload?.isUnderMaintenance
            })
            .addCase(fetchWallConfigurations.fulfilled, (state, action) => {
                state.isLoaded = true
                state.services.wall = action.payload?.isServiceEnabled
                state.underMaintenance.wall = action.payload?.isUnderMaintenance
                state.wallFileReadUrl = action.payload?.fileReadUrl
            })
            .addCase(fetchCommunitiesConfigurations.fulfilled, (state, action) => {
                state.isLoaded = true
                state.services.communities = action.payload?.isServiceEnabled
                state.underMaintenance.communities = action.payload?.isUnderMaintenance
                state.communitiesFileReadUrl = action.payload?.fileReadUrl
            })
            .addCase(fetchPPMConfigurations.fulfilled, (state, action) => {
                state.isLoaded = true
                state.services.ppm = action.payload?.isServiceEnabled
                state.underMaintenance.ppm = action.payload?.isUnderMaintenance
            })
            .addCase(fetchAttendanceConfigurations.fulfilled, (state, action) => {
                state.isLoaded = true
                state.services.attendance = action.payload?.isServiceEnabled
                state.underMaintenance.attendance = action.payload?.isUnderMaintenance
            })
            .addCase(fetchTwinConfigurations.rejected, (state, action) => {
                if (action?.error?.message?.includes('404') || action?.error?.message?.includes('429')) {
                    state.isLoaded = true
                    state.services.alerts = action.payload?.alerts?.isServiceEnabled || 'NA'
                    state.services.aqi = action.payload?.aqi?.isServiceEnabled || 'NA'
                    state.services.aqiFloorView = action.payload?.aqi?.isFloorViewEnabled || 'NA'
                    state.services.deskOccupancy = action.payload?.deskOccupancy?.isServiceEnabled || 'NA'
                    state.services.deskOccupancyFloorView = action.payload?.deskOccupancy?.isFloorViewEnabled || 'NA'
                    state.services.deskOccupancyHeatmap = action.payload?.deskOccupancy?.isHeatmapEnabled || 'NA'
                    state.services.deviceHealth = action.payload?.deviceHealth?.isServiceEnabled || 'NA'
                    state.services.deviceManagement = action.payload?.deviceManagement?.isServiceEnabled || 'NA'
                    state.services.energy = action.payload?.energy?.isServiceEnabled || 'NA'
                    state.services.forge = action.payload?.forge?.isServiceEnabled || 'NA'
                    state.services.matterport = action.payload?.matterport?.isServiceEnabled || 'NA'
                    state.services.liveCamera = action.payload?.liveCamera?.isServiceEnabled || 'NA'
                    state.services.occupancy = action.payload?.occupancy?.isServiceEnabled || 'NA'
                    state.services.restRooms = action.payload?.restRooms?.isServiceEnabled || 'NA'
                    state.services.hvac = action.payload?.hvac?.isServiceEnabled || 'NA'
                    state.services.water = action.payload?.water?.isServiceEnabled || 'NA'
                    state.services.meetingRoomsTwin = action.payload?.meetingRooms?.isServiceEnabled || 'NA'
                    state.services.meetingRoomHeatmap = action.payload?.meetingRooms?.isHeatMapEnabled || 'NA'
                    state.services.meetingRoomsV1 = action.payload?.meetingRooms?.isServiceEnabled || 'NA'
                    state.services.meetingRoomFloorView = action.payload?.meetingRooms?.isFloorViewEnabled || 'NA'
                }
            })
            .addCase(fetchAmConfigurations.rejected, (state, action) => {
                if (action?.error?.message?.includes('404') || action?.error?.message?.includes('429')) {
                    state.isLoaded = true
                    state.services.assetManagement = action.payload?.isServiceEnabled || 'NA'
                }
            })
            .addCase(fetchDbConfigurations.rejected, (state, action) => {
                if (action?.error?.message?.includes('404') || action?.error?.message?.includes('429')) {
                    state.isLoaded = true
                    state.services.deskBooking = action.payload?.isServiceEnabled || 'NA'
                }
            })
            .addCase(fetchFrConfigurations.rejected, (state, action) => {
                if (action?.error?.message?.includes('404') || action?.error?.message?.includes('429')) {
                    state.isLoaded = true
                    state.services.faultReports = action.payload?.isServiceEnabled || 'NA'
                }
            })
            .addCase(fetchFrV2Configurations.rejected, (state, action) => {
                if (action?.error?.message?.includes('404') || action?.error?.message?.includes('429')) {
                    state.isLoaded = true
                    state.services.faultReports = action.payload?.isServiceEnabled || 'NA'
                }
            })
            .addCase(fetchMrConfigurations.rejected, (state, action) => {
                if (action?.error?.message?.includes('404') || action?.error?.message?.includes('429')) {
                    state.services.meetingRoomsCalendar = action.payload?.isServiceEnabled || 'NA'
                    state.services.meetingRoomsTab = action.payload?.isTabServiceEnabled || 'NA'
                }
            })
            .addCase(fetchVmConfigurations.rejected, (state, action) => {
                if (action?.error?.message?.includes('404') || action?.error?.message?.includes('429')) {
                    state.services.visitorManagement = action.payload?.isServiceEnabled || 'NA'
                }
            })
            .addCase(fetchFbConfigurations.rejected, (state, action) => {
                if (action?.error?.message?.includes('404') || action?.error?.message?.includes('429')) {
                    state.isLoaded = true
                    state.services.feedback = action.payload?.isServiceEnabled || 'NA'
                }
            })
            .addCase(fetchDocConfigurations.rejected, (state, action) => {
                if (action?.error?.message?.includes('404') || action?.error?.message?.includes('429')) {
                    state.isLoaded = true
                    state.services.digitalDocumentation = action.payload?.isServiceEnabled || 'NA'
                }
                else if (action?.error?.message?.includes('503')) {
                    state.isLoaded = true
                    state.services.digitalDocumentation = action.payload?.isServiceEnabled || 'NA'
                    state.underMaintenance.digitalDocumentation = true
                }
            })
            .addCase(fetchWallConfigurations.rejected, (state, action) => {
                if (action?.error?.message?.includes('503') || action?.error?.message?.includes('401')) {
                    state.isLoaded = true
                    state.services.wall = action.payload?.isServiceEnabled || false
                    state.underMaintenance.wall = action.payload?.isUnderMaintenance || false
                    // state.fileReadUrl = action.payload?.fileReadUrl || false
                }
            })
            .addCase(fetchCommunitiesConfigurations.rejected, (state, action) => {
                if (action?.error?.message?.includes('503') || action?.error?.message?.includes('401')) {
                    state.isLoaded = true
                    state.services.communities = action.payload?.isServiceEnabled || false
                    state.underMaintenance.communities = action.payload?.isUnderMaintenance || false
                    // state.fileReadUrl = action.payload?.fileReadUrl || false
                }
            })
            .addCase(fetchPPMConfigurations.rejected, (state, action) => {
                if (action?.error?.message?.includes('404') || action?.error?.message?.includes('429')) {
                    state.isLoaded = true
                    state.services.ppm = action.payload?.isServiceEnabled || 'NA'
                }
            })
            .addCase(fetchAttendanceConfigurations.rejected, (state, action) => {
                if (action?.error?.message?.includes('404') || action?.error?.message?.includes('429')) {
                    state.isLoaded = true
                    state.services.attendance = action.payload?.isServiceEnabled || 'NA'
                }
            })
    }
})

export default configurationsSlice.reducer