import { Box } from "@mui/material"
import Secondary from "../../../components/secondary/Secondary"
import PPMStatistics from "../../../features/plannedPreventativeMaintenance/secondary/PPMStatistics"


const PPMStatisticsPage = (props) => {



    return (
        <Box width={"100%"}>
            <Secondary
                title={"Statistical Overview of Today"}
                returnPage={"Dashboard"}
                returnRoute={"/"}
                children={
                    <PPMStatistics />
                }
            />
        </Box>
    )
}

export default PPMStatisticsPage