import { Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled, Tooltip } from "@mui/material";
import { useState } from "react";
import WatchLaterTwoToneIcon from '@mui/icons-material/WatchLaterTwoTone';
import RotateLeftTwoToneIcon from '@mui/icons-material/RotateLeftTwoTone';
import ThumbUpAltTwoToneIcon from '@mui/icons-material/ThumbUpAltTwoTone';
import ThumbDownAltTwoToneIcon from '@mui/icons-material/ThumbDownAltTwoTone';
import ThumbsUpDownTwoToneIcon from '@mui/icons-material/ThumbsUpDownTwoTone';
import { ThemeColors } from "../../../../hooks/ThemeColors";

const steps = ['pending', 'inprogress', 'completed']

const TaskProgress = ({ status, capitalizeFirst }) => {
    const { textColor, bgColor } = ThemeColors()
    const [activeStatus] = useState(status ? status === 'incomplete' ? 2 : steps?.indexOf(status) : 0)

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor: '#eaeaf0',
            borderRadius: 1,
            ...theme.applyStyles('dark', {
                backgroundColor: theme.palette.grey[800],
            }),
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.grey[700],
        }),
        variants: [
            {
                props: ({ ownerState }) => ownerState.active,
                style: {
                    backgroundImage:
                        'linear-gradient( 0deg, #ef5350 100%, #ef5350 100%, #ef5350 100%)',
                    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
                },
            },
            {
                props: ({ ownerState }) => ownerState.completed,
                style: {
                    backgroundImage:
                        'linear-gradient( 0deg, #ef5350 100%, #ef5350 100%, #ef5350 100%)',
                },
            },
        ],
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: <Tooltip title='Pending' placement='top'><WatchLaterTwoToneIcon /></Tooltip>,
            2: <Tooltip title='Inprogress' placement='top'><RotateLeftTwoToneIcon /></Tooltip>,
            3: status === 'completed' ?
                <ThumbUpAltTwoToneIcon />
                : status === 'incomplete'
                    ? <ThumbDownAltTwoToneIcon /> : <ThumbsUpDownTwoToneIcon />,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    return (
        <Stepper sx={{
            width: "30vw",
            paddingTop: "2.5rem",
            paddingBottom: "1rem",
            border: "2px gainsboro solid",
            borderRadius: "1rem"
        }} alternativeLabel activeStep={activeStatus} connector={<ColorlibConnector />}>
            {steps?.map((label) => {
                const info = (status === 'incomplete' && label === 'completed') ? 'incomplete' : label
                return (
                    <Step key={label}>
                        <StepLabel sx={{ "& .MuiStepLabel-label": { color: textColor } }} StepIconComponent={ColorlibStepIcon}>{status === info && capitalizeFirst(info)}</StepLabel>
                    </Step>
                )
            })}
        </Stepper>
    )
}

export default TaskProgress