import React, { useState, useEffect, useCallback } from 'react';
import {
    Paper,
    Grid,
    Avatar,
    Box,
    Card,
    Button,
    MenuItem,
    Checkbox,
    ListItemText,
    Input,
} from '@mui/material';
import CustomTypography from '../../../components/texts/CustomTypography';
import FormInput from '../../../components/formInputs/FormInput';
import * as Yup from 'yup';
import { handleEditFormSubmit, uploadImage } from '../helpers';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import ImageCropper from '../../settings/areas/components/ImageCropper';
import { optimizImg } from '../../../helpers/optimizeImg';
import { FILEPOST } from '../../../helpers/http';
import { errorToast, successToast } from '../../../helpers/apiToast';

const ProfilePage = ({ userData, enabledFields, buildingsList, departmentsList, userList, userId }) => {
    const { profilePictureUrl, userRef, buildingId } = userData;

    const { fileReadUrl } = useSelector(state => state?.configurations);

    const [formValues, setFormValues] = useState({});
    const [errors, setErrors] = useState({});
    const [userListing, setUserListing] = useState([...userList]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [cropModal, setCropModal] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [selectedBuildings, setSelectedBuildings] = useState([]);
    const [selectedBuildingId, setSelectedBuildingId] = useState('');
    const [selectedManager, setSelectedManager] = useState('');
    const [selectedReportsFrom, setSelectedReportsFrom] = useState([]);

    const navigate = useNavigate();

    const getPrefilledBuildings = useCallback(() => {
        return userData?.buildings?.map(b => b?._id) || [];
    }, [userData]);

    const getPrefilledReportsFrom = useCallback(() => {
        const reportsFrom = userData.reportingChain?.directReportsFrom?.map(b => b?._id) || [];
        return reportsFrom;
    }, [userData]);

    useEffect(() => {
        setFormValues({
            fullName: userData?.userRef?.fullName || '',
            employeeId: userData?.employeeId || '',
            role: userData?.userRef?.role || '',
            designation: userData.designation || '',
            dateOfJoining: userData.dateOfJoining ? moment(userData.dateOfJoining).format('YYYY-MM-DD') : '',
            deskNumber: userData.deskNumber || '',
            parkingSlotNumber: userData.parkingSlotNumber || 'NA',
            emergencyContactName: userData.emergencyContact?.name || '',
            emergencyContactMobile: userData.emergencyContact?.mobile || '',
            bloodGroup: userData.bloodGroup || '',
            workLocation: userData.workLocation || ''
        });

        setSelectedDepartment([userData?.department?._id] || '');
        setSelectedBuildingId(buildingId);
        setSelectedBuildings(getPrefilledBuildings());
        setSelectedManager(userData.reportingChain?.reportsTo?._id);
        setSelectedReportsFrom(getPrefilledReportsFrom());
        setUserListing([...userList]);
    }, [userData, buildingId, getPrefilledBuildings, getPrefilledReportsFrom, userList]);

    const validationSchema = Yup.object().shape({
        employeeId: Yup.string().required('Employee ID is required'),
        role: Yup.string().required('Role is required'),
        designation: Yup.string().required('Designation is required'),
        dateOfJoining: Yup.date().required('Date of Joining is required').typeError('Must be a valid date'),
        deskNumber: Yup.string().required('Desk Number is required'),
        parkingSlotNumber: Yup.string().nullable().default('NA').required('Parking Slot Number is required'),
        emergencyContactName: Yup.string().required('Emergency Contact Name is required'),
        emergencyContactMobile: Yup.string().required('Emergency Contact Mobile is required')
            .matches(/^[0-9]+$/, 'Must be a number')
            .min(10, 'Must be at least 10 digits')
            .max(15, 'Must be at most 15 digits'),
        bloodGroup: Yup.string().required('Blood Group is required'),
        buildingsReference: Yup.array().min(1, 'Select at least one building').required('At least one building is required'),
        departmentsReference: Yup.array().min(1, 'Select at least one department').required('At least one department is required'),
        reportsTo: Yup.string().required('Reports To is required'),
        reportsFrom: Yup.array().min(1, 'Select at least one report from').required('At least one report from is required'),
    });

    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const result = await uploadImage({ payload: file });
            setSelectedImage(result);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormValues((prev) => ({ ...prev, [name]: value }));
        // Reset the error for the current field
        setErrors((prev) => ({ ...prev, [name]: undefined }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await validationSchema.validate({
                ...formValues,
                buildingsReference: selectedBuildings,
                departmentsReference: selectedDepartment,
                reportsTo: selectedManager,
                reportsFrom: selectedReportsFrom,
            }, { abortEarly: false });

            const payload = {
                role: formValues.role,
                employeeId: formValues.employeeId,
                designation: formValues.designation,
                dateOfJoining: formValues.dateOfJoining,
                deskNumber: formValues.deskNumber,
                parkingSlotNumber: formValues.parkingSlotNumber,
                buildings: selectedBuildings,
                buildingId: selectedBuildingId,
                department: selectedDepartment[0] || '',
                profilePictureUrl: selectedImage,
                reportingChain: {
                    reportsTo: selectedManager || userData.reportingChain?.reportsTo?._id || '',
                    directReportsFrom: selectedReportsFrom || [],
                },
                emergencyContact: {
                    name: formValues.emergencyContactName,
                    mobile: formValues.emergencyContactMobile,
                },
                bloodGroup: formValues.bloodGroup,
            };

            setErrors({});

            const result = await handleEditFormSubmit({ id: userId, payload, setFormValues, formValues });
            if (result?.status === 200) {
                setFormValues(result?.data);
                navigate('/admin/usermanagement');
            }
        } catch (err) {
            const validationErrors = {};
            if (err.inner) {
                err.inner.forEach(e => {
                    validationErrors[e.path] = e.message;
                });
            }
            setErrors(validationErrors);
        }
    };

    const uploadCroppedImg = async (img) => {
        //optimize bfr Image
        const optImg = await optimizImg(img)
        // console.log("🚀 ~ afetr opt ~ img:", optImg.size / 1000, 'kb')

        const myFile = new File([optImg], 'image.jpeg', {
            type: optImg.type,
        });

        //upload Img
        let formData = new FormData();
        formData.append('file', myFile)
        FILEPOST(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/user/image`, formData, "IMAGE UPLOAD")
            .then((data) => {
                if (data?.status === 201) {
                    successToast('Image uploaded successfully!!');
                    setSelectedImage(data?.data)
                }
                else errorToast('Something went wrong!!');
            })
            .catch((error) => {
                console.log(error.message)
                errorToast(error?.response?.data?.message || error.message)
            })
    }

    const getInitials = (name) => name.split(' ').map(n => n.charAt(0)?.toUpperCase()).join('');

    const handleSelectChange = (e, setFunction) => {
        const { value, name } = e.target;
        console.log({ value });


        if (name === 'Reports To') {
            setUserListing(userList.filter(el => el?._id !== value));
        }
        setFunction(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <Box sx={{ margin: '0 20px 20px', minHeight: '87vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
            <Paper sx={{ padding: '1.5rem', marginTop: '1rem', borderRadius: '16px', maxWidth: '60%', width: '100%' }} elevation={0} className='theme-component-background'>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'inline-block',
                                width: 'fit-content',
                                '&:hover .backdrop': {
                                    display: 'flex',

                                },
                            }}
                        >
                            <Avatar
                                src={`${fileReadUrl}${selectedImage}`}
                                sx={{ width: 150, height: 150, marginRight: 2 }}
                            />

                            {selectedImage ? (
                                <Box
                                    className="backdrop"
                                    sx={{
                                        display: 'none',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: 150,
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                        height: 150,
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: 'white',
                                        zIndex: 1,
                                    }}
                                >
                                    <DeleteOutlined sx={{ fontSize: 48 }} onClick={() => setSelectedImage(null)} />
                                </Box>
                            ) : <label htmlFor="icon-button-file">
                                <Input
                                    accept="image/*"
                                    id="icon-button-file"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setSelectedImage(URL.createObjectURL(e.target.files[0]))
                                        setCropModal(true)
                                    }} />
                                <Box
                                    className="backdrop"
                                    sx={{
                                        display: 'none',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: 150,
                                        borderRadius: '50%',
                                        cursor: 'pointer',
                                        height: 150,
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: 'white',
                                        zIndex: 1,
                                    }}
                                >
                                    <EditOutlined sx={{ fontSize: 48 }} />
                                </Box>
                            </label>
                            }
                        </Box>

                    </Grid>

                    <Grid item xs={12}>
                        <CustomTypography variant="h5" textAlign='center' color='grey !important'>{userData?.userRef?.email}</CustomTypography>
                        <CustomTypography variant="body1" textAlign='center' color='grey !important'>{userData?.designation}</CustomTypography>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card sx={{ border: '1px solid #e0e0e0', borderRadius: '1rem', padding: '1.5rem', margin: '1rem 0' }}
                            className='theme-component-background' elevation={0}>
                            <form>
                                <Grid container spacing={2}>
                                    {/* First Half */}
                                    <Grid item xs={6}>
                                        {[
                                            'employeeId',
                                            'designation',
                                            'dateOfJoining',
                                            'parkingSlotNumber',
                                        ].map((field, index) =>
                                            <FormInput
                                                sx={{ mb: 2 }}
                                                key={index}
                                                size='small'
                                                name={field}
                                                type={field === 'dateOfJoining' ? 'date' : 'text'}
                                                value={formValues[field]}
                                                variant="outlined"
                                                fullWidth
                                                label={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
                                                onChange={handleInputChange}
                                                error={!!errors[field]}
                                                helperText={errors[field] || ''}
                                            />
                                        )}

                                        {/* Additional Select Fields */}
                                        <FormInput
                                            select
                                            size='small'
                                            SelectProps={{
                                                renderValue: (selected) => userList.filter(b => selected?.indexOf(b?._id) > -1)?.map(b => b?.fullName).join(', '),
                                            }}
                                            value={selectedManager}
                                            onChange={(e) => setSelectedManager(e.target.value)}
                                            fullWidth
                                            label="Reports To"
                                            name='Reports To'
                                            error={!!errors.manager}
                                            helperText={errors.manager || ''}
                                        >
                                            {userList.map(user => (
                                                <MenuItem key={user?._id} value={user?._id}>
                                                    <ListItemText primary={user?.fullName} />
                                                </MenuItem>
                                            ))}
                                        </FormInput>

                                        <FormInput
                                            select
                                            size='small'
                                            SelectProps={{
                                                multiple: true,
                                                renderValue: (selected) => userListing.filter(b => selected?.indexOf(b?._id) > -1)?.map(b => b?.fullName).join(', '),
                                            }}
                                            value={selectedReportsFrom}
                                            onChange={(e) => handleSelectChange(e, setSelectedReportsFrom)}
                                            fullWidth
                                            label="Reports From"
                                            name='Reports From'
                                            error={!!errors.reportsFrom}
                                            helperText={errors.reportsFrom || ''}
                                            sx={{ mt: 2 }}
                                        >
                                            {userListing.map(user => (
                                                <MenuItem key={user?._id} value={user?._id}>
                                                    <Checkbox checked={selectedReportsFrom?.indexOf(user?._id) > -1} />
                                                    <ListItemText primary={user?.fullName} />
                                                </MenuItem>
                                            ))}
                                        </FormInput>

                                        <FormInput
                                            select
                                            size='small'
                                            SelectProps={{
                                                renderValue: (selected) => departmentsList.filter(b => selected?.indexOf(b?._id) > -1)?.map(b => b?.name).join(', '),
                                            }}
                                            value={selectedDepartment}
                                            onChange={(e) => handleSelectChange(e, setSelectedDepartment)}
                                            fullWidth
                                            label="Select Department"
                                            name='departmentsReference'
                                            error={!!errors.department}
                                            helperText={errors.department || ''}
                                            sx={{ mt: 2 }}
                                        >
                                            {departmentsList?.map(dept => (
                                                <MenuItem key={dept?._id} value={dept?._id}>
                                                    <ListItemText primary={dept?.name} />
                                                </MenuItem>
                                            ))}
                                        </FormInput>

                                    </Grid>

                                    {/* Second Half */}
                                    <Grid item xs={6}>
                                        {[
                                            'fullName',
                                            'emergencyContactName',
                                            'emergencyContactMobile',
                                            'bloodGroup',
                                            'deskNumber',
                                        ].map((field, index) =>
                                            <FormInput
                                                sx={{ mb: 2 }}
                                                key={index}
                                                size='small'
                                                name={field}
                                                type={field === 'emergencyContactMobile' ? 'tel' : 'text'}
                                                value={formValues[field]}
                                                variant="outlined"
                                                fullWidth
                                                label={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
                                                onChange={handleInputChange}
                                                error={!!errors[field]}
                                                helperText={errors[field] || ''}
                                            />
                                        )}
                                        <FormInput
                                            select
                                            size='small'
                                            SelectProps={{
                                                renderValue: (selected) => buildingsList.filter(b => selected?.indexOf(b?._id) > -1)?.map(b => b?.name).join(', '),
                                            }}
                                            value={selectedBuildingId}
                                            onChange={(e) => setSelectedBuildingId(e.target.value)}
                                            fullWidth
                                            label="Select Building ID"
                                            name='buildingId'
                                            error={!!errors.buildingId}
                                            helperText={errors.buildingId || ''}
                                        >
                                            {buildingsList.map(building => (
                                                <MenuItem key={building?._id} value={building?._id}>
                                                    <ListItemText primary={building?.name} />
                                                </MenuItem>
                                            ))}
                                        </FormInput>

                                        <FormInput
                                            select
                                            size='small'
                                            SelectProps={{
                                                multiple: true,
                                                renderValue: (selected) => buildingsList.filter(b => selected?.indexOf(b?._id) > -1)?.map(b => b?.name).join(', '),
                                            }}
                                            value={selectedBuildings}
                                            onChange={(e) => handleSelectChange(e, setSelectedBuildings)}
                                            fullWidth
                                            label="Select Buildings"
                                            name='buildingsReference'
                                            error={!!errors.building}
                                            helperText={errors.building || ''}
                                            sx={{ mt: 2 }}
                                        >
                                            {buildingsList.map(building => (
                                                <MenuItem key={building?._id} value={building?._id}>
                                                    <Checkbox checked={selectedBuildings?.indexOf(building?._id) > -1} />
                                                    <ListItemText primary={building?.name} />
                                                </MenuItem>
                                            ))}
                                        </FormInput>

                                    </Grid>
                                </Grid>

                                <Button
                                    variant="contained"
                                    sx={{
                                        mt: 2,
                                        width: '10rem',
                                        height: '2.5rem',
                                        backgroundColor: '#dc4d28',
                                        '&:hover': { backgroundColor: '#b74a24' },
                                    }}
                                    onClick={handleSubmit}
                                >
                                    Update
                                </Button>
                            </form>
                        </Card>
                    </Grid>
                </Grid>
            </Paper>
            {
                cropModal && <ImageCropper
                    img={selectedImage}
                    setImg={setSelectedImage}
                    open={cropModal}
                    close={() => setCropModal(false)}
                    uploadCroppedImg={uploadCroppedImg}
                />
            }
        </Box>
    );
};

export default ProfilePage;
