import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { errorToast } from '../../../../helpers/apiToast'
import { FILEPOST, GET, PUT } from '../../../../helpers/http'
import { ThemeColors } from '../../../../hooks/ThemeColors'
import LimitTags from '../../../../components/formInputs/CustomAutoComplete'
import CustomButton from '../../../../components/button/CustomButton'
import { Box, Button, Grid, IconButton, styled } from '@mui/material'
import CountryPhoneInput from './countryPhoneInput/CountryPhoneInput'
import DropDown from './dropDown/DropDown'
import CustomFormField from '../../../../components/formInputs/CustomFormField'
import CustomTypography from '../../../../components/texts/CustomTypography'
import CustomComponentContainer from '../../../../components/container/CustomComponentContainer'
import styles from '../../../../components/formInputs/inputFlieUpload/InputFileUpload.module.css';
import { CloudUpload, HighlightOffOutlined } from '@mui/icons-material'
import AddAssetFormStyles from '../../../../styles/AddAssetFormStyles'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const validationSchema = Yup.object().shape({
    fullName: Yup.string().trim().required('Full Name is required'),
    email: Yup.string().email('Invalid email address'),
    countryCallingCode: Yup.string().required('Country calling code is required'),
    mobile: Yup.string().matches(/^\+?[1-9]\d{1,14}$/, 'Invalid phone number').required('Phone number is required'),
    type: Yup.string().required('Type is required'),
    vendor: Yup.string().required('Vendor is required'),
    // department: Yup.string().required('Department is required'),
    // categories: Yup.array().of(Yup.string()).min(1, 'At least one category is required').required('Categories are required'),
    buildingId: Yup.string().required('Building ID is required'),
    address: Yup.string().required('Address is required'),
    identificationDocuments: Yup.array(),
    // buildings: Yup.array().of(Yup.string()).min(1, 'At least one building is required').required('Managed buildings are required')
});

const VendorUserEdit = () => {
    const { id } = useParams()
    const { commonSX } = ThemeColors();

    const [identificationDocuments, setIdentificationDocuments] = useState([]);
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        address: '',
    });

    const [phone, setPhone] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCallingCode, setCountryCallingCode] = useState('')

    const [formErrors, setFormErrors] = useState({});

    const [type] = useState([{ name: 'staff' }, { name: 'supervisor' }])
    const [vendor, setVendor] = useState([])
    // const [categories, setCategories] = useState([])
    // const [selectedCategories, setSelectedCategories] = useState([])
    const [selectedBuildings, setSelectedBuildings] = useState([])
    const [buildingId, setBuildingId] = useState([])
    const [departments, setDepartments] = useState([])

    const [dropDowns, setDropDowns] = useState({
        type: '',
        vendor: '',
        buildingId: '',
    })

    const [defaultValue, setDefaultValue] = useState({
        departments: '',
        buildingId: '',
        categories: [],
        buildings: []
    })

    const [profilePicture, setProfilePicture] = useState('');

    const fileReadUrl = useSelector(state => state?.configurations?.fileReadUrl)

    const formFields = [
        { type: 'title', label: 'Personal Information', required: true, xs: 12 },
        { type: 'textField', name: 'fullName', label: 'Full Name', required: true, xs: 6 },
        { type: 'textField', name: 'email', label: 'Email', required: true, xs: 6 },
        { type: 'phoneField', name: 'phone', label: 'Phone', required: true, xs: 6 },
        { type: 'title', label: 'Respective Vendor', required: true, xs: 12 },
        { type: 'selectField', name: 'type', label: 'Type', value: type, required: true, xs: 6, defaultValue: dropDowns.type },
        { type: 'selectField', name: 'vendor', label: 'Vendor', value: vendor, required: true, xs: 6, defaultValue: dropDowns.vendor },
        { type: 'selectField', name: 'department', label: 'Department', value: departments, defaultValue: defaultValue.departments, required: true, xs: 6 },
        // {
        //     type: 'multiselect', name: 'categories', label: 'Categories', value: categories, defaultValue: defaultValue.categories,
        //     selectedOptions: selectedCategories, setSelectedOptions: setSelectedCategories, required: true, xs: 6,
        //     multiselectError: formErrors.categories
        // },
        {
            type: 'selectField', name: 'buildingId', label: 'Associated Building',
            defaultValue: defaultValue.buildingId, value: buildingId, required: true, xs: 6
        },
        { type: 'textField', name: 'address', label: 'Address', required: true, xs: 6 },
        {
            type: 'multiselect', name: 'managedBuildings', label: 'Managed Buildings', value: buildingId, defaultValue: defaultValue.buildings,
            required: true, xs: 6, selectedOptions: selectedBuildings, setSelectedOptions: setSelectedBuildings,
            multiselectError: formErrors.buildings
        },
        { type: 'title', label: 'Identification Documents *', required: true, xs: 12 },
        {
            type: 'fileUpload', name: 'identificationDocuments', label: 'Documents', required: true, xs: 12,
            uploadPath: 'accounts_mgmt_media_uploads/', value: identificationDocuments, allowMultiple: true
        },
        { type: 'title', label: 'Profile Photo *', required: true, xs: 12 },
        {
            type: 'fileUpload', name: 'profilePicture', label: 'Profile Picture', required: true, xs: 12,
            uploadPath: 'accounts_mgmt_media_uploads/', value: profilePicture, setState: setProfilePicture
        }
    ];

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setFormErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const handlePhoneInputs = (value, data) => {
        setPhone(value);
        const countryCallingCode = data.dialCode;
        const mobile = value.slice(countryCallingCode?.length);
        setCountryCallingCode(`+${countryCallingCode}`)
        setPhoneNumber(mobile);
    };

    const navigate = useNavigate()

    const handleSubmit = async () => {
        const completeData = {
            ...formData,
            vendorUserId: id,
            countryCallingCode,
            mobile: phoneNumber,
            identificationDocuments,
            profilePictureUrl: profilePicture?.key,
            buildingId: buildingId[0]?._id,
            ...dropDowns,
            // categories: selectedCategories.map(el => el._id),
            buildings: selectedBuildings?.map(el => el?._id)
        };
        try {
            await validationSchema.validate(completeData, { abortEarly: false });
            setFormErrors({});
            const result = await submitCall(completeData);
            if (result?.status === 200) {
                navigate('/admin/settings/vendor-user')
            }
        } catch (errors) {
            const validationErrors = {};
            errors.inner.forEach((error) => {
                validationErrors[error.path] = error.message;
            });
            setFormErrors(validationErrors);
        }
    };

    const handleDropDownChange = useCallback((e) => {
        const { name, value } = e.target;
        setDropDowns((prevData) => ({ ...prevData, [name]: value }));
        setFormErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }, []);

    const submitCall = async (completeData) => {
        try {
            const response = await PUT(`${process.env.REACT_APP_BASE_URL}um/v1/admin/vendor-user`, { ...completeData }, "VENDOR ADD")
            return response
        } catch (error) {
            console.log({ error });
        }
    }

    useEffect(() => {
        // const getCategories = async () => {
        //     try {
        //         const result = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/category/list`, "GET CATEGORIES")
        //         setCategories(result?.data)
        //     } catch (error) {
        //         console.log({ error });
        //     }
        // }

        const getAllVendors = async () => {
            try {
                const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/vendor/list`, "GET VENDORS")
                setVendor(response?.data);
            } catch (error) {
                console.log({ error });
            }
        }

        const getAllBuildingIds = async () => {
            try {
                const result = await GET('https://dev-gateway.letsnhance.com/twin/v1/buildings', "GET BUILDING IDS")
                setBuildingId(result?.data)
            } catch (error) {
                console.log({ error });
            }
        }

        const getAllDepartments = async () => {
            try {
                const result = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/department/list`, "GET DEPARTMENTS")
                setDepartments(result?.data)
            } catch (error) {
                console.log({ error });
            }
        }

        const getVendorUserById = async () => {
            try {
                const response = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/admin/vendor-user?id=${id}`)
                if (response?.status === 200) {
                    setFormData({
                        fullName: response?.data?.fullName,
                        email: response?.data?.email,
                        address: response?.data?.address
                    })
                    setPhone(`${response?.data?.countryCallingCode}${response?.data?.mobile}`)
                    setPhoneNumber(response?.data?.mobile)
                    setCountryCallingCode(response?.data?.countryCallingCode)
                    setDropDowns({
                        type: response?.data?.type,
                        vendor: response?.data?.vendor
                    })
                    setDefaultValue({
                        departments: response?.data?.department,
                        buildingId: response?.data?.buildingId,
                        categories: response?.data?.category,
                        buildings: response?.data?.buildings,
                    })
                    setProfilePicture(response?.data?.profilePictureUrl)
                    setIdentificationDocuments(response?.data?.identificationDocuments)
                }
            } catch (error) {
                errorToast(error?.response?.data?.message)
            }
        }

        getAllVendors()
        // getCategories()
        getAllBuildingIds()
        getAllDepartments()
        getVendorUserById()
        return () => {
            setVendor([])
            // setCategories([])
            setDepartments([])
            setBuildingId([])
        }
    }, [id])


    const mediaUpload = async (file) => {
        try {
            const result = await FILEPOST(`${process.env.REACT_APP_ACCOUNTSUI_URL}api/v1/media/upload`, { file }, "MEDIA UPLOAD")
            return result.data
        } catch (error) {
            setFormErrors({ documents: `Invalid File: ${file.name}` })
        }
    }

    const handleMultipleFileUpload = useCallback(async (fileItems, arr, name) => {
        let response = []
        console.log({ fileItems });
        if (fileItems.length > 0) {
            try {
                response = await Promise.allSettled([...fileItems]?.map((fileItem) => mediaUpload(fileItem)))
                response = response.map((result) => result?.value)
                setIdentificationDocuments([...arr, ...response])
            } catch (error) {
                setFormErrors({ [name]: error?.message || error?.response?.data?.message })
            }
        }
    }, []);

    const handleFileUploads = useCallback(async (fileItems, name, setState) => {
        let response = ''
        console.count('single file upload');
        if (fileItems.length > 0) {
            try {
                response = await mediaUpload(fileItems[0])
                if (response) {
                    setState(response)
                }
            } catch (error) {
                setFormErrors({ [name]: error?.message || error?.response?.data?.message })
            }
        }
    }, []);

    const handleRemoveFile = (id) => {
        const result = identificationDocuments.filter((el) => el.uploadId !== id)
        setIdentificationDocuments(result)
    }

    const handleRemoveSingleFile = ({ name, setState }) => {
        setState('');
    }

    const imageRenderer = ({ value, name, setState }) => {
        if (!value) return;
        if (Array.isArray(value)) {
            return <div className='flex'>
            {
                value?.map((el, index) => {
                    return !el ? null : <Box sx={{ ...AddAssetFormStyles.commonImageBox, mt: '10px' }} key={index}>
                        <object data={`${fileReadUrl}${el?.key}`} height={100} width={'100%'} aria-label='images' />
                        <IconButton onClick={() => { handleRemoveFile(el?.uploadId) }}><HighlightOffOutlined fontSize='medium' /></IconButton>
                    </Box>
                })
            }
        </div>
        }
        else if (!Array.isArray(value) && typeof (value) === 'string') {
            return <Box sx={{ ...AddAssetFormStyles.commonImageBox, width: '30%', mt: '10px' }}>
                <object data={`${fileReadUrl}${value}`} height={100} width={'100%'} aria-label='image' />
                <IconButton onClick={() => { handleRemoveSingleFile({ name, setState }) }}><HighlightOffOutlined fontSize='medium' /></IconButton>
            </Box>
        }
        else if (!Array.isArray(value) && typeof (value) === 'object' && Object.keys(value).includes('key')) {
            return <Box sx={{ ...AddAssetFormStyles.commonImageBox, width: '30%', mt: '10px' }}>
                <object data={`${fileReadUrl}${value?.key}`} height={100} width={'100%'} aria-label='image' />
                <IconButton onClick={() => { handleRemoveSingleFile({ name, setState }) }}><HighlightOffOutlined fontSize='medium' /></IconButton>
            </Box>

        }
    }

    return (
        <div className='pad8 minHeight89vh'>
            <div className='flex flexAlignItemsCenter flexJustifyBetween'>
                <CustomTypography className='font30 fontweight900'>Edit Vendor User</CustomTypography>
                <CustomButton
                    variant='contained'
                    sx={{ mr: '20px', width: '12rem', height: '2.4rem' }}
                    className={styles.button_bgc}
                    onClick={() => navigate('/admin/settings/vendor-user')}
                >
                    cancel
                </CustomButton>
            </div>
            <CustomComponentContainer className='mrgnsettingpage pad10'>
                <Grid container className='pad10 width50'>
                    {formFields.map(({ type, name, label, required, xs, value, dropDownOptions,
                        setState, setSelectedOptions, selectedOptions, multiselectError, defaultValue,
                        allowMultiple }, index) => (
                        <Grid item className='pad5' xs={xs} key={index}>
                            {type === 'title' && (
                                <CustomTypography className='mrgnleft10 bold' sx={{ mt: index > 0 && '10px' }}>
                                    {label}
                                </CustomTypography>
                            )}
                            {type === 'textField' && (
                                <CustomFormField
                                    sx={commonSX}
                                    size='small'
                                    label={label}
                                    name={name}
                                    required={required}
                                    value={formData[name]}
                                    onChange={handleTextChange}
                                    error={Boolean(formErrors[name])}
                                    helperText={formErrors[name]}
                                />
                            )}
                            {type === 'phoneField' && (
                                <CountryPhoneInput
                                    phone={phone}
                                    handlePhoneInputs={handlePhoneInputs}
                                    formErrors={formErrors.phone || formErrors.mobile}
                                />
                            )}
                            {type === 'selectField' && (
                                <DropDown
                                    defaultValue={defaultValue}
                                    formErrors={formErrors[name]}
                                    name={name}
                                    required={required}
                                    label={label}
                                    options={value}
                                    dropDownOptions={dropDownOptions}
                                    setState={setState}
                                    handleDropDownChange={handleDropDownChange}
                                />
                            )}
                            {type === 'fileUpload' && (
                                <>
                                    <Button
                                        component="label"
                                        className={styles.button_bgc}
                                        role={undefined}
                                        size='small'
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUpload className='color-white' />}
                                        sx={{ width: '12rem', height: '2.4rem' }}
                                    >
                                        {label}
                                        <VisuallyHiddenInput
                                            type="file"
                                            accept="image/*,application/pdf"
                                            multiple={allowMultiple}
                                            name={name}
                                            onChange={(e) => {
                                                allowMultiple ? handleMultipleFileUpload(e.target.files, value) : handleFileUploads(e.target.files, name, setState)
                                                e.target.value = ''
                                            }
                                            }
                                        />
                                    </Button>
                                    {value && imageRenderer({ value, name, setState })}
                                </>
                            )}
                            {
                                type === 'multiselect' && (
                                    <LimitTags
                                        defaultValue={defaultValue}
                                        label={label}
                                        options={value}
                                        selectedOptions={selectedOptions}
                                        setSelectedOptions={setSelectedOptions}
                                        error={multiselectError}
                                    />
                                )
                            }
                        </Grid>
                    ))}
                    <Grid item xs={12} className='pad5'>
                        <CustomButton
                            variant='contained'
                            sx={{ mt: '10px', width: '12rem', height: '2.4rem' }}
                            className={styles.button_bgc}
                            onClick={handleSubmit}
                        >
                            Submit
                        </CustomButton>
                    </Grid>
                </Grid>
            </CustomComponentContainer>
        </div >
    )
}

export default VendorUserEdit