import { Avatar, Box, Button, Grid, IconButton, InputAdornment, MenuItem, Paper, TextField, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import CommonStyles from '../../../styles/CommonStyles'
import { useSelector } from 'react-redux'
import { EditOutlined, SearchOutlined, VisibilityOutlined, Sync, AddCircleOutline, UploadFileOutlined } from '@mui/icons-material'
import Datatable from '../../../components/datatable/Datatable'
import Modal from '../../../components/modal/Modal'
import { useEffect } from 'react'
import { GET } from '../../../helpers/http'
import EditUser from '../../../features/userManagement/components/modals/EditUser'
import validSearchTerm from '../../../helpers/validSearchTerm'
import Loader from '../../../components/loading/Loader'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { fetchUserList, handleSync } from '../../../features/userManagement/helpers'
import StatusSwitch from '../../../features/userManagement/components/StatusSwitch'

const UserManagement = () => {
    const userEmail = useSelector(state => state?.user?.email);
    const buildingsList = useSelector((state) => state?.buildings?.buildings)
    const isSSOUser = useSelector((state) => state?.user?.isPasswordResetEnabled);
    const { fileReadUrl } = useSelector(state => state?.configurations)

    const [isLoadingBtn, setIsLoadingBtn] = useState(false);

    const navigate = useNavigate();

    const [users, setUsers] = useState([])
    const [editUserModal, setEditUserModal] = useState(false)
    const [editUser, setEditUser] = useState({})
    const [searchTerm, setSearchTerm] = useState('')
    const [timer, setTimer] = useState(0)
    const [isLoaded, setIsLoaded] = useState(false)

    const themeMode = useSelector(state => state.theme.darkTheme)

    const usersList = users !== undefined ? users?.map(ele => { return { ...ele, id: ele?._id } })?.sort((a, b) => a?.fullName?.localeCompare(b?.fullName)) : [];

    const onSearchChange = (e) => {
        setSearchTerm(e.target.value)
    }

    const debounce = (fn, delay) => {
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(fn, delay))
    }

    const closeEditUserModal = () => {
        setEditUserModal(false)
        setEditUser({})
    }

    const openEditUserModal = (user) => {
        setEditUser(user.row)
        setEditUserModal(true)
    }

    const handleSyncClick = (e) => {
        setIsLoadingBtn(true);
        handleSync({ setLoading: setIsLoadingBtn, setUserList: setUsers });
    }

    useEffect(() => {
        if (isLoaded) setIsLoaded(false)
        const endpoint = searchTerm?.length >= 2 ? `${process.env.REACT_APP_BASE_URL}um/v1/user/list?searchTerm=${validSearchTerm(searchTerm)}` : `${process.env.REACT_APP_BASE_URL}um/v1/user/list`
        debounce(async function fetchUsers() {
            try {
                const response = await GET(endpoint)
                setUsers(response?.data)
                setIsLoaded(true)
            } catch (error) {
                console.log(error)
                setIsLoaded(true)
            }
        }, 300)
        if (usersList?.length > 1) fetchUserList({ setUserList: setUsers })
    }, [setUsers, searchTerm])

    function getInitials(name) {
        if (!name) return '';
        const words = name.split(' ');
        const initials = words.map(word => word.charAt(0).toUpperCase()).join('');
        return initials;
    }


    const columns = [
        {
            field: 'profilePictureUrl',
            headerName: 'Profile',
            headerAlign: 'center',
            align: 'center',
            flex: .5,
            renderCell: (cellValues) => {
                const { fullName, profilePictureUrl } = cellValues?.row;

                return (
                    <Avatar alt={getInitials(fullName)} src={`${fileReadUrl}${profilePictureUrl}`}>
                        {!profilePictureUrl && getInitials(fullName)}
                    </Avatar>
                );
            },
            editable: false,
            sortable: false,
            filterable: false,
        },
        {
            field: 'fullName',
            headerName: 'Name',
            headerAlign: 'center',
            align: 'center',
            flex: 1.5,
            valueGetter: (cellValues) => {
                const { fullName } = cellValues?.row;
                return fullName;
            },
            editable: false,
        },
        {
            field: 'empCode',
            headerName: 'Employee Code',
            flex: 1,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1.5,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'designation',
            headerName: 'Designation',
            flex: 1.5,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'role',
            headerName: 'Role',
            flex: 1,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'reportsTo',
            headerName: 'Manager',
            flex: 1.5,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                console.log({ row: cellValues?.row });

            }
        },
        {
            field: 'department',
            headerName: 'Department',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                const { department } = cellValues?.row;
                return department?.name;
            },
        },
        {
            field: 'buildings',
            headerName: 'Buildings',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                const buildings = cellValues?.row?.buildings?.map(building => building?.name).join(', ');
                return (
                    <Tooltip
                        title={
                            <>
                                <Typography fontWeight={600} borderBottom={'0.5px solid'}>
                                    {cellValues?.row?.fullName}
                                </Typography>
                                <Box sx={{ maxHeight: '150px', overflow: 'auto', ...CommonStyles.scrollStyle }}>
                                    {cellValues?.row?.buildings?.map((building, i) => (
                                        <MenuItem key={i}>{building?.name}</MenuItem>
                                    ))}
                                </Box>
                            </>
                        }
                    >
                        {buildings}
                    </Tooltip>
                );
            },
            editable: false,
            sortable: false,
            filterable: false,
        },
        {
            field: 'buildingId',
            headerName: 'Assigned Building',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                const building = buildingsList?.find(building => building?._id === cellValues?.row?.buildingId);
                return <Tooltip>{building?.name}</Tooltip>;
            },
            editable: false,
            sortable: false,
            filterable: false,
        },
        ...(isSSOUser ? [
            {
                field: 'status',
                headerName: 'Account Status',
                flex: 1,
                headerAlign: 'center',
                align: 'center',
                renderCell: (cellValues) => {
                    const status = cellValues?.row?.isSuspended;
                    return (
                        <>
                            <StatusSwitch status={status} id={cellValues?.row?._id} />
                        </>
                    );
                },
                sortable: false,
                editable: false,
            }
        ] : []),
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                const disable = userEmail === cellValues?.row?.email;
                return (
                    <>
                        <Tooltip title='EDIT' placement='top'>
                            {/* <IconButton size='small' onClick={() => openEditUserModal(cellValues)} disabled={disable}>
                                <EditOutlined fontSize='small' sx={{ color: disable ? '#808080 !important' : '#DC4D28 !important' }} />
                            </IconButton> */}
                            <IconButton size='small' onClick={() => navigate(`/admin/usermanagement/edit/${cellValues?.row?._id}`)} disabled={disable}>
                                <EditOutlined fontSize='small' sx={{ color: disable ? '#808080 !important' : '#DC4D28 !important' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='VIEW' placement='top'>
                            <IconButton size='small' onClick={() => navigate(`/admin/usermanagement/view/${cellValues?.row?._id}`)} disabled={disable}>
                                <VisibilityOutlined fontSize='small' sx={{ color: disable ? '#808080 !important' : '#DC4D28 !important' }} />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
            sortable: false,
            editable: false,
        }
    ];

    return (
        <Box sx={CommonStyles.OuterBox} className="user-management-page">
            <Typography
                sx={CommonStyles.pageHeading}
            >
                User Management
            </Typography>
            <Box className='user-management-container d100p mrgnauto flex flexDirectionCol flexJustifyEvenly'
            >
                <Paper
                    sx={{
                        ...CommonStyles.topPaper,
                    }}
                    className='theme-cards-boxShadow theme-component-background bdrRadius4px'
                >
                    <Grid container spacing={1} className='flexAlignItemsCenter flexJustifyBetween'>
                        {isSSOUser && <Grid item xs={8}>
                            <LoadingButton
                                onClick={handleSyncClick}
                                sx={{
                                    backgroundColor: '#dc4d28',
                                    '&:hover': { backgroundColor: '#c43e2d' },
                                }}
                                loading={isLoadingBtn}
                                loadingPosition="start"
                                startIcon={<Sync sx={{ mr: 1, color: '#fff' }} />}
                                variant="contained"
                            >
                                Sync Users
                            </LoadingButton>
                            <Button variant="contained"
                                onClick={() => navigate('/admin/usermanagement/add')}
                                sx={{
                                    ml: 2,
                                    backgroundColor: '#dc4d28',
                                    '&:hover': { backgroundColor: '#c43e2d', border: '1px solid #c43e2d' },
                                    border: '1px solid #dc4d28',
                                    color: '#fff',
                                }} startIcon={<AddCircleOutline sx={{ mr: 1, color: '#fff' }} />}>
                                Add user
                            </Button>
                            <Button variant="contained"
                                onClick={() => navigate('/admin/usermanagement/bulk-upload')}
                                sx={{
                                    ml: 2,
                                    backgroundColor: '#dc4d28',
                                    '&:hover': { backgroundColor: '#c43e2d', border: '1px solid #c43e2d' },
                                    border: '1px solid #dc4d28',
                                    color: '#fff',
                                }} startIcon={<UploadFileOutlined sx={{ mr: 1, color: '#fff' }} />}>
                                Add bulk users
                            </Button>
                        </Grid>}

                        <Grid item xs={4}>
                            <TextField
                                size="small"
                                value={searchTerm}
                                onChange={onSearchChange}
                                className='width100 maxHeight40px'
                                placeholder="Search a user by name or email"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchOutlined sx={{ color: '#616161' }} />
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        // Customize the border color
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#808080',
                                        },
                                        // Customize the focused border color
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#808080',
                                        },
                                        // Customize the input text color
                                        '& .MuiInputBase-input': {
                                            color: themeMode ? '#fff' : '#000'
                                        },
                                        // Customize the cursor color
                                        '& .MuiInputBase-input::selection': {
                                            backgroundColor: '#808080',
                                        },
                                        // Customize the border color on hover
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#808080',
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                {
                    isLoaded ? <>
                        <Datatable
                            checkBox={false}
                            rows={usersList}
                            columns={columns}
                            pageSize={15}
                            rowsPerPage={15}
                        />
                        {
                            editUserModal && (
                                <Modal modalOpen={editUserModal} closeModal={closeEditUserModal} title='Edit User'>
                                    <EditUser editUser={editUser} closeEditUserModal={closeEditUserModal} users={users} setUsers={setUsers} />
                                </Modal>)
                        }
                    </> : <Loader height={'100%'} />
                }
            </Box>
        </Box >
    )
}

export default UserManagement