import { Box, Button } from "@mui/material"
import CustomComponentContainer from "../../../components/container/CustomComponentContainer"
import HistoryCard from "../components/taskView/HistoryCard"
import { errorToast } from "../../../helpers/apiToast";
import { GET } from "../../../helpers/http";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NoDataFound from "../../../components/noDataFound/NoDataFound";
import Loader from "../../../components/loading/Loader";
import HistoryDetails from "../components/taskView/HistoryDetails";
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';

const History = () => {
    const { id } = useParams();
    const [batchNumber, setBatchNumber] = useState(1)
    const [historyList, setHistoryList] = useState([])
    const [historyListLoaded, setHistoryListLoaded] = useState(false)
    const [loadMoreEnded, setLoadmoreEnded] = useState(false)

    const [openDetails, setOpenDetails] = useState(false)
    const [selectedHistory, setSelectedHistory] = useState({})

    const handleHistoryDetail = ({ historyObj }) => {
        if (selectedHistory?._id === historyObj?._id) {
            setOpenDetails(!openDetails)
        } else {
            setSelectedHistory(historyObj)
            setOpenDetails(true)
        }
    }

    useEffect(() => {
        const fetchHistory = async ({ id }) => {
            try {
                const response = await GET(`${process.env.REACT_APP_URL}ppm/v1/ppm-history/${id}?batch=${batchNumber}`, "Fault Report - Get Fault History")
                // const response = await GET(`http://localhost:8004/v2/fault/history?id=${id}`, "Fault Report - Get Fault History")
                if (response?.status === 200) {
                    setHistoryList([...historyList, ...response?.data])
                    setHistoryListLoaded(true)
                    if (response?.data?.length < 10) setLoadmoreEnded(true)
                }
            } catch (error) {
                console.log(error)
                if (error?.response) errorToast(error?.response?.data?.message)
                else errorToast(error?.message);
                setHistoryListLoaded(true)
            }
        }
        fetchHistory({ id })
    }, [id, batchNumber])

    const handleLoadMore = () => {
        if (!loadMoreEnded) setBatchNumber(batchNumber + 1)
    }


    return (
        <CustomComponentContainer
            className='commonComponentSpacing'
            sx={{
                minHeight: '80vh',
                display: "flex",
                flexDirection: "row",
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: "100%"
                }}>
                {
                    historyListLoaded ?
                        <Box sx={{ paddingY: "2rem" }}>
                            {
                                historyList?.length > 0 ?
                                    historyList?.map(log => {
                                        return (
                                            <Box >
                                                <HistoryCard
                                                    key={log?._id}
                                                    handleHistoryDetail={handleHistoryDetail}
                                                    data={log}
                                                    isCardSelected={selectedHistory?._id === log?._id}
                                                    sx={{
                                                        // backgroundColor: selectedHistory?._id === log?._id && "#f5f5f5",
                                                        borderRadius: "1rem",

                                                    }}
                                                    type={historyList[historyList?.length - 1]?._id === log?._id ? "last" : "normal"}
                                                />
                                                <Box sx={{ width: "50%" }}>
                                                    {(openDetails && selectedHistory?._id === log?._id) && <HistoryDetails data={selectedHistory} />}
                                                </Box>
                                            </Box>
                                        )
                                    })
                                    :
                                    <NoDataFound height="70vh" message="Log not found" />
                            }
                            {
                                !loadMoreEnded && <Button
                                    sx={{ marginLeft: "4.5rem", marginTop: "2rem" }}
                                    onClick={() => handleLoadMore()}
                                >Load more
                                    <ExpandMoreTwoToneIcon />
                                </Button>
                            }
                        </Box>
                        :
                        <Loader height='70vh' />
                }
            </Box>
        </CustomComponentContainer>
    )
}

export default History