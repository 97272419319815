import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import CustomComponentContainer from '../../../components/container/CustomComponentContainer'
import Loader from '../../../components/loading/Loader'
import NoDataFound from '../../../components/noDataFound/NoDataFound'
import CustomSecondaryHeader from '../../components/header/CustomSecondaryHeader'
import StatsNumberCard from '../components/stats/StatsNumberCard'
import HighChartPPMRaisedMonthwise from '../components/charts/HighChartsPPMRaisedMonthwise'
import HighchartsPPM30DaysTrend from '../components/charts/HighchartsPPM30DaysTrend'
import CustomTypography from '../../../components/texts/CustomTypography'
import { GET } from '../../../helpers/http'
import { errorToast } from '../../../helpers/apiToast'

const PPMStatistics = () => {
    // const [statisticsData, setStatisticsData] = useState({
    //     ticketsBasedOnTaskStatus: { data: [2], isLoaded: true },
    //     ticketsBasedOnCategories: { data: [2], isLoaded: true },
    //     ticketsBasedOnPriorities: { data: [2], isLoaded: true },
    //     ticketsBasedOnDueDate: { data: [2], isLoaded: true },
    //     ticketsRaisedCompletionDaywise: { data: [2], isLoaded: true }
    // })

    const [statisticsData, setStatisticsData] = useState({})
    const [chartData, setChartData] = useState({})
    const [monthChart, setMonthChart] = useState({})
    const CARD_HEIGHT = "30vh"

    // const { themeMode } = ThemeColors();

    const getStatistics = async () => {
        // try {
        //     const optionsResponse = await GET(`http://localhost:8030/v1/task/statistics-options`)
        //     console.log(optionsResponse, "<<<<<<<<<<<<<<<<<<<<<<<<< options response")
        //     // const optionsResponse = await GET(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/fault/statistics-options`)
        //     if (optionsResponse?.status === 200) {
        //         const requests = optionsResponse?.data?.map(async ({ key, option }) => {
        //             return { key, data: await GET(`http://localhost:8030/v1/task/statistics1?buildingId=${localStorage.getItem("bid")}&option=${option}`, `Get ${key} `) }
        //             // return { key, data: await GET(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/fault/statistics?option=${option}&buildingId=${localStorage.getItem('bid')}`, `Get ${key} `) }
        //         })

        //         const results = await Promise.allSettled(requests)
        //         const newStatisticsData = results?.reduce((acc, { status, value }) => {
        //             if (status === "fulfilled") {
        //                 acc[value?.key] = { data: value?.data["data"], isLoaded: true };
        //             } else {
        //                 acc[value?.key] = { data: [], isLoaded: true };
        //             }
        //             return acc;
        //         }, {});

        //         console.log({ newStatisticsData })

        //         setStatisticsData({
        //             ...statisticsData,
        //             ...newStatisticsData
        //         });
        //     } else {
        //         setStatisticsData({
        //             ticketsBasedOnTaskStatus: { data: [], isLoaded: true },
        //             ticketsBasedOnCategories: { data: [], isLoaded: true },
        //             ticketsBasedOnPriorities: { data: [], isLoaded: true },
        //             ticketsBasedOnDueDate: { data: [], isLoaded: true },
        //             ticketsRaisedCompletionDaywise: { data: [], isLoaded: true }
        //         })
        //     }
        // } catch (error) {
        //     errorToast(error?.response?.data?.message || error.message);
        // }

    }

    useEffect(() => {
        // getStatistics()
        (async () => {
            try {
                const bid = localStorage.getItem('bid')
                if (bid) {
                    const response = await GET(`${process.env.REACT_APP_URL}ppm/v1/task/statistics1?buildingId=${localStorage.getItem('bid')}`)
                    const response1 = await GET(`${process.env.REACT_APP_URL}ppm/v1/task/monthly/statistics?buildingId=${localStorage.getItem('bid')}`)
                    const response2 = await GET(`${process.env.REACT_APP_URL}ppm/v1/task/monthly/chart?buildingId=${localStorage.getItem('bid')}`)
                    setChartData(response1?.data)
                    setStatisticsData(response?.data)
                    setMonthChart(response2?.data)
                }
            } catch (error) {
                console.log(error)
                errorToast(error?.response?.data?.message || error.message);

            }

        })();

    }, [])
    console.log({ monthChart })

    const gridComponents = [
        {
            xs: 2.4,
            title: "TOTAL PPMs - TODAY",
            component: <Box sx={{ height: CARD_HEIGHT }}>
                {
                    Object.keys(statisticsData).length > 0 ?
                        statisticsData?.totalPPMCount >= 0 ?
                            <StatsNumberCard data={statisticsData?.totalPPMCount} />
                            : <NoDataFound height="100%" />
                        : <Loader height="100%" />
                }
            </Box>
        },
        {
            xs: 2.4,
            title: "PENDING",
            component: <Box sx={{ height: CARD_HEIGHT }}>
                {Object.keys(statisticsData).length > 0 ?
                    statisticsData?.pending >= 0 ?
                        <StatsNumberCard data={statisticsData?.pending} />
                        : <NoDataFound height="100%" />
                    : <Loader height="100%" />}
            </Box>
        },
        {
            xs: 2.4,
            title: "IN PROGRESS",
            component: <Box sx={{ height: CARD_HEIGHT }}>
                {Object.keys(statisticsData).length > 0 ?
                    statisticsData?.inProgress >= 0 ?
                        <StatsNumberCard data={statisticsData?.inProgress} />
                        : <NoDataFound height="100%" />
                    : <Loader height="100%" />}
            </Box>
        },
        {
            xs: 2.4,
            title: "COMPLETED",
            component: <Box sx={{ height: CARD_HEIGHT }}>
                {Object.keys(statisticsData).length > 0 ?
                    statisticsData?.completed >= 0 ?
                        <StatsNumberCard data={statisticsData?.completed} />
                        : <NoDataFound height="100%" />
                    : <Loader height="100%" />}
            </Box>
        },
        {
            xs: 2.4,
            title: "INCOMPLETE",
            component: <Box sx={{ height: CARD_HEIGHT }}>
                {Object.keys(statisticsData).length > 0 ?
                    statisticsData?.incomplete >= 0 ?
                        <StatsNumberCard data={statisticsData?.incomplete} />
                        : <NoDataFound height="100%" />
                    : <Loader height="100%" />}
            </Box>
        },
        {
            xs: 2.4,
            title: "LAST 30 DAYS PPMs",
            component: <Box sx={{ height: CARD_HEIGHT }}>
                {Object.keys(chartData).length > 0 ?
                    chartData?.totalPPM >= 0 ?
                        <StatsNumberCard data={chartData?.totalPPM} />
                        : <NoDataFound height="100%" />
                    : <Loader height="100%" />}
            </Box>
        },
        {
            xs: 9.6,
            title: "MONTHLY HISTORICAL COMPLETION TRENDS",
            component: <Box sx={{
                height: CARD_HEIGHT,
                width: '100%', // Ensure it takes full width of the container
                paddingX: '1rem',
                paddingTop: '2rem',
                display: 'flex',
                justifyContent: 'center', // Center the chart horizontally
                alignItems: 'center', // Center the chart vertically
                // overflow: 'hidden'
            }}
            >
                {Object.keys(chartData).length > 0 ?
                    chartData?.data?.length > 0 ?
                        <>
                            <Box sx={{ width: '100%', height: '100%', overflow: "hidden" }}>
                                <HighChartPPMRaisedMonthwise
                                    data={monthChart}
                                />
                            </Box>

                        </>
                        : <NoDataFound height="100%" />
                    : <Loader height="100%" />
                }
            </Box>
        },
        {
            xs: 12,
            title: "30 DAYS TREND OF PPM COMPLETION",
            component: <Box sx={{ height: CARD_HEIGHT, paddingX: "1rem", paddingTop: "2rem" }}>
                {Object.keys(chartData).length > 0 ?
                    chartData?.data?.length > 0 ?
                        <HighchartsPPM30DaysTrend
                            data={chartData}
                        />
                        : <NoDataFound height="100%" />
                    : <Loader height="100%" />
                }
            </Box>
        },
    ]
    return (
        <Box ml={2} mr={2}>
            <Grid container spacing={3}>
                {gridComponents?.map(({ xs, component, title }, index) => {
                    return <Grid item xs={xs} >
                        <CustomComponentContainer>
                            <CustomTypography sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontWeight: 700,
                                paddingY: ".4rem",
                                borderBottom: "1px solid lightgrey"
                            }} >{title}</CustomTypography >
                            {component}
                        </CustomComponentContainer>
                    </Grid>
                })}
            </Grid>
        </Box>
    )
}

export default PPMStatistics