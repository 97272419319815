import React, { memo, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ArrowUpwardOutlined } from '@mui/icons-material';
import { PUT } from '../../../../helpers/http';
import CustomTextArea from '../CustomTextArea';
import { errorToast } from '../../../../../admin/helpers/apiToast';
import { ChatContext } from '../../context/ChatContext';
import VoiceCommand from '../voiceCommand/VoiceCommand';
import CustomTypography from '../../../../components/texts/CustomTypography';

const TextFieldContainer = ({ sx, className }) => {
    const { chatInfo, chatDispatch } = useContext(ChatContext);
    const { interfaceChat, newChatId, populatedQuestion } = chatInfo;

    const [question, setQuestion] = useState('');

    useEffect(() => {
        if (populatedQuestion) setQuestion(populatedQuestion)
    }, [populatedQuestion])

    const getTextResponse = async ({ question, newChatId }) => {
        try {
            const response = await PUT(
                `${process.env.REACT_APP_BASE_APP_URL}maverick/chat/${newChatId ? newChatId : interfaceChat?._id}`,
                { content: question, role: 'user' },
                'Chat'
            );

            if (response?.status === 200) {
                chatDispatch({ type: 'ADD_INTERFACE_CHAT', payload: response?.data?.response });
            }
        } catch (error) {
            if (error?.response?.data?.detail) {
                error?.response?.data?.detail?.forEach((el) => errorToast(el.msg));
            } else {
                error?.message && errorToast(error?.message);
            }
        } finally {
            setQuestion('');
        }
    };


    const handleSubmit = ({ e, question }) => {
        e.preventDefault();
        if (question) {
            getTextResponse({ question, newChatId });
        }
    };

    return (
        <>
            <Box
                sx={{ width: '56.25rem', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}
            >
                <Box
                    sx={{
                        backgroundColor: '#f5f3f4',
                        borderRadius: '2rem',
                        minHeight: '53px',
                        maxHeight: '20rem',
                        width: '790px',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}
                >
                    <CustomTextArea interfaceChat={interfaceChat} handleSubmit={handleSubmit} question={question}
                        setQuestion={setQuestion} getTextResponse={getTextResponse}
                    />
                    <Box sx={{
                        border: '1px solid #b5b5b5', borderRadius: '100%', mr: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center',
                        padding: '8px', backgroundColor: '#b5b5b5', cursor: 'pointer'
                    }}
                        onClick={(e) => { handleSubmit({ e, question }) }}
                    >
                        <ArrowUpwardOutlined sx={{ fontSize: '1rem', color: '#fff', cursor: 'pointer' }} />
                    </Box>
                </Box>
                <VoiceCommand />
            </Box>
            <CustomTypography sx={{ color: '#9f9f9f !important', padding: '1rem 0' }}>nhance maverick v 1.0 ©  2024 - for internal testing only</CustomTypography>
        </>
    );
}

export default memo(TextFieldContainer);