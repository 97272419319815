import HighchartsReact from "highcharts-react-official"
import { ThemeColors } from "../../../../hooks/ThemeColors"
import { useSelector } from "react-redux"
import React from 'react';
import Highcharts from 'highcharts';

const HighChartPPMRaisedMonthwise = ({ title, data }) => {
    const { textColor, bgColor } = ThemeColors()
    const themeMode = useSelector(state => state.theme.darkTheme)
    const options = {
        chart: {
            type: 'column',
            height: '230px',
            backgroundColor: bgColor,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
        },
        title: {
            text: ''
        },
        legend: {
            enabled: true,
            itemStyle: {
                color: textColor(),
            },
        },
        tooltip: {
            shared: true,
            headerFormat: '<b>{point.key}</b><br/>',
            pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y}</b><br/>',
        },
        xAxis: {
            categories: data?.xAxis,
            title: {
                text: null
            },
            lineColor: textColor(),
            labels: {
                enabled: true,
                autoRotationLimit: 0,
                style: {
                    fontSize: "12px",
                    color: textColor(),
                },
            },
        },
        yAxis: {
            allowDecimals: false,
            title: {
                text: ''
            },
            gridLineColor: 'transparent',
            lineColor: textColor(),
            labels: {
                enabled: true,
                autoRotationLimit: 0,
                style: {
                    fontSize: "12px",
                    color: textColor(),
                },
            },
        },
        series: [
            {
                name: data?.data[0]?.status,
                data: data?.data[0]?.data,  // Example data for total PPM
                color: '#0073e6',
                // color: '#6a0dad'
                pointPlacement: 0.05,
            },
            {
                name: data?.data[1]?.status,
                data: data?.data[1]?.data,  // Example data for completed PPM
                color: '#00bfff',
                pointPlacement: -0.1,
            }
        ],
        plotOptions: {
            column: {
                pointWidth: 25
            },
        },
        credits: {
            enabled: false  // This disables the "highcharts.com" watermark
        }
    };

    return <>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={{ style: { height: '100%', width: '100%' } }} // Responsive container
        />

    </>
}

export default HighChartPPMRaisedMonthwise