import { Checkbox, FormControl, FormControlLabel, useTheme } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

const CheckBox = (props) => {
    const { checkboxLabel, checked, onChange, sx, name, size } = props

    const theme = useTheme()
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'

    return (
        <FormControl sx={{ ...sx }}>
            <FormControlLabel
                control={
                    <Checkbox checked={checked} onChange={onChange} name={name} style={{ color }} size={size} />
                }
                label={checkboxLabel}
            />
        </FormControl>

    )
}

export default CheckBox