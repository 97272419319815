import { useSelector } from "react-redux";
import { ThemeColors } from "../../../../hooks/ThemeColors";
import { errorToast, successToast } from "../../../../helpers/apiToast";
import { DELETE, FILEPOST, PUT } from "../../../../helpers/http";
import { useState } from "react";
import AddMediaCard from "../../AddMediaCard";
import { Avatar, Box, Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import ReturnMultiTypeMedia from "../../../faultReports/newFaultReports/components/returnMultiTypeMedia";
import { ConstructionOutlined, Delete } from "@mui/icons-material";
import Loading from "../../../../components/loading/Loading";
import FormInput from "../../../../components/formInputs/FormInput";
import CommonStyles from "../../../../styles/CommonStyles";
import { event } from "jquery";




const AddMediasForm = ({ id, handleCloseModal, mediaTrigger }) => {
    const { themeMode } = ThemeColors();
    const fileReadUrl = useSelector((state) => state?.configurations?.frfileReadUrl)


    const [images, setImages] = useState([])
    const [uploadingMedia, setUploadingMedia] = useState(false)

    const handleImagesFormSubmit = async (event) => {
        // event.preventDefault()
        try {
            const updatedFault = await PUT(`http://localhost:8030/v1/task/media/66ff872a74769d29cee8d12b`,
                { newMediaArr: images },
                "Update Fault for Media")
            // const updatedFault = await PUT(`http://localhost:8004/v2/fault/media/${id}`, addImagesForm, "Update Fault for Media")
            console.log({ updatedFault })
            if (updatedFault?.status === 200) {
                handleCloseModal(false)
                mediaTrigger()
            }

        } catch (error) {
            console.log(error)
        }

    }

    const handleImage = async ({ event, faultId }) => {
        try {
            setUploadingMedia(true)
            const maxLimit = 5
            //check maxMedia count
            if (images?.length < maxLimit) {
                const fileData = event.target.files[0]
                const formData = new FormData()
                formData.append('file', fileData)
                // const response = await FILEPOST(`http://localhost:8004/v2/media/upload`, formData, 'File Upload : Add Image', true)
                const response = await FILEPOST(`http://localhost:8030/v1/media/upload`, formData, 'File Upload : Add Image', true)
                console.log({ response })
                successToast("Image Uploaded Successfully")
                if (response?.status === 200) {
                    setImages([...images, response.data])
                    handleImagesFormSubmit()
                    setUploadingMedia(false)
                }
            } else {
                errorToast(`You can upload only ${maxLimit} medias`)
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
            setUploadingMedia(false)
            console.log(error)
        }
    }
    console.log({ newMediaArray: images })


    // const makeItPublic = (e) => {
    //     if (e.target.checked) setAddImagesForm({ ...addImagesForm, visibility: "public" })
    //     else setAddImagesForm({ ...addImagesForm, visibility: "internal" })
    // }

    const handleDeleteMediaFun = async (event, media) => {
        try {
            console.log({ media })
            // setAddImagesForm({ ...addImagesForm, newMediaArr: addImagesForm?.newMediaArr?.filter(mda => mda?.uploadId !== media?.uploadId) })
            // const response = await DELETE(`http://localhost:8004/v2/media?uploadId=${ele.uploadId}`)
            const response = await DELETE(`http://localhost:8030/v1/media?uploadId=${media?.uploadId}`, "Delete Media")
            console.log({ response })
            if (response) {
                const imageResult = images.filter(img => {
                    return img.uploadId !== media.uploadId
                })
                setImages(imageResult)
                handleImagesFormSubmit()
                successToast("Media Deleted Successfully")
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
            console.log(error)
        }
    }

    return (
        <Box
            sx={{ display: "flex", flexDirection: "row", gap: 2, width: "100%" }}
        >
            <Grid container width="100%">
                <Grid item sm={6} md={4} lg={3} xl={2}>
                    <AddMediaCard
                        sx={{ height: "8rem", width: "10rem" }}
                        type="add"
                        handleAddImage={handleImage}
                        id={id}
                    />
                </Grid>
                {
                    images?.map(media => {
                        return <Grid item sm={6} md={4} lg={3} xl={2} sx={{ position: "relative" }}>
                            <ReturnMultiTypeMedia fitImage="fill" fileReadUrl={fileReadUrl} media={media} height="8rem" width="10rem"
                                sx={{ border: '1px solid lightgray', borderRadius: "1rem" }} />
                            <Avatar sx={{
                                position: 'absolute',
                                height: "2rem",
                                width: "2rem",
                                backgroundColor: "black",
                                right: "0.2rem",
                                top: "1.5rem"
                            }}>
                                <Delete
                                    fontSize="small"
                                    onClick={() => handleDeleteMediaFun(media)}
                                    sx={{ cursor: 'pointer' }} />
                            </Avatar>
                        </Grid>
                    })
                }
                {uploadingMedia && <Grid item sm={6} md={4} lg={3} xl={2}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            borderRadius: '1rem',
                            border: '1px solid lightgray',
                            backgroundColor: themeMode ? '#5a5a5a' : '#fff',
                            height: "100%"
                        }}>
                        <Loading height="100%" />
                    </Box>
                </Grid>}
            </Grid>
        </Box>
    )
}

export default AddMediasForm