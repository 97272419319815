import { errorToast, successToast } from "../../../../../helpers/apiToast"
import { DELETE, FILEPOST, GET, POST, PUT } from "../../../../../helpers/http"

const useApiCalls = () => {
    // ${process.env.REACT_APP_BASE_URL}fr

    const getAllCost = async ({ faultId, setTableData }) => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/cost/list?faultId=${faultId}`, 'Get Costing')
            if (response.status === 200) {
                setTableData(response?.data);
            }
        } catch (err) {
            console.log(err)
        }
    };

    const getAllBuildings = async ({ setBuildingOptions }) => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/building/list`, 'Get Buildings')
            if (response.status === 200) {
                setBuildingOptions(response?.data)
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
        }
    }

    const getAllCategory = async ({ setCategoryOptions }) => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/category/list`, 'Get Category')
            if (response.status === 200) {
                setCategoryOptions(response?.data)
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
        }
    }

    const getAllFloors = async ({ setFloorOptions }) => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/floor/list`, 'Get Floors')
            if (response.status === 200) {
                setFloorOptions(response?.data)
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
        }
    }

    const getAllArea = async ({ setAreaOptions }) => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/area/list`, 'Get Area')
            if (response.status === 200) {
                setAreaOptions(response?.data)
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
        }
    }

    const postMedia = async ({ media }) => {
        if (media && media.length > 0) {
            try {
                // Map each file to a promise that performs the upload
                const uploadPromises = media.map(async file => await FILEPOST(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/media/upload`, { file }, 'File Upload : Add Media'));

                // Wait for all uploads to complete
                const responses = await Promise.allSettled(uploadPromises);
                const result = responses?.map(({ value }) => value)
                return result;

            } catch (error) {
                // Handle any errors that occurred during the upload process
                errorToast(error?.response?.data?.message || error?.message);
            }
        }
    }

    const createCost = async ({ data }) => {
        try {
            const response = await POST(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/cost`, data, 'Add Costing');
            if (response.status !== 201) {
                errorToast('Something went wrong.');
            } else {
                successToast('Costing Added Successfully');
                return response;
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message);
        }
    };

    const updateCost = async ({ data }) => {
        try {
            const response = await PUT(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/cost`, data, 'Update Costing');
            if (response.status !== 200) {
                errorToast('Something went wrong.');
            } else {
                successToast('Costing Updated Successfully');
                return response;
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message);
        }
    };

    const getAllTaskStatus = async ({ id, setTaskOptions }) => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/fault/update-options?id=${id}`, 'Get Task Status');
            if (response.status === 200) {
                setTaskOptions(response?.data?.availableTaskStatusOptions);
                return response?.data;
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message);
        }
    };

    const updateTaskStatus = async ({ id, taskStatus, fault, setFault }) => {
        try {
            const response = await PUT(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/fault/task-status`, { id, taskStatus }, 'Update Task Status');
            if (response.status === 200) {
                setFault({ ...fault, taskStatus: response?.data?.taskStatus });
                successToast('Task Status Updated Successfully');
                return response
            }
            return response?.data
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message);
            return error
        }
    }

    const updateFaultDetails = async ({ id, setFault, payload }) => {
        try {
            const response = await PUT(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/fault?id=${id}`, payload, 'Update Fault Details');
            if (response.status === 200) {
                setFault(response?.data);
                successToast('Fault Updated Successfully');
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message);
        }
    };

    const deleteSingleCost = async ({ costId, faultId }) => {
        try {
            const response = await DELETE(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/cost?costId=${costId}`, 'Delete Cost');
            if (response.status === 200) {
                successToast('Cost Deleted Successfully');
                return response;
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message);
        }
    };

    const categoryListOnUserChange = async ({ userId, userType }) => {
        try {
            const response = await GET(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/fault/category/for-user?userId=${userId}&userType=${userType}`, 'Get Category')
            if (response.status === 200) {
                return response?.data;
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
        }
    };

    return {
        getAllArea, getAllFloors, getAllBuildings, getAllCategory, postMedia, getAllCost, createCost, updateCost, getAllTaskStatus,
        updateFaultDetails, updateTaskStatus, deleteSingleCost, categoryListOnUserChange
    };

}

export default useApiCalls