import React, { useContext } from 'react'
import ImageRenderer from '../../../../components/image/ImageRenderer'
import { Box, Grid } from '@mui/material'
import CustomCard from '../CustomCard'
import CustomTypography from '../../../../components/texts/CustomTypography'
import { useSelector } from 'react-redux'
import { ChatContext } from '../../context/ChatContext'
import { POST, PUT } from '../../../../helpers/http'
import { errorToast } from '../../../../helpers/apiToast'
import { StairsOutlined, SchoolOutlined, FreeBreakfastOutlined, MeetingRoomOutlined } from '@mui/icons-material';

const NoChats = ({ logo }) => {
    const userName = useSelector(state => state.user.fullName);
    const { chatInfo, chatDispatch } = useContext(ChatContext);
    const { interfaceChat } = chatInfo;


    const defaultQuestions = [
        { questions: 'How many floors are there?', color: '#AACDBE', icon: <StairsOutlined sx={{ color: '#AACDBE' }} /> },
        { questions: 'Learn about Nhance Agent?', color: '#8A79AF', icon: <SchoolOutlined sx={{ color: '#8A79AF' }} /> },
        { questions: 'Where is the cafeteria?', color: '#29C6CD', icon: <FreeBreakfastOutlined sx={{ color: '#29C6CD' }} /> },
        { questions: 'How many meeting rooms are there?', color: '#F1C27B', icon: <MeetingRoomOutlined sx={{ color: '#F1C27B' }} /> },
    ]

    const getTextResponse = async ({ question, newChatId }) => {
        try {
            const response = await PUT(
                `${process.env.REACT_APP_BASE_APP_URL}maverick/chat/${newChatId ? newChatId : interfaceChat?._id}`,
                { content: question, role: 'user' },
                'Chat'
            );

            if (response?.status === 200) {
                chatDispatch({ type: 'ADD_INTERFACE_CHAT', payload: response?.data?.response });
            }
        } catch (error) {
            if (error?.response?.data?.detail) {
                error?.response?.data?.detail?.forEach((el) => errorToast(el.msg));
            } else {
                error?.message && errorToast(error?.message);
            }
        }
    };

    const handleNewChat = async ({ question, updatedConversation }) => {
        try {
            const response = await POST(`${process.env.REACT_APP_BASE_APP_URL}maverick/chat`, { building_id: localStorage.getItem('bid') }, 'New Chat')
            if (response?.status === 200) {
                chatDispatch({ type: 'SET_NEW_CHAT_ID', payload: response?.data?.chat_id });
                getTextResponse({ question, updatedConversation, newChatId: response?.data?.chat_id });
            }
        } catch (error) {
            if (error?.response?.data?.detail) {
                error?.response?.data?.detail?.forEach((el) => errorToast(el.msg));
            } else {
                error?.message && errorToast(error?.message);
            }
        }
    }

    const handleQuestionClick = ({ e }) => {
        const question = e.target.textContent;

        let updatedConversation;
        updatedConversation = [{ content: question, role: 'user' }, { content: 'loading', role: 'assistant' }];
        if (Object.keys(interfaceChat)?.length === 0) {
            chatDispatch({ type: 'NEW_INTERFACE_CHAT', payload: updatedConversation })
            handleNewChat({ question, updatedConversation });
            return;
        }
    }

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <ImageRenderer src={logo} alt='logo' className='d50' />
            <Grid container sx={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '60%', maxWidth: '60%',
                mt: '1rem'
            }}>
                <Grid item xs={12} sx={{ marginBottom: "0.5rem", textAlign: 'center' }}>
                    <CustomTypography variant="h3" sx={{ lineHeight: 1.1 }} className='gradient-text'>
                        Hi there, {userName.split(' ')[0]}
                    </CustomTypography>
                    <CustomTypography variant="h3" sx={{ lineHeight: 1.1 }} className='gradient-text'>
                        What would you like to know?
                    </CustomTypography>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: "1rem", textAlign: 'center' }}>
                    <CustomTypography variant="h5" sx={{
                        lineHeight: 1.1,
                        fontSize: '1.38rem',
                        color: '#9d9d9d !important'
                    }}>
                        Use one of the most common prompts
                    </CustomTypography>
                    <CustomTypography variant="h5" sx={{
                        lineHeight: 1.1,
                        fontSize: '1.38rem',
                        color: '#9d9d9d !important'
                    }}>
                        below or use your own to begin
                    </CustomTypography>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem', mt: '1rem' }}>
                {defaultQuestions?.map(({ questions, icon }) =>
                    <CustomCard
                        className='question-cards'
                        onClick={(e) => { handleQuestionClick({ e }) }}
                        sx={{ padding: '0.8rem', height: '100%', width: '12rem', boxShadow: '0 0 2px 0 rgba(0,0,0,.05), 0 4px 6px 0 rgba(0,0,0,.02)' }}>
                        {icon}
                        <CustomTypography sx={{ fontSize: '18px', color: '#606060 !important' }}>{questions}</CustomTypography>
                    </CustomCard>
                )}
            </Box>
        </Box >
    )
}

export default NoChats