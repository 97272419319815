import { Box } from "@mui/material"
import Secondary from "../../../components/secondary/Secondary"
import TabContainer from "../../../components/tab/Tab"
import Details from "./Details"
import Medias from "./Medias"
import Costing from "./Costing"
import History from "./History"
import { useEffect, useState } from "react"
import { GET } from "../../../helpers/http"
import { useParams } from "react-router-dom"


const TaskViewContainer = () => {
    const { id } = useParams()
    const [taskData, setTaskData] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const fetchSingleTask = async (id) => {
        try {
            setIsLoading(true)
            const response = await GET(`${process.env.REACT_APP_URL}ppm/v1/task/${id}`, 'Get Single PPM Task')
            setTaskData(response?.data)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSingleTask(id)
    }, [])

    return (
        <Box width="100%">
            <Secondary
                title={"Task Details"}
                returnPage={"Dashboard"}
                returnRoute={"/"}
                children={
                    <TabContainer
                        className='commonComponentSpacing'
                        tabList={["Details", "Media", 'Costing', 'History']}
                        tabPanels={[
                            <Details isLoading={isLoading} task={taskData} fetchSingleTask={fetchSingleTask} />,
                            <Medias />,
                            <Costing task={taskData} />,
                            <History />
                        ]}
                    />
                }
            />
        </Box>
    )
}

export default TaskViewContainer