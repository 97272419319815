import { memo } from 'react'
import { Grid } from '@mui/material'
import Footer from '../footer/Footer'
import SideBar from '../sidebar/SideBar'
import PrivateRoute from '../../authentication/PrivateRoute'
import { componentRoutes } from './data/componentRoutes'

function AppContainer() {
    return (
        <div className='flex'>
            <SideBar />
            <Grid container className='main-content-container'>
                <Grid item xs={12} >
                    {componentRoutes.map(({ path, component }, index) => <PrivateRoute key={index} path={path} component={component} />)}
                </Grid>
                <Grid item xs={12}>
                    <Footer />
                </Grid>
            </Grid>
        </div>

    )
}

export default memo(AppContainer)