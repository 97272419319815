import { useEffect, useState } from 'react';
import ProfilePage from './profile-page';
import { errorToast } from '../../../helpers/apiToast';
import { GET } from '../../../helpers/http';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/loading/Loader';
import { fetchBuildings, fetchDepartments, fetchUserList } from '../helpers';

const EditPage = () => {
    const { id } = useParams();

    const [userData, setUserData] = useState({});
    const [enabledFields, setEnabledFields] = useState({});
    const [buildingsList, setBuildingsList] = useState([]);
    const [departmentsList, setDepartmentsList] = useState([]);
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        fetchBuildings({ setBuildings: setBuildingsList });
        fetchDepartments({ setDepartments: setDepartmentsList });
        fetchUserList({ setUserList });
    }, []);

    useEffect(() => {
        const fetchUserData = async (id) => {
            try {
                const result = await GET(`${process.env.REACT_APP_BASE_URL}um/v1/user/${id}`, 'Get User');
                if (result?.status === 200) {
                    setUserData(result?.data?.userDetails || {});
                    setEnabledFields(result?.data?.editableFields);
                }
            } catch (error) {
                errorToast(error?.response?.data?.message || error?.message)
            }
        };
        fetchUserData(id);
    }, [id]);

    return Object.keys(userData).length > 0 ? <ProfilePage
        userData={userData}
        enabledFields={enabledFields}
        buildingsList={buildingsList}
        departmentsList={departmentsList}
        userList={userList}
        userId={id}
    /> : <Loader height='90vh' width='100%' />
};

export default EditPage;
