import { Box, Typography } from "@mui/material"
import CustomComponentContainer from "../../../components/container/CustomComponentContainer"
import CustomTypography from "../../../components/texts/CustomTypography"
import DetailsInfo from "../components/taskView/DetailsInfo"
import TaskProgress from "../components/taskView/TaskProgress"
import Loader from "../../../components/loading/Loader"
import { useState } from "react"


const Details = ({ task, isLoading,fetchSingleTask }) => {
    const [readmore, setReadmore] = useState(false)

    const capitalizeFirst = (word) => {
        if (!word) { // Check if the word is empty
            return "";
        }
        return word.toLowerCase().charAt(0).toUpperCase() + word.slice(1);
    }

    return (
        <CustomComponentContainer
            className='commonComponentSpacing'
            sx={{
                minHeight: '80vh',
                display: "flex",
                flexDirection: "row",
            }}>

            {!isLoading ? <Box sx={{ display: "flex", flexDirection: "column", width: "100%", padding: "2.5rem" }}>
                <Box sx={{ display: 'flex', flexDirection: "row", height: "25vh" }}>
                    <Box sx={{
                        display: "flex", flexDirection: "column", width: "50%",
                        //  borderRight: "2px gainsboro solid" 
                    }}>
                        <CustomTypography
                            className="padleft15"
                            sx={{ fontSize: "26px", fontWeight: "800" }} >
                            {task?.title?.slice(0, 50)}
                        </CustomTypography >
                        <div className="txtWrap pad16 overflowAuto">
                            {/* {task?.description?.length > 400 ? `${task?.description?.slice(0, 480)}...` : task?.description} */}
                            {!readmore ?
                                <Typography sx={{ textAlign: "justify" }}>
                                    {<Typography className="color-darkgray" sx={{ fontSize: "16px" }} >
                                        {task?.description?.slice(0, 500) + " "}
                                        {(task?.description?.length > 500) &&
                                            <Box
                                                className="cursorPtr txtTransformLow fontweight600" component="span"
                                                onClick={() => setReadmore(!readmore)} size="small">
                                                <i>...Read more</i>
                                            </Box>}
                                    </Typography>}
                                </Typography>
                                :
                                <Typography className="color-darkgray" sx={{ textAlign: "justify" }} >
                                    {<Box
                                        sx={{ fontSize: "16px" }}
                                    >{task?.description} {readmore &&
                                        <i
                                            className="cursorPtr fontweight600 txtTransformLow"
                                            onClick={() => setReadmore(!readmore)}
                                        > ...read less
                                        </i>}
                                    </Box>}
                                </Typography>
                            }
                        </div>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", width: "50%", alignItems: "center" }}>
                        <TaskProgress capitalizeFirst={capitalizeFirst} status={task?.taskStatus} />
                    </Box>
                </Box>
                <DetailsInfo fetchSingleTask={fetchSingleTask} capitalizeFirst={capitalizeFirst} task={task} />
            </Box>
                :
                <Loader height='70vh' sx={{ width: "100%" }} />
            }
        </CustomComponentContainer>
    )
}

export default Details