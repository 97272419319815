import { styled, Switch } from "@mui/material";

const PPMSwitch = ({ checked, onChange }) => {
    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 40,
        height: 22,
        // marginLeft: 10,
        marginRight: 10,
        padding: 0,
        borderRadius: 12,
        display: 'flex',
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
                transform: 'translateX(18px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: '#ef5350',
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#ef5350',
                    }),
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 18,
            height: 18,
            borderRadius: 8,
            color: "white",
            transition: theme.transitions.create(['width'], {
                duration: 400,
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: 'rgba(0,0,0,.25)',
            boxSizing: 'border-box',
            ...theme.applyStyles('dark', {
                backgroundColor: 'rgba(255,255,255,.35)',
            }),
        },
    }));

    return <AntSwitch checked={checked} onChange={onChange} />
}

export default PPMSwitch
