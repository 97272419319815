import {  Box, Button, Card, CardContent, Chip, Grid, IconButton, Switch, Tooltip } from '@mui/material'
import React, {  useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import CommonStyles from '../../../styles/CommonStyles'
import { GET, PUT } from '../../../helpers/http'
import Loading from '../../../components/loading/Loading'
import SensorsTable from '../../../features/energy/sensorsGroup/SensorsTable'
import SidebarModal from '../../../components/modal/SidebarModal'
import SensorForm from '../../../features/energy/sensorsGroup/SensorForm'
import SensorGroupsStyle from '../../../styles/SensorGroupsStyle'
import CustomTypography from '../../../components/texts/CustomTypography'
import { errorToast, successToast } from '../../../helpers/apiToast'
import swal from "sweetalert"
import SensorsIcon from '@mui/icons-material/Sensors';

const SensorGroups = (props) => {
    const themeMode = useSelector(state => state.theme.darkTheme)
    const bgcolor = themeMode ? '#282a2e' : '#fff'
    const buildingId = useSelector(state => state?.buildings?.selectedBuilding)

    const [isLoaded, setIsLoaded] = useState(false)
    const [formStatus, setFormStatus] = useState("")
    const [modalOpen, setModalOpen] = useState(false)
    const [formModalOpen, setFormModalOpen] = useState(false)
    const [sensorGroups, setSensorGroups] = useState([])
    const [selectedSensorsGroup, setSelectedSensorsGroup] = useState({})

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    const handleCloseFormModal = () => {
        setFormModalOpen(false)
    }

    const getSensorGroups = async ({ buildingId }) => {
        try {
            setIsLoaded(false);
            const endPoint = `${process.env.REACT_APP_BASE_URL}twin/v1/energygroups/?bid=${buildingId}`;
            const response = await GET(endPoint, "Get Energy groups")
            setSensorGroups(response?.data)
            setIsLoaded(true);
        } catch (error) {
            console.log(error)
            if (error?.response) {
                errorToast(error?.response?.data?.message);
            } else {
                errorToast(error?.message);
            }
        }
    }
    
    const renderSensorGroups = () => {
        getSensorGroups({ buildingId })
    }

    const handleSwitchButton = async({status, id}) => {
        try {
            const endPoint = `${process.env.REACT_APP_BASE_URL}twin/v1/energygroups/${id}?bid=${buildingId}`;
            const response = await PUT(endPoint, { status: status === "active" ? "inactive" : "active" }, "Change Energy group status")
            if (response?.status === 200) {
                setSensorGroups(sensorGroups?.map(sensor => {
                    if (sensor?._id === response?.data?._id) return { ...sensor, status: response?.data?.status }
                    else return sensor
                }))
                successToast("Sensor Group status changed Successfully.")
            }
        } catch (error) {
            console.log(error)
            if (error?.response) {
                errorToast(error?.response?.data?.message);
            } else {
                errorToast(error?.message);
            }
        }
    }

    const handleDeleteGroup =  ({ id })=>{
        try {
            swal({
                title: "Are you sure?",
                text: `You will be deleting sensors group.`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then(async(success) => {
                if (success) {     
                    const endPoint = `${process.env.REACT_APP_BASE_URL}twin/v1/energygroups/${id}?bid=${buildingId}`;
                    const response = await PUT(endPoint, { isDeleted: true}, "Change Energy group status")
                    if (response?.status === 200) {
                        setSensorGroups(sensorGroups?.filter(sensor => sensor?._id !== response?.data?._id))
                        successToast("Sensor Group delete Successfully.")
                    }}
            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error)
            if (error?.response) {
                errorToast(error?.response?.data?.message);
            } else {
                errorToast(error?.message);
            }
        }
    }

    useEffect(() => {
        if (buildingId && !formModalOpen) {
            getSensorGroups({buildingId})
        }
    }, [buildingId])

    return (
        <Box
            sx={SensorGroupsStyle.boxContainer}
            className='minHeight90vh'>
            <CustomTypography
                sx={SensorGroupsStyle.headingTypo}>
                Energy Sensor Groups
            </CustomTypography>
            <Box
                sx={{...SensorGroupsStyle.paperBoxContainer,bgcolor}}
                className='theme-cards-boxShadow bdrRadius4px'>
                <Grid container direction={'row-reverse'} spacing={1} >
                    <Grid item xs={1.5} border={'solid transparent'} > 
                        <Button
                            onClick={() => {
                                setFormModalOpen(true)
                                setFormStatus("add")
                            }}
                            className='btnFontSize'
                            sx={SensorGroupsStyle.addButton}>
                            Add Sensor group
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            {isLoaded ? (
                    sensorGroups?.length > 0 ? (
                        <Grid border={'solid transparent'} container spacing={2} width={'100%'}>
                        {sensorGroups?.map((group) => {
                            const sensorsCount = group?.sensors?.length 
                            const subSensorsCount = group?.subGroups?.reduce((ini, sub) => {
                                ini = ini + sub?.sensors?.length
                                return ini
                            },0)
                            return (
                                <Grid key={group?._id} item sm={6} md={6} xl={4}>
                                        <Card className='theme-cards-boxShadow bdrRadius4px overflowAuto height24vh'
                                        sx={{ ...CommonStyles.themeCardsBoxShadow, ...CommonStyles.themeBorder, backgroundColor: group?.status === "active" ? bgcolor : "#E8E8E8"  }}>
                                            <CardContent className="flex flexDirectionCol flexJustifyBetween gap8">
                                                <Box className="flex flexDirectionRow flexJustifyBetween gap8"> 
                                                        <CustomTypography variant="h6" sx={{ fontWeight: 'bold' }}>
                                                        {group?.name}
                                                        </CustomTypography>
                                                    <Box sx={{ display: 'flex', columnGap: 2}}>
                                                    <Tooltip title={`${sensorsCount + subSensorsCount} Sensors`}>
                                                            <Chip label={
                                                                <Box className="flex flexDirectionRow flexAlignItemsCenter gap8">
                                                                <SensorsIcon color='primary' fontSize='small' />
                                                                <b className='font15'>{sensorsCount + subSensorsCount}</b>
                                                                </Box>
                                                            } />
                                                                
                                                        </Tooltip>
                                                        <Tooltip title="Change Status">
                                                            <Switch
                                                                checked={group?.status === "active"}
                                                                onChange={() => handleSwitchButton({ status: group?.status, id: group?._id })}
                                                                sx={{ ...SensorGroupsStyle.switchButton }}
                                                            />
                                                        </Tooltip>
                                                        <Tooltip title="Edit Group">
                                                            <IconButton size='small'
                                                                onClick={() => {
                                                                    setFormModalOpen(true)
                                                                    setFormStatus("edit")
                                                                    setSelectedSensorsGroup(group)
                                                                }}
                                                            >
                                                                <EditOutlined color='primary' fontSize='small' />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Remove Group">
                                                            <IconButton size='small'
                                                                onClick={() => handleDeleteGroup({ id: group?._id })}
                                                            >
                                                                <DeleteOutlined color='error' fontSize='small' />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                </Box>
                                                <CustomTypography>
                                                    <b>Description</b>: {group?.description?.length > 35 ?
                                                        `${group?.description?.slice(0, 35)}...` :
                                                        `${group?.description}`}
                                                </CustomTypography>
                                                <CustomTypography variant="body2">
                                                    <b>Created By</b>: {group?.createdBy} ({group?.createdOn})
                                                </CustomTypography>
                                                <CustomTypography variant="body2">
                                                    <b>Last Updated By</b>: {group?.lastUpdatedBy} ({group?.lastUpdatedOn})
                                                </CustomTypography>
                                                <Button
                                                        sx={{
                                                            ...CommonStyles.actionButton,
                                                            width: "10vw",
                                                            height: "4vh",
                                                            fontSize: "15px",
                                                            marginTop:"0.75rem"
                                                        }}
                                                        onClick={() => {
                                                            setModalOpen(true)
                                                            setSelectedSensorsGroup(group)
                                                        }}
                                                    >
                                                        Details
                                                </Button>
                                            </CardContent>
                                        </Card>
                                        </Grid>
                                )})
                            }
                        </Grid>
                    ) : (
                        <Box sx={SensorGroupsStyle.loaderBox} >
                            <CustomTypography>No Sensor Groups</CustomTypography>
                        </Box>
                    )
                ) : (
                    <Box sx={SensorGroupsStyle.loaderBox} >
                        <Loading />
                    </Box>
                )
            }
            <SidebarModal
                sx={{ minWidth: "35vw" }}
                title={selectedSensorsGroup?.name}
                children={<SensorsTable selectedSensorGroup={selectedSensorsGroup} />}
                openModal={modalOpen}
                handleClose ={handleCloseModal}
            />
            <SidebarModal
                sx={{ minWidth: "40vw" }}
                openModal={formModalOpen}
                handleClose={handleCloseFormModal}
                title={`${formStatus[0]?.toUpperCase()}${formStatus?.slice(1)?.toLowerCase()} Sensor Group`}
                children={<SensorForm
                        data={selectedSensorsGroup}
                        type={formStatus}
                        handleCloseFormModal={handleCloseFormModal}
                        renderSensorGroups={renderSensorGroups}
                        />}
                direction="left"
            />
        </Box>
    )
}

export default SensorGroups