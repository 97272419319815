import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";
import { errorHandler } from "../../../components/facilityManager/helpers/http";

const fetchAssetManagementSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        fetchAssetsStats: builder.query({
            query: (buildingId) => ({
                url: `am/v1/s1/data?bid=${buildingId}`,
                method: 'GET',
                credentials: "include",
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Asset Dashboard Widget Stats')
            },
        })
    }),
});

export const { useFetchAssetsStatsQuery } = fetchAssetManagementSlice

const initialState = {
    isLoaded: false,
    data: {}
}

const assetManagementSlice = createSlice({
    name: 'assetManagement',
    initialState,
    reducers: {
        resetAssetManagementStats: (state, action) => {
            return state = initialState
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => action?.meta?.arg?.endpointName === 'fetchAssetsStats' && action?.meta?.requestStatus === 'fulfilled',
            (state, action) => {
                const chartData = action.payload?.categoryAssetsWithPercentage?.map(ele => {
                    return {
                        name: `${ele?.category?.name || 'Uncategorized'} - ${ele?.count} (${ele?.percentage}%)`,
                        y: ele?.percentage,
                        count: ele?.count
                    }
                })
                state.isLoaded = true
                state.data = { ...action.payload, chartData }
            }
        )
    },
})

export const { resetAssetManagementStats } = assetManagementSlice.actions

export default assetManagementSlice.reducer
