import { Box, Grid } from '@mui/material';
import React, { Fragment, memo, useContext, useEffect, useRef } from 'react';
import CommonStyles from '../../styles/CommonStyles';
import TextFieldContainer from './components/textField/TextFieldContainer';
import '../../pages/secondary/chat/chat.css'
import Question from './components/chats/Question';
import Reponse from './components/chats/Reponse';
import NoChats from './components/chats/NoChats';
import GenerativeChatMenu from './components/menu/Menu';
import { ChatContext } from './context/ChatContext';
import ImageRenderer from '../../components/image/ImageRenderer';
import { chatLogo, newConversationLogo } from '../../../../data/imageUrls';

const ChatInterface = () => {

    const { chatInfo } = useContext(ChatContext);
    const { interfaceChat } = chatInfo;

    const lastResponseRef = useRef(null);

    useEffect(() => {
        if (lastResponseRef.current) {
            lastResponseRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [interfaceChat?.conversation]);

    const chatBody = () => {
        if (Array.isArray(interfaceChat?.conversation)) {
            return interfaceChat?.conversation?.map(({ content, role, response_id }, index) =>
                <Fragment key={index}>
                    {role === 'user' && <Question question={content} />}
                    {role === 'assistant' && (
                        content === 'loading' ?
                            <Box
                                className='response'
                                sx={{ width: '100%', display: 'flex' }}
                                ref={index === interfaceChat?.conversation?.length - 1 ? lastResponseRef : null}>
                                <ImageRenderer src={newConversationLogo.src} alt={newConversationLogo.alt} className='d32' />
                                <Box sx={{ width: '100%', height: '100%' }} className='loader'></Box>
                            </Box>
                            :
                            <Reponse
                                logo={newConversationLogo.src}
                                alt={newConversationLogo.alt}
                                response={content}
                                id={response_id}
                                index={index}
                                ref={index === interfaceChat?.conversation?.length - 1 ? lastResponseRef : null}
                            />
                    )
                    }
                </Fragment>
            )
        }
        else {
            return <NoChats logo={chatLogo.src} />
        }
    }

    return (

        <GenerativeChatMenu>
            <Box sx={{ backgroundColor: '#fff', maxHeight: '100vh', width: '100%', maxWidth: '100vw' }}>
                <Grid container sx={{ height: '93vh', width: '100%', mt: '4rem' }}>

                    <Grid item xs={12} sx={{ height: '87%', overflowY: 'auto', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                        <Box sx={{ width: Array.isArray(interfaceChat?.conversation) ? '800px' : '80%', height: '100% ', maxHeight: '100% ' }}>
                            {chatBody()}
                        </Box>
                    </Grid>

                    <Grid item xs={12}
                        sx={{
                            position: 'relative',
                            height: '10%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <TextFieldContainer
                            sx={{
                                position: 'absolute',
                                bottom: '25%',
                                width: '45%',
                                backgroundColor: '#f5f3f4',
                                resize: 'none',
                                overflow: 'hidden',
                                boxSizing: 'border-box',
                                zIndex: '3',
                                maxHeight: '10rem',
                                ...CommonStyles.scrollStyle,
                            }}
                            interfaceChat={interfaceChat}
                        />
                    </Grid>

                </Grid>
            </Box>
        </GenerativeChatMenu >
    );
}

export default memo(ChatInterface);