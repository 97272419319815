


import React from 'react';
import { InputLabel, MenuItem, FormControl, Chip } from '@mui/material';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import './dropDown.css'

const Dropdown = (props) => {
    const { variant, margin, width, mt, mr, ml, label, labelId, data, value, onChange, textColor, bgcolor, name, disabled, id, needValue, error, height, maxHeight, sx, inputLabel } = props
    const themeMode = useSelector(state => state.theme.darkTheme)
    const color = themeMode ? '#fff' : '#000'
    const outLineColor = themeMode ? '#282a2e' : '#fff'

    return (
        <div>
            <FormControl variant={variant} size="small" sx={{ m: margin >= 0 ? margin : 1, minWidth: width || 100, mt: mt || 0, mr: mr || 0, ml: ml || 0 }}>
                {inputLabel && <InputLabel id={id} sx={{ color: textColor || color }} disabled={disabled}>{inputLabel}</InputLabel>}
                <Select
                    disableUnderline
                    sx={{
                        color: textColor,
                        fontStyle: 'normal',
                        bgcolor,
                        '.MuiOutlinedInput-notchedOutline': {
                            border: `0.5px solid ${outLineColor}`,
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: `0.5px solid ${outLineColor}`,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            border: `0.5px solid ${outLineColor}`,
                        },
                        '.MuiSvgIcon-root ': {
                            fill: `${color}`,
						},
						'.MuiChip-label': {
							color: 'white'
						},
                        ...sx
                    }}
                    labelId={labelId}
                    value={value}
                    id={id}
                    name={name}
                    onChange={onChange}
                    label={label}
                    disabled={disabled}
                    error={error}
                    height={height}
                    style={{ maxHeight: maxHeight ? maxHeight : null }}
                    MenuProps={{
                        style: { maxHeight: maxHeight ? maxHeight : null }, // Set the maximum height you desire
                        disableScrollLock: true,
                        className: themeMode ? 'menu-container-dark' : 'menu-container-light',
                    }}
                >
                    {data?.map((item, i) => <MenuItem key={i} value={needValue ? item?.name : item?.id || item?._id || item} disabled={item?.disabled}>
                        <>{item?.name || < Chip label={item} sx={{ width: '5rem', backgroundColor: "#dc3d28", color: 'white' }} size="small" />}</>
                    </MenuItem>
                    )}
                </Select>
            </FormControl>
        </div >
    );
}


export default Dropdown;