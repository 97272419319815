import { Avatar, Box, Grid, styled } from "@mui/material"
import { AddCircleOutline, Delete } from "@mui/icons-material"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import ReturnMultiTypeMedia from "../faultReports/newFaultReports/components/returnMultiTypeMedia"
import { DELETE, FILEPOST } from "../../helpers/http"
import { errorToast, successToast } from "../../helpers/apiToast"
import { ThemeColors } from "../../hooks/ThemeColors"
import Loading from "../../components/loading/Loading"

const MediaUploadForm = ({ handleFileUpload, existingMedia, type, handleDeleteMedia }) => {
    const { themeMode } = ThemeColors();
    const fileReadUrl = useSelector((state) => state?.configurations?.frfileReadUrl)

    const [medias, setMedias] = useState(existingMedia?.length > 0 ? [...existingMedia] : [])
    const [uploadingMedia, setUploadingMedia] = useState(false)

    useEffect(() => {
        handleFileUpload(medias)
    }, [medias])

    const handleImage = async ({ event }) => {
        try {
            const maxLimit = 5
            //check maxMedia count
            if (medias?.length < maxLimit) {
                const fileData = event.target.files[0]
                const formData = new FormData()
                formData.append('file', fileData)
                setUploadingMedia(true)
                const response = await FILEPOST(`${process.env.REACT_APP_URL}ppm/v1/media/upload`, formData, 'File Upload : Add Image', true)
                console.log(response)
                // const response = await FILEPOST(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/media/upload`, formData, 'File Upload : Add Image', true)
                if (response?.status === 200) {
                    setMedias([...medias, response?.data])
                    setUploadingMedia(false)
                    successToast("Image Uploaded Successfully")
                }
            } else {
                errorToast(`You can upload only ${maxLimit} medias`)
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
            setUploadingMedia(false)
            console.log(error)
        }
    }

    const handleDeleteMediaFun = async (media) => {
        console.log({ media })
        try {
            if (type === 'cost') {
                handleDeleteMedia(media)
                setMedias(medias?.filter(mda => mda?.uploadId !== media?.uploadId))
            } else {
                const response = await DELETE(`${process.env.REACT_APP_URL}ppm/v1/media/?uploadId=${media.uploadId}`)
                // const response = await DELETE(`${process.env.REACT_APP_BASE_APP_URL}fr/v2/media?uploadId=${media?.uploadId}`)
                if (response) {
                    setMedias(medias?.filter(mda => mda?.uploadId !== media?.uploadId))
                    successToast("Media Deleted Successfully")
                }
            }
        } catch (error) {
            errorToast(error?.response?.data?.message || error?.message)
            console.log(error)
        }
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    return (
        <Box sx={{ height: "8rem", overflow: "auto" }}>
            <Grid container spacing={2} sx={{ height: "8rem", }}>
                <Grid item sm={6} md={4} lg={3} xl={3} >
                    <Box
                        component='label'
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            borderRadius: '1rem',
                            border: '1px solid lightgray',
                            height: "100%",
                            backgroundColor: themeMode ? '#5a5a5a' : '#fff',
                            // ...sx
                        }}>
                        <AddCircleOutline sx={{ fontSize: '3rem' }} />
                        <VisuallyHiddenInput
                            type="file"
                            onChange={(event) => handleImage({ event })}
                        />
                    </Box>
                </Grid>
                {medias?.length > 0 &&
                    medias?.map(media => {
                        return <Grid item sm={6} md={4} lg={3} xl={3} sx={{ position: "relative", height: "100%" }}>
                            <ReturnMultiTypeMedia fitImage="cover" fileReadUrl={fileReadUrl} media={media} height="100%"
                                sx={{ border: '1px solid lightgray', borderRadius: "1rem" }} />
                            <Avatar sx={{
                                position: 'absolute',
                                height: "1.5rem",
                                width: "1.5rem",
                                backgroundColor: "black",
                                right: "0.5rem",
                                top: "1.5rem"
                            }}>
                                <Delete
                                    fontSize="small"
                                    onClick={() => handleDeleteMediaFun(media)}
                                    sx={{ cursor: 'pointer' }} />
                            </Avatar>
                        </Grid>
                    })
                }
                {uploadingMedia && <Grid item sm={6} md={4} lg={3} xl={3}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            borderRadius: '1rem',
                            border: '1px solid lightgray',
                            height: "100%",
                            backgroundColor: themeMode ? '#5a5a5a' : '#fff',
                        }}>
                        <Loading height="100%" />
                    </Box>
                </Grid>}
            </Grid>
        </Box >
    )
}

export default MediaUploadForm