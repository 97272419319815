import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";
import { errorHandler } from "../../../components/facilityManager/helpers/http";

const fetchAlertsSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAlerts: builder.query({
            query: (queryParams) => ({
                url: 'twin/v1/alerts/s1/data',
                params: { ...queryParams, duration: 'today' },
                providesTags: ['alerts'],
                method: 'GET',
                credentials: "include"
            }),
            transformResponse: responseData => {
                const data = JSON.parse(JSON.stringify(responseData?.alerts))

                const newData = data.map(ele => {

                    const isValid = ele?.isValid, lastUpdatedTime = ele?.lastUpdatedTime, feature = ele.feature?.toLowerCase()
                    let str = ""
                    if (lastUpdatedTime && isValid === false && !ele?.isHigh && !ele?.isLow) str = "Inactive Device - Last Value"
                    else if (lastUpdatedTime === null && isValid === false && !ele?.isHigh && !ele?.isLow) str = "Inactive Device"
                    else if (isValid === false && lastUpdatedTime === undefined && !ele?.isHigh && !ele?.isLow) str = "Invalid Data"

                    const alertText = feature === 'temperature' ? (isValid ? (ele.isHigh ? 'High Temperature' : 'Low Temperature') : str) :
                        feature === 'humidity' ? (isValid ? (ele.isHigh ? 'High Humidity' : 'Low Humidity') : str) :
                            feature === 'illuminance' ? (isValid ? (ele.isHigh ? 'High Illuminance' : 'Low Illuminance') : str) :
                                feature === 'co2' ? (isValid ? (ele.isHigh ? 'High CO2' : 'Low CO2') : str) :
                                    feature === 'air pressure' ? (isValid ? (ele.isHigh ? 'High Air Pressure' : 'Low Air Pressure') : str) :
                                        feature === 'radon' ? (isValid ? (ele.isHigh ? ' High Radon' : 'Low Radon') : str) :
                                            feature === 'voc' ? (isValid ? (ele.isHigh ? ' High Tvoc' : 'Low Tvoc') : str) :
                                                feature === 'paper towel usage' ? (isValid ? (ele.isHigh ? ' Paper Towel Full' : 'Paper Towel Empty') : str) :
                                                    feature === 'bin usage' ? (isValid ? (ele.isHigh && ' Bin is full') : str) :
                                                        feature === 'body temperature' ? (isValid ? 'Abnormal temperature detected in an individual' : str) :
                                                            feature === 'people count' ? (isValid ? 'Negative people count' : str) :
                                                                feature === 'energy' ? (isValid ? '' : str) :
                                                                    feature === 'aqi' ? (isValid ? (ele.isHigh && 'High AQI') : str) :
                                                                        feature === 'occupancy' ? (isValid ? '' : str) :
                                                                            feature === 'ammonia' ? (isValid ? '' : str) :
                                                                                feature === 'desk occupancy' ? (isValid ? '' : str) :
                                                                                    feature === 'wetness' ? (isValid ? '' : str) :
                                                                                        feature === 'soap level' ? (isValid ? '' : str) :
                                                                                            feature === 'odour' ? (isValid ? '' : str) :
                                                                                                feature === 'proximity' ? (isValid ? '' : str) :
                                                                                                    feature === 'meetingroom_occupancy' ? (isValid ? 'Occupancy Count Exceeded' : str) :
                                                                                                        feature === 'occupancy sensor' ? (isValid ? 'Invalid Data' : str) : ''

                    return {
                        ...ele, alertText
                    }
                })
                const finalData = { alerts: newData, count: responseData?.count }
                return finalData
            },
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Alerts Dashboard')
            },
        }),
        getAlertStats: builder.query({
            query: (queryParams) => ({
                url: 'twin/v1/alerts/s2/stats',
                params: { ...queryParams, type: 'all', duration: 'today' },
                providesTags: ['alerts'],
                method: 'GET',
                credentials: "include"
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Alerts Secondary Stats')
            },
        }),
        getAlertChartStats: builder.query({
            query: (queryParams) => ({
                url: 'twin/v1/alerts/s2/stats',
                method: 'GET',
                credentials: "include",
                params: { ...queryParams, type: 'all', duration: 'today', 'group-by': 'floor' },
                providesTags: ['alerts']
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Alerts Secondary Charts')
            },
        }),
        getAlertTabData: builder.query({
            query: (queryParams) => ({
                url: 'twin/v1/alerts/s2/data',
                method: 'GET',
                credentials: "include",
                params: { ...queryParams, duration: 'today' },
                providesTags: ['alerts']
            }),
            transformErrorResponse: (error) => {
                console.log({ error })
                errorHandler(error, 'Alerts Secondary Tab Data')
            },
        })
    })
})

export const { useGetAlertsQuery, useGetAlertStatsQuery, useGetAlertChartStatsQuery, useGetAlertTabDataQuery } = fetchAlertsSlice

const initialState = {
    dashboard: {
        isLoaded: false,
        error: "",
        apiAlerts: []
    },
    secondary: {
        data: {},
        isLoaded: false,
        criticalAlertsCount: 0,
        highAlertsCount: 0,
        total: 0,
        chartData: {},
        chartDataIsLoaded: false,
        alertsCountIsLoaded: false
    }
}

const alertSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        resetDashboardAlerts: (state, action) => {
            state.dashboard = initialState.dashboard
        },
        resetIsLoaded: (state, action) => {
            state.secondary.isLoaded = false
        },
        resetSecondaryData: (state, action) => {
            state.secondary = { ...initialState?.secondary }
        },
        updateSSEAlerts: (state, action) => {
            const data = JSON.parse(JSON.stringify(state.dashboard?.apiAlerts))
            const isValid = action.payload?.isValid, lastUpdatedTime = action.payload?.lastUpdatedTime
            const feature = action.payload?.feature?.toLowerCase(), isLow = action.payload?.isLow, isHigh = action.payload?.isHigh
            let str = ""
            if (lastUpdatedTime && isValid === false && !isHigh && !isLow) str = "Inactive Device - Last Value"
            else if (lastUpdatedTime === null && isValid === false && !isHigh && !isLow) str = "Inactive device"
            else if (isValid === false && lastUpdatedTime === undefined && !isHigh && !isLow) str = "Invalid Data"

            const sseData = action.payload
            sseData.alertText = feature === 'temperature' ? (isValid ? (isHigh ? 'High Temperature' : 'Low Temperature') : str) :
                feature === 'humidity' ? (isValid ? (isHigh ? 'High Humidity' : 'Low Humidity') : str) :
                    feature === 'illuminance' ? (isValid ? (isHigh ? 'High Illuminance' : 'Low Illuminance') : str) :
                        feature === 'co2' ? (isValid ? (isHigh ? 'High CO2' : 'Low CO2') : str) :
                            feature === 'air pressure' ? (isHigh ? 'High Air Pressure' : isLow ? 'Low Air Pressure' : '') :
                                feature === 'radon' ? (isValid ? (isHigh ? ' High Radon' : 'Low Radon') : str) :
                                    feature === 'voc' ? (isValid ? (isHigh ? ' High Tvoc' : 'Low Tvoc') : str) :
                                        feature === 'paper towel usage' ? (isValid ? (isHigh ? ' Paper Towel Full' : 'Paper Towel Empty') : str) :
                                            feature === 'bin usage' ? (isValid ? (isHigh && ' Bin is full') : str) :
                                                feature === 'body temperature' ? (isValid ? 'Abnormal temperature detected in an individual' : str) :
                                                    feature === 'people count' ? (isValid ? 'Negative people count' : str) :
                                                        feature === 'meetingroom_occupancy' ? (isValid ? 'Occupancy Count Exceeded' : str) :
                                                            feature === 'energy' ? (isValid ? '' : str) :
                                                                feature === 'aqi' ? (isValid ? (isHigh && 'High AQI') : str) :
                                                                    feature === 'occupancy' ? (isValid ? '' : str) :
                                                                        feature === 'ammonia' ? (isValid ? '' : str) :
                                                                            feature === 'desk occupancy' ? (isValid ? '' : str) :
                                                                                feature === 'wetness' ? (isValid ? '' : str) :
                                                                                    feature === 'soap level' ? (isValid ? '' : str) :
                                                                                        feature === 'odour' ? (isValid ? '' : str) :
                                                                                            feature === 'proximity' ? (isValid ? '' : str) : ''
            const newData = [sseData, ...data?.alerts]
            const newCount = { ...data?.count, [sseData?.status?.toLowerCase()]: data?.count[sseData?.status?.toLowerCase()] + 1, all: data?.count?.all + 1 }
            const finalData = { alerts: newData, count: newCount }

            state.dashboard = { ...state.dashboard, isLoaded: true, apiAlerts: finalData }
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getAlerts' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.dashboard.isLoaded = true
                    state.dashboard.apiAlerts = action.payload
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getAlertStats' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.total = action.payload?.all
                    state.secondary.criticalAlertsCount = action.payload?.critical
                    state.secondary.highAlertsCount = action.payload?.high
                    state.secondary.alertsCountIsLoaded = true
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getAlertChartStats' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.chartData = action.payload
                    state.secondary.chartDataIsLoaded = true
                }
            )
            .addMatcher(
                (action) => action?.meta?.arg?.endpointName === 'getAlertTabData' && action?.meta?.requestStatus === 'fulfilled',
                (state, action) => {
                    state.secondary.data = action.payload
                    state.secondary.isLoaded = true
                }
            )
    }
})

export const { resetDashboardAlerts, resetIsLoaded, updateSSEAlerts, resetSecondaryData } = alertSlice.actions

export default alertSlice.reducer